import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { TranslationContent } from "../../../redux/reducers/admin";
import { getAllWebContent } from "../../../redux/reducers/user";
import "./TranslationField.css";
import { useHistory } from "react-router-dom";


const AboutContent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

  const { allWebContent } = useSelector((state) => state?.user);
  const aboutData = allWebContent?.[0]?.about;

  const [diable, setDiable] = useState(true);

  const [disable, setDisable] = useState(true);
  const [formData, setFormData] = useState({
    // General information
    page: "about",
    headerHeading: "",
    headerText: "",

    // About Section
    aboutTitle: "",
    aboutDescription: "",

    // Statistics Section
    statFounded: "",
    statClients: "",
    statUnbiased: "",
    statFoundedLabel: "",
    statClientsLabel: " ",
    statUnbiasedLabel: " ",

    // Team Information
    teamTitle: " ",
    teamDescription: "",
    teamImageAlt: " ",

    // Mission Section
    missionTitle: " ",
    missionDescriptionPart1: "",
    missionDescriptionPart2: "",

    // Process Information
    name1: "",
    name2: "",
    name3: "",
    name4: "",
    name5: " ",
    name6: "",

    // Contact Information
    availableHeading: "",
    availableText1: "",
    availableText2: "",
    address: "",
    copyrights: "",

    // Statistics Labels
    statFoundedLabel: "",
    statClientsLabel: " ",
    statUnbiasedLabel: " ",
  });

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setDisable(false);
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    if (aboutData) {
      setFormData((prevState) => ({
        ...prevState,
        ...aboutData,
      }));
    }
  }, [aboutData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(TranslationContent(formData));
    console.log("🚀 ~ AboutContent Submit:", formData);
    setDisable(true);
  };

  const history = useHistory();

  return (
    <div className="container">
      <ToastContainer autoClose={20000} />

      <div className="tf-top mx-5 mt-2 mb-2">
        
        <button className="back-button" onClick={history.goBack}>
          &lt;</button>

          <h1 className="mt-3" s  style={{
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'absolute',
}}>
About Page
</h1>

    </div>

      <form onSubmit={handleSubmit} className="mx-5">
        {/* Header Section */}
        <h3>Header</h3>
        <div className="form-group">
          <label>Header Heading:</label>
          <input
            type="text"
            name="headerHeading"
            value={formData.headerHeading}
            onChange={(e) => handleInputChange(e, "headerHeading")}
            required
          />
        </div>
        <div className="form-group">
          <label>Header Text:</label>
          <textarea
            name="headerText"
            value={formData.headerText}
            onChange={(e) => handleInputChange(e, "headerText")}
            required
          />
        </div>

        {/* About Section */}
        <h3>About Section</h3>
        <div className="form-group">
          <label>Title:</label>
          <input
            type="text"
            name="aboutTitle"
            value={formData.aboutTitle}
            onChange={(e) => handleInputChange(e, "aboutTitle")}
          />
        </div>
        <div className="form-group">
          <label>Description:</label>
          <textarea
            name="aboutDescription"
            value={formData.aboutDescription}
            onChange={(e) => handleInputChange(e, "aboutDescription")}
          />
        </div>

        {/* Statistics Section */}
        <h3>Statistics</h3>
        <div className="form-group">
          <label>Founded Label:</label>
          <input
            type="text"
            name="statFoundedLabel"
            value={formData.statFoundedLabel}
            onChange={(e) => handleInputChange(e, "statFoundedLabel")}
          />
        </div>
        <div className="form-group">
          <label>Founded Value:</label>
          <input
            type="text"
            name="statFounded"
            value={formData.statFounded}
            onChange={(e) => handleInputChange(e, "statFounded")}
          />
        </div>
        <div className="form-group">
          <label>Clients Label:</label>
          <input
            type="text"
            name="statClientsLabel"
            value={formData.statClientsLabel}
            onChange={(e) => handleInputChange(e, "statClientsLabel")}
          />
        </div>
        <div className="form-group">
          <label>Clients Value:</label>
          <input
            type="text"
            name="statClients"
            value={formData.statClients}
            onChange={(e) => handleInputChange(e, "statClients")}
          />
        </div>
        <div className="form-group">
          <label>Unbiased Label:</label>
          <input
            type="text"
            name="statUnbiasedLabel"
            value={formData.statUnbiasedLabel}
            onChange={(e) => handleInputChange(e, "statUnbiasedLabel")}
          />
        </div>
        <div className="form-group">
          <label>Unbiased Value:</label>
          <input
            type="text"
            name="statUnbiased"
            value={formData.statUnbiased}
            onChange={(e) => handleInputChange(e, "statUnbiased")}
          />
        </div>

        {/* Team Information */}
        <h3>Team Information</h3>
        <div className="form-group">
          <label>Team Title:</label>
          <input
            type="text"
            name="teamTitle"
            value={formData.teamTitle}
            onChange={(e) => handleInputChange(e, "teamTitle")}
          />
        </div>
        <div className="form-group">
          <label>Team Description:</label>
          <textarea
            name="teamDescription"
            value={formData.teamDescription}
            onChange={(e) => handleInputChange(e, "teamDescription")}
          />
        </div>
        <div className="form-group">
          <label>Team Image Alt Text:</label>
          <input
            type="text"
            name="teamImageAlt"
            value={formData.teamImageAlt}
            onChange={(e) => handleInputChange(e, "teamImageAlt")}
          />
        </div>

        {/* Mission Section */}
        <h3>Mission Section</h3>
        <div className="form-group">
          <label>Mission Title:</label>
          <input
            type="text"
            name="missionTitle"
            value={formData.missionTitle}
            onChange={(e) => handleInputChange(e, "missionTitle")}
          />
        </div>
        <div className="form-group">
          <label>Mission Description Part 1:</label>
          <textarea
            name="missionDescriptionPart1"
            value={formData.missionDescriptionPart1}
            onChange={(e) => handleInputChange(e, "missionDescriptionPart1")}
          />
        </div>
        <div className="form-group">
          <label>Mission Description Part 2:</label>
          <textarea
            name="missionDescriptionPart2"
            value={formData.missionDescriptionPart2}
            onChange={(e) => handleInputChange(e, "missionDescriptionPart2")}
          />
        </div>

        {/* Process Information */}
        <h3>Process Information</h3>
        <div className="form-group">
          <label>Process Name 1:</label>
          <input
            type="text"
            name="name1"
            value={formData.name1}
            onChange={(e) => handleInputChange(e, "name1")}
          />
        </div>
        <div className="form-group">
          <label>Process Name 2:</label>
          <input
            type="text"
            name="name2"
            value={formData.name2}
            onChange={(e) => handleInputChange(e, "name2")}
          />
        </div>
        <div className="form-group">
          <label>Process Name 3:</label>
          <input
            type="text"
            name="name3"
            value={formData.name3}
            onChange={(e) => handleInputChange(e, "name3")}
          />
        </div>
        <div className="form-group">
          <label>Process Name 4:</label>
          <input
            type="text"
            name="name4"
            value={formData.name4}
            onChange={(e) => handleInputChange(e, "name4")}
          />
        </div>
        <div className="form-group">
          <label>Process Name 5:</label>
          <input
            type="text"
            name="name5"
            value={formData.name5}
            onChange={(e) => handleInputChange(e, "name5")}
          />
        </div>
        <div className="form-group">
          <label>Process Name 6:</label>
          <input
            type="text"
            name="name6"
            value={formData.name6}
            onChange={(e) => handleInputChange(e, "name6")}
          />
        </div>



        {/* Available Section */}
        <h3>Available Section</h3>
        <div className="form-group">
          <label>Available Heading:</label>
          <input
            type="text"
            name="availableHeading"
            value={formData.availableHeading}
            onChange={(e) => handleInputChange(e, "availableHeading")}
          />
        </div>
        <div className="form-group">
          <label>Available Text 1:</label>
          <input
            type="text"
            name="availableText1"
            value={formData.availableText1}
            onChange={(e) => handleInputChange(e, "availableText1")}
          />
        </div>
        <div className="form-group">
          <label>Available Text 2:</label>
          <input
            type="text"
            name="availableText2"
            value={formData.availableText2}
            onChange={(e) => handleInputChange(e, "availableText2")}
          />
        </div>

        {/* Contact Information */}
        <h3>Contact Information</h3>
        <div className="form-group">
          <label>Address:</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={(e) => handleInputChange(e, "address")}
          />
        </div>
        <div className="form-group">
          <label>Copyright Text:</label>
          <input
            type="text"
            name="copyrights"
            value={formData.copyrights}
            onChange={(e) => handleInputChange(e, "copyrights")}
          />
        </div>

        <div className="form-group">
          <button type="submit" disabled={disable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AboutContent;