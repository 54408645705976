import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./CourseCheckout.css";
import {  AppHeader } from "@coreui/react";
import CoursePayment from "./CoursePayment";
import { getExhangeRate } from "../../../redux/reducers/auth";
import DefaultHeader from "../../Header/DefaultHeader";
import {  useLocation } from "react-router-dom";

import PFHeader from "../../PersonalFinance/components/PFHeader";


const CourseCheckout = () => {
  const dispatch = useDispatch();
  const { cart, loading, usdRate, paymentConfiguration } = useSelector(
    (state) => state.auth
  );
  const { userCurrency } = useSelector((state) => state.user);
  const [step, setStep] = useState(1);
  const [shippingInfo, setShippingInfo] = useState({
    firstname: "1",
    lastname: "1",
    addressLineOne: "1",
    addressLineTwo: "1",
    zipCode: "1",
    country: "1",
    state: "1",
    city: "1",
  });
  const [billingAdress, setBillingAdress] = useState({
    firstname: "1",
    lastname: "1",
    addressLineOne: "1",
    addressLineTwo: "1",
    zipCode: "1",
    country: "1",
    state: "1",
    city: "1",
  });
  const [paymentData, setPaymentData] = useState({
    card: {
      cardNo: "",
      expMonth: "",
      expYear: "",
      cvv: "",
      zipCode: "",
    },
    paymentMethod: null,
  });

  const content = {
    
  }


  const location = useLocation();


  const data = location.state ;

  
  if(!data.user_id)
    {
      const { user } = useSelector((state) => state.auth);    
      data.user_id = user?.user?.id;
  
    }
  
  
  


  const [pricingBreakdown, setPricingBreakdown] = useState({ total: usdRate });
  const [addressReused, setAddressReused] = useState(false);
  let onlyResidencyProducts = cart?.every(
    (pro) =>
      pro.product.productType === "physical" && pro.product.isResidency === true
  );
  let onlyDigitalProducts = cart?.every(
    (pro) => pro.product.productType === "digital"
  );
  useEffect(() => {
    if (!!onlyResidencyProducts) {
      setStep(2);
    }
    if (!!onlyDigitalProducts && billingAdress.addressLineOne !== "") {
      setStep(2);
    }
    dispatch(getExhangeRate(userCurrency));
  }, []);
  const changePaymentData = (paymentData) => {
    setPaymentData(paymentData);
  };

  const reuseShippingAddress = (e) => {
    if (e.target.checked) {
      setBillingAdress(shippingInfo);
      setAddressReused(true);
    } else {
      setBillingAdress({
        firstname: "",
        lastname: "",
        addressLineOne: "",
        addressLineTwo: "",
        zipCode: "",
        country: "",
        state: "",
        city: "",
      });
      setAddressReused(false);
    }
  };
  const salesPrice =
    cart?.reduce((a, b) => a + b.product?.unitPrice * b.qty, 0) * usdRate;

  const shippingCharges =
    cart?.reduce((a, b) => a + b.product?.shippingCharges * b.qty, 0) * usdRate;

  const yinnPercentage = (
    paymentConfiguration?.checkoutPaymentPercentageCharge * salesPrice
  ).toFixed(2);

  const totalPrice = (
    Number(yinnPercentage) +
    salesPrice +
    // paymentConfiguration?.coinMaintenanceCharge +
    shippingCharges +
    paymentConfiguration?.checkoutPaymentFixCharge
  ).toFixed(2);

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const confirmationTranslation =
    translationState?.[0]?.translations?.confirmation?.[userLanguage];

  return (
    <Container fluid style={{ backgroundColor: "#f6f6f6", padding: 0 }}>
      <PFHeader content={content} />

      <Row className="checkout-content">
        <Col xs="0" sm="1" />
        <Col xs="12" sm="10">

          
            <CoursePayment
              billingAdress={billingAdress}
              setBillingAdress={setBillingAdress}
              paymentData={paymentData}
              setPaymentData={changePaymentData}
              moveStep={setStep}
              cartData={cart}
              pricingBreakdown={pricingBreakdown}
              reuseShippingAddress={reuseShippingAddress}
              shippingInfo={shippingInfo}
              addressReused={addressReused}
              dataLoading={loading}
              yinnPercentage={yinnPercentage}
              totalPrice={totalPrice}
              shippingCharges={shippingCharges}
              onlyResidencyProducts={onlyResidencyProducts}
              onlyDigitalProducts={onlyDigitalProducts}
              data = {data}
            />

         
        </Col>
        <Col xs="0" sm="1" />
      </Row>


    </Container>
  );
};

export default CourseCheckout;
