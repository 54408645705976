import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getAllWebContent } from '../../redux/reducers/user';
import CallToAction from '../PersonalFinance/components/CallToAction';
import Footer from "../PersonalFinance/components/Footer";
import Header from "../PersonalFinance/components/Header";
import TestimonialContact from "../Testimonials/components/TestimonialContact";
import MT from "./components/MT";
import QA from "./components/QA";

function FAQ() {
  const dispatch = useDispatch();

  const { translationState, allWebContent } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);

  // Use translation if available, otherwise fall back to default content
  const faq = allWebContent?.[0]?.faq || {};

  const faqTranslation = translationState?.[0]?.translations?.faq?.[userLanguage] || {};
  const content = Object.keys(faqTranslation).length > 0 ? faqTranslation : faq;



  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);



  return (
    <div>
      <Header content={content} />
      <QA content={content} />
      <MT content={content} />
      <TestimonialContact content={content} />
      {/* <CallToAction content={content} /> */}
      <Footer content={content} />


    </div>
  );
}

export default FAQ;
