import React from 'react';
import './TestimonialCard.css';

const TestimonialCard = ({ content }) => {
  const clients = [
    { heading: content.Clientheading1, text: content.Clienttext1, author: content.Client1 },
    { heading: content.Clientheading2, text: content.Clienttext2, author: content.Client2 },
    { heading: content.Clientheading3, text: content.Clienttext3, author: content.Client3 },
    { heading: content.Clientheading4, text: content.Clienttext4, author: content.Client4 },
    { heading: content.Clientheading5, text: content.Clienttext5, author: content.Client5 },
    { heading: content.Clientheading6, text: content.Clienttext6, author: content.Client6 },
  ];

  return (
    <div className="TestimonialCard">
      <div className="Testimonial-heading">
        <h1 style={{ fontWeight: 'bold' }}>
          What Client Say
        </h1>
      </div>
      {clients.map((client, index) => (
        <div className="TestimonialCard-container" key={index}>
          <h2 className="TestimonialCard-heading">{client.heading}</h2>
          <p className="TestimonialCard-text">{client.text}</p>
          <p className="TestimonialCard-author">– {client.author}</p>
        </div>
      ))}
    </div>
  );
};

export default TestimonialCard;
