import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Calendar, Clock, DollarSign, ArrowLeft } from "lucide-react";
import "./EventDetails.css";
import { useDispatch } from "react-redux";
import PFHeader from "../../PersonalFinance/components/PFHeader";
import { translations } from "../../TranslationHelper/Translation";
import { toast } from "react-toastify";
import { getSuggestedCoursePrice } from "../../../redux/reducers/auth";
import PriceDialog from "./PriceDialog"; // Import the new dialog component

const EventDetails = () => {
  const location = useLocation();
  const [count, setCount] = useState(0);
  const history = useHistory();
  const event = location.state || {};
  const [suggestedPrice, setSuggestedPrice] = useState();
  const [newPrice, setNewPrice] = useState("");
  const [playicon, setplayicon] = useState(true);
  const dispatch = useDispatch();
  const [isPriceDialogOpen, setIsPriceDialogOpen] = useState(false);

  // Comprehensive translation object
  const translation = {
    date: {
      en: "Date",
      es: "Fecha",
      fr: "Date",
      zh: "日期",
      pt: "Data",
      ar: "التاريخ",
    },
    time: {
      en: "Time",
      es: "Hora",
      fr: "Heure",
      zh: "时间",
      pt: "Hora",
      ar: "الوقت",
    },
    price: {
      en: "Price",
      es: "Precio",
      fr: "Prix",
      zh: "价格",
      pt: "Preço",
      ar: "السعر",
    },
    details: {
      en: "Details/Description",
      es: "Detalles/Descripción",
      fr: "Détails/Description",
      zh: "详情/描述",
      pt: "Detalhes/Descrição",
      ar: "التفاصيل/الوصف",
    },
    enrollNow: {
      en: "Enroll Now",
      es: "Inscríbete Ahora",
      fr: "Inscrivez-vous Maintenant",
      zh: "立即报名",
      pt: "Inscreva-se Agora",
      ar: "سجل الآن",
    },
    // Added missing suggestedPrice translation
    suggestedPrice: {
      en: "Suggested Price",
      es: "Precio Sugerido",
      fr: "Prix Suggéré",
      zh: "建议价格",
      pt: "Preço Sugerido",
      ar: "السعر المقترح",
    },
  };

  // Get user language from Redux
  const { userLanguage } = useSelector((state) => state.auth || {});
  const { translationState } = useSelector((state) => state.user);

  // Language selection logic
  const selectedLanguage = (() => {
    // Normalize the language code (convert to lowercase, take first part of locale)
    const normalizedLanguage = userLanguage
      ? userLanguage.toLowerCase().split("-")[0]
      : null;

    // Check if the normalized language exists in our translation
    if (normalizedLanguage && translation.date[normalizedLanguage]) {
      return normalizedLanguage;
    }

    // Default to English if no match found
    return "en";
  })();


  const handleBuyNowClick = () => {
    event.price = parseFloat(suggestedPrice);
    history.push({
      pathname: "/login",
      state: { event },
    });
  };

  const content = {};

  const course_id = event.id;

  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];

  const checkSuggestedPrice = () => {
    const price = parseFloat(suggestedPrice);
    setplayicon(false);
    dispatch(
      getSuggestedCoursePrice({
        price,
        course_id,
      })
    ).then(({ payload }) => {
      if (payload.message) {
        toast.success(
          `${
            toastTranslation?.success
              ? toastTranslation?.success
              : "You got product"
          }`
        );
        handleBuyNowClick();
      } 
      else {

        setNewPrice(payload.newPrice);
        let updatedCount = count + 1;

        setCount(updatedCount);
        setIsPriceDialogOpen(true);
        setplayicon(true);

        return;

      }
    });
  };

  const getStarRating = (product) => {
    let price = product ? product?.price : null;
    if (price) {
      if (price < 20) {
        return 1;
      } else if (price < 100) {
        return 2;
      } else if (price < 500) {
        return 3;
      } else if (price < 1500) {
        return 4;
      } else {
        return 5;
      }
    } else {
      return null;
    }
  };

  const pricerating = getStarRating(event);
  const extendedRating = pricerating * 2;

  return (
    <div>
      <PFHeader content={content} />
      <div className="event-details-page">
        <div className="event-details-container">
          <div className="event-details-content">
            <h1 className="event-details-title">{event.eventTitle}</h1>

            <div className="event-highlights">
              <div className="highlight-item">
                <Calendar className="highlight-icon" />
                <div className="highlight-content">
                  <span className="highlight-label">
                    {translation.date[selectedLanguage]}
                  </span>
                  <span className="highlight-value">
                    {new Date(event.date).toLocaleDateString(selectedLanguage, {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </span>
                </div>
              </div>
              <div className="highlight-item">
                <Clock className="highlight-icon" />
                <div className="highlight-content">
                  <span className="highlight-label">
                    {translation.time[selectedLanguage]}
                  </span>
                  <span className="highlight-value">{event.time}</span>
                </div>
              </div>
              <div className="highlight-item">
                <DollarSign className="highlight-icon" size={26} />
                <div className="highlight-content">
                  <span className="highlight-label">
                    {translation.price[selectedLanguage]}
                  </span>
                  <span className="highlight-value" style={{ display: "flex" }}>
                    <span className="price-tag">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ color: "#1a1a1a" }}></span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            minWidth: 80,
                          }}
                        >
                          {Array(extendedRating)
                            .fill()
                            .map((star, i) => (
                              <svg width="6" height="14" key={i}>
                                <rect
                                  width="20"
                                  height="50"
                                  style={{ fill: "#EEAC1A", strokeWidth: "3" }}
                                />
                              </svg>
                            ))}
                          {Array(10 - extendedRating)
                            .fill()
                            .map((star, i) => (
                              <svg width="6" height="14" key={i}>
                                <rect
                                  width="20"
                                  height="50"
                                  style={{ fill: "#cecedb", strokeWidth: "3" }}
                                />
                              </svg>
                            ))}
                        </div>
                        <span className="rating-value highlight-value">
                          {extendedRating}/10
                        </span>
                      </div>

                      {/* Suggested Price Input Section */}
                      <div className="suggested-price-section">
                        <label
                          htmlFor="suggestedPrice"
                          className="highlight-label"
                        >
                          {translation.suggestedPrice[selectedLanguage]}
                        </label>
                        <div style={{ display: "flex" }}>
                          <input
                            type="number"
                            id="suggestedPrice"
                            className="suggested-price-input"
                            onChange={(e) =>
                              setSuggestedPrice(Number(e.target.value))
                            }
                            step="0.01"
                            placeholder="0.00"
                          />
                          <button
                            className="price-button"
                            onClick={checkSuggestedPrice}
                            disabled={!playicon}
                          >
                            {playicon ? "▶" : "⏳"}
                          </button>
                        </div>
                      </div>
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className="event-description">
              <h2 className="section-title">
                {translation.details[selectedLanguage]}
              </h2>
              <p className="event-details-description">{event.description}</p>
            </div>
          </div>

          <div
            className="event-details-thumbnail"
            style={{ backgroundImage: `url(${event.thumbnailUrl})` }}
          >
            <div className="event-category-badge">{event.category}</div>
          </div>
        </div>

        <PriceDialog
          isOpen={isPriceDialogOpen}
          onClose={() => {
            setIsPriceDialogOpen(false);
            if (count === 2) {
              setCount(0);
            }
          }}
          
          newPrice={Math.ceil(newPrice)}
          language={selectedLanguage}
          count = {count}
        />
      </div>
    </div>
  );
};

export default EventDetails;
