import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { TranslationContent } from "../../../redux/reducers/admin";
import { getAllWebContent } from "../../../redux/reducers/user";
import "./TranslationField.css";
import { useHistory } from "react-router-dom";


const FAQField = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

  const { allWebContent } = useSelector((state) => state?.user);
  const faqData = allWebContent?.[0]?.faq;

  const [diable, setDiable] = useState(true);

  const [formData, setFormData] = useState({
    page: "faq",
    headerHeading: "",
    headerText: "",
    q1: "", a1: "",
    q2: "", a2: "",
    q3: "", a3: "",
    q4: "", a4: "",
    q5: "", a5: "",
    q6: "", a6: "",
    q7: "", a7: "",
    q8: "", a8: "",
    q9: "", a9: "",
    q10: "", a10: "",
    misconception1: "", truth1: "",
    misconception2: "", truth2: "",
    misconception3: "", truth3: "",
    availableHeading: "",
    availableText1: "",
    availableText2: "",
    name1: "", name2: "", name3: "",
    name4: "", name5: "", name6: "",
    address: "", copyrights: "",
  });

  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    setDiable(false);

    setFormData((prevData) => ({
      ...prevData,
      [section]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(TranslationContent(formData));
    console.log("🚀 ~ FAQField Submit:", formData);
    setDiable(true);
  };

  useEffect(() => {
    if (faqData) {
      setFormData((prevState) => ({
        ...prevState,
        ...faqData,
      }));
    }
  }, [faqData]);

  return (
    <div className="container">
      <ToastContainer autoClose={20000} />
      <div className="tf-top mx-5 mt-2 mb-2">
        
        <button className="back-button" onClick={history.goBack}>
          &lt;</button>

          <h1 className="mt-3" s  style={{
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'absolute',
}}>
FAQ Page
</h1>

    </div>
      <form onSubmit={handleSubmit} className="mx-5">

        <h3>Header</h3>
        <div className="form-group">
          <label>Header Heading:</label>
          <input
            type="text"
            name="headerHeading"
            value={formData.headerHeading}
            onChange={(e) => handleInputChange(e, "headerHeading")}
            required
          />
        </div>
        <div className="form-group">
          <label>Header Text:</label>
          <textarea
            name="headerText"
            value={formData.headerText}
            onChange={(e) => handleInputChange(e, "headerText")}
            required
          />
        </div>


        <h3>FAQs</h3>
        {[...Array(10)].map((_, i) => (
          <div className="form-group" key={`q${i + 1}`}>
            <label>Question {i + 1}:</label>
            <input
              type="text"
              name={`q${i + 1}`}
              value={formData[`q${i + 1}`]}
              onChange={(e) => handleInputChange(e, `q${i + 1}`)}
              required
            />
            <label>Answer {i + 1}:</label>
            <textarea
              name={`a${i + 1}`}
              value={formData[`a${i + 1}`]}
              onChange={(e) => handleInputChange(e, `a${i + 1}`)}
              required
            />
          </div>
        ))}

        <h3>Misconceptions and Truths</h3>
        {[...Array(3)].map((_, i) => (
          <div className="form-group" key={`misconception-${i}`}>
            <label>Misconception {i + 1}:</label>
            <textarea
              name={`misconception${i + 1}`}
              value={formData[`misconception${i + 1}`]}
              onChange={(e) => handleInputChange(e, `misconception${i + 1}`)}
              required
            />
            <label>Truth {i + 1}:</label>
            <textarea
              name={`truth${i + 1}`}
              value={formData[`truth${i + 1}`]}
              onChange={(e) => handleInputChange(e, `truth${i + 1}`)}
              required
            />
          </div>
        ))}


        <h3>Available Information</h3>
        <div className="form-group">
          <label>Available Heading:</label>
          <input
            type="text"
            name="availableHeading"
            value={formData.availableHeading}
            onChange={(e) => handleInputChange(e, "availableHeading")}
            required
          />
        </div>
        <div className="form-group">
          <label>Available Text 1:</label>
          <textarea
            name="availableText1"
            value={formData.availableText1}
            onChange={(e) => handleInputChange(e, "availableText1")}
            required
          />
        </div>
        <div className="form-group">
          <label>Available Text 2:</label>
          <textarea
            name="availableText2"
            value={formData.availableText2}
            onChange={(e) => handleInputChange(e, "availableText2")}
            required
          />
        </div>

        <h3>Process Information</h3>
        {[...Array(6)].map((_, i) => (
          <div className="form-group" key={`process-name-${i}`}>
            <label>Process Name {i + 1}:</label>
            <input
              type="text"
              name={`name${i + 1}`}
              value={formData[`name${i + 1}`]}
              onChange={(e) => handleInputChange(e, `name${i + 1}`)}
              required
            />
          </div>
        ))}

        <h3>Address and Copyrights</h3>
        <div className="form-group">
          <label>Address:</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={(e) => handleInputChange(e, "address")}
          />
        </div>
        <div className="form-group">
          <label>Copyright Text:</label>
          <textarea
            name="copyrights"
            value={formData.copyrights}
            onChange={(e) => handleInputChange(e, "copyrights")}
          />
        </div>

        <div className="form-group">
          <button type="submit" disabled={diable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default FAQField;
