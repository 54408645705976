import { default as React } from 'react';
import asian from "../../../assets/img/personalFinance/asian-couple.jpg";
import image from "../../../assets/img/personalFinance/couple-in-cafe.jpg";
import financeimage from "../../../assets/img/personalFinance/mature-couple.jpg";
import pencil from "../../../assets/img/personalFinance/pencil.jpg";
import shakinghands from "../../../assets/img/personalFinance/shaking-hands.jpg";
import './Blog.css';

const Blog = ({ content }) => {

  return (
    <div className="finance-container">
      <div className="finance-content">
        <img
          src={financeimage}
          alt="Couple looking at finances"
          className="finance-image"
        />
        <div className="finance-text">
          <h2>{content.blog1Heading}</h2>
          <p>{content.blog1Text}</p>
        </div>
      </div>

      <div className="finance-content">
        <img
          src={image}
          alt="Couple looking at finances"
          className="finance-image"
        />
        <div className="finance-text">
          <h2>{content.blog2Heading}</h2>
          <p>{content.blog2Text}</p>
        </div>
      </div>

      <div className="finance-content">
        <img
          src={asian}
          alt="Couple looking at finances"
          className="finance-image"
        />
        <div className="finance-text">
          <h2>{content.blog3Heading}</h2>
          <p>{content.blog3Text}</p>
        </div>
      </div>

      <div className="finance-content">
        <img
          src={pencil}
          alt="Couple looking at finances"
          className="finance-image"
        />
        <div className="finance-text">
          <h2>{content.blog4Heading}</h2>
          <p>{content.blog4Text}</p>
        </div>
      </div>

      <div className="finance-content">
        <img
          src={shakinghands}
          alt="Couple looking at finances"
          className="finance-image"
        />
        <div className="finance-text">
          <h2>{content.blog5Heading}</h2>
          <p>{content.blog5Text}</p>
        </div>
      </div>
    </div>
  );
};

export default Blog;
