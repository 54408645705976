import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MediaGrid } from "./PaidMediaCards";
import { useSelector } from "react-redux";
import "./PaidCoursesAndEvents.css";

const PaidCoursesAndEvents = ({ content }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const history = useHistory();

  // Translation object with comprehensive language support
  const translation = {
    heading: {
      en: "Discover Our Premium Courses & Events",
      es: "Descubre nuestros cursos y eventos premium",
      fr: "Découvrez nos cours et événements premium",
      zh: "发现我们的优质课程和活动",
      pt: "Descubra nossos cursos e eventos premium",
      ar: "اكتشف دوراتنا وفعالياتنا المميزة",
    },
    subheading: {
      en: "Expand your horizons with our expertly curated selection",
      es: "Expande tus horizontes con nuestra selección cuidadosamente curada",
      fr: "Élargissez vos horizons avec notre sélection soigneusement organisée",
      zh: "通过我们精心策划的选择拓展您的视野",
      pt: "Expanda seus horizontes com nossa seleção cuidadosamente curada",
      ar: "وسع آفاقك مع مجموعتنا المختارة بعناية",
    },
    loading: {
      en: "Loading amazing courses for you...",
      es: "Cargando cursos increíbles para ti...",
      fr: "Chargement de cours incroyables pour vous...",
      zh: "正在为您加载精彩的课程...",
      pt: "Carregando cursos incríveis para você...",
      ar: "جاري تحميل الدورات الرائعة لك...",
    },
    noEvents: {
      en: "No events or courses available at the moment.",
      es: "No hay eventos ni cursos disponibles en este momento.",
      fr: "Aucun événement ni cours disponible pour le moment.",
      zh: "目前没有可用的活动或课程",
      pt: "Nenhum evento ou curso disponível no momento.",
      ar: "لا توجد فعاليات أو دورات متاحة في الوقت الحالي.",
    },
    checkBack: {
      en: "Please check back soon for updates!",
      es: "¡Vuelve pronto para ver las actualizaciones!",
      fr: "Veuillez revenir bientôt pour des mises à jour !",
      zh: "请稍后回来查看更新！",
      pt: "Por favor, volte em breve para atualizações!",
      ar: "يرجى العودة قريبًا للحصول على التحديثات!",
    },
  };
  

  // Robust language selection logic
  const { userLanguage } = useSelector((state) => state.auth || {});
  
  // Improved language selection with fallback and logging
  const getSelectedLanguage = () => {
    // Normalize the language code (convert to lowercase)
    const normalizedLanguage = userLanguage ? userLanguage.toLowerCase().split('-')[0] : null;
    
    // Check if the normalized language exists in our translation
    if (normalizedLanguage && translation.heading[normalizedLanguage]) {
      return normalizedLanguage;
    }
    
    // Default to English if no match found
    return 'en';
  };

  const selectedLanguage = getSelectedLanguage();

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    history.push(`/event-details/${event.eventTitle}`, {
      id: event._id,
      eventTitle: event.eventTitle,
      description: event.description,
      date: event.date,
      time: event.time,
      price: event.pricing,
      category: event.category,
      thumbnailUrl: event.thumbnailUrl,
      videoUrl: event.videoUrl,
    });
  };

  // Loading state
  if (!content) {
    return (
      <div className="cae-container">
        <div className="cae-loading">
          <div className="cae-loading-spinner"></div>
          <p>{translation.loading[selectedLanguage]}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="cae-header">
        <h1 className="cae-heading">{translation.heading[selectedLanguage]}</h1>
        <p className="cae-subheading">{translation.subheading[selectedLanguage]}</p>
      </div>

      <div className="cae-page-wrapper">
        <div className="cae-container">
          {content && Array.isArray(content) && content.length > 0 ? (
            <MediaGrid contents={content} onEventClick={handleEventClick} />
          ) : (
            <div className="cae-no-events">
              <i className="cae-icon-calendar-x"></i>
              <p>{translation.noEvents[selectedLanguage]}</p>
              <p className="cae-check-back">{translation.checkBack[selectedLanguage]}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaidCoursesAndEvents;