import { faBullseye, faCalendar, faClipboard, faHandshake, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './FinancialCoachingProcess.css';

const FinancialCoachingProcess = ({ content }) => {
  const steps = [
    {
      title: content.step1Heading,
      icon: faHandshake,  // You can change this to a different icon if needed
      description: content.step1Text // Assuming these are passed in content
    },
    {
      title: content.step2Heading,
      icon: faClipboard,
      description: content.step2Text
    },
    {
      title: content.step3Heading,
      icon: faList,
      description: content.step3Text
    },
    {
      title: content.step4Heading,
      icon: faCalendar,
      description: content.step4Text
    },
    {
      title: content.step5Heading,
      icon: faBullseye,
      description: content.step5Text
    }
  ];

  return (
    <div className="financial-coaching-process">
      <div className="fcp-container">
        <div className="steps-container">
          {steps.map((step, index) => (
            <div key={index} className="step">
              <div className="icon-container">
                <FontAwesomeIcon icon={step.icon} size="2x" />
              </div>
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FinancialCoachingProcess;
