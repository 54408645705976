import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getAllWebContent } from '../../redux/reducers/user';
import Blog from './components/Blog';
import BlogBottom from './components/BlogBottom';
import CallToAction from './components/CallToAction';
import FinancialCoachingProcess from './components/FinancialCoachingProcess';
import Footer from './components/Footer';
import Header from './components/Header';
import './PersonalFinance.css';

function PersonalFinance() {
  const dispatch = useDispatch();

  const { translationState, allWebContent } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);

  // Use translation if available, otherwise fall back to default content
  const pFinance = allWebContent?.[0]?.personalFinance || {};
  const pFinanceTranslation = translationState?.[0]?.translations?.personalFinance?.[userLanguage] || {};
  const content = Object.keys(pFinanceTranslation).length > 0 ? pFinanceTranslation : pFinance;



  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

  return (
    <>
      <Header content={content} />
      <Blog content={content} />
      <FinancialCoachingProcess content={content} />
      <BlogBottom content={content} />
      {/* <CallToAction content={content} /> */}
      <Footer content={content} />
    </>
  );
}

export default PersonalFinance;
