import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getAllWebContent } from '../../redux/reducers/user';
import Blog from './components/Blog';
import Process from './components/Process';
import Header from "../PersonalFinance/components/Header";
import Footer from "../PersonalFinance/components/Footer";
import './NewImmigrantsCredit&Finance.css';

function NewImmigrantsCreditAndFinance() {
  const dispatch = useDispatch();

  const { translationState, allWebContent } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);

  // Use translation if available, otherwise fall back to default content
  const nicaf = allWebContent?.[0]?.nicaf || {};
  const nicafTranslation = translationState?.[0]?.translations?.nicaf?.[userLanguage] || {};
  const content = Object.keys(nicafTranslation).length > 0 ? nicafTranslation : nicaf;



  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

return (
  <div className="App">
    <Header content={content} />

    <h1 className="media-heading">{content.title}</h1>


    <Blog content={content} />
    <Process content={content} />

    <Footer content={content} />

  </div>
);
}

export default NewImmigrantsCreditAndFinance;