// src/components/Blog.js

import React from 'react';
import BlogCard from './BlogCard';
import './BlogCard.css';
import nicaf1 from "../../../assets/img/personalFinance/nicaf1.jpg";
import nicaf2 from "../../../assets/img/personalFinance/nicaf2.jpg";
import nicaf3 from "../../../assets/img/personalFinance/nicaf3.jpg";
import nicaf4 from "../../../assets/img/personalFinance/nicaf4.jpg";



function Blog({ content }) {

  const blogPosts = [
    { title: content.blogtitle1, description: content.blogdescription1, image: nicaf1, date: content.blogdate1, author: content.blogauthor1, tag:content.blogtag1 },
    { title: content.blogtitle2, description: content.blogdescription2, image: nicaf2, date: content.blogdate2, author: content.blogauthor2, tag:content.blogtag2 },
    { title: content.blogtitle3, description: content.blogdescription3, image: nicaf3, date: content.blogdate3, author: content.blogauthor3, tag:content.blogtag3 },
    { title: content.blogtitle4, description: content.blogdescription4, image: nicaf4, date: content.blogdate4, author: content.blogauthor4, tag:content.blogtag4 }, // Add more posts as needed
  ];

  return (
    <section className="blog-container">
      {blogPosts.map((post, index) => (
        <BlogCard key={index} {...post} />
      ))}
    </section>
  );
}

export default Blog;
