import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { Button, Row, InputGroup, InputGroupAddon, Input } from "reactstrap";
import {
  GetChat,
  UpdateChatThread,
  getsingleSellerSupport,
  readTickets,
  replySellerSupport,
} from "../../redux/reducers/user";
import "./SupportTicket.css";
import "react-toastify/dist/ReactToastify.css";

const ViewSellerSupportDetail = (props) => {
  const { type } = props;
  const divRef = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams();

  const { user, singleProduct } = useSelector((state) => state.auth);
  const { singlesellersupport, chatList, loading } = useSelector(
    (state) => state.user
  );
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);

  const userDashboardTranslation =
    translationState?.[0]?.translations?.userDashboard?.[userLanguage];

  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];

  const [message, setMessage] = useState("");
  const [detailsData, setDetailsData] = useState({ chat: {}, to: "" });

  useEffect(() => {
    if (id) {
      dispatch(getsingleSellerSupport(id));
      dispatch(readTickets([id]));
    }
  }, []);

  useEffect(() => {
    let data = {};
    if (type === "customer") {
      data = {
        ...data,
        chat: chatList?.length ? chatList[0] : {},
        to: singleProduct?.sellerId?.id,
      };
    } else if (singlesellersupport?._id) {
      const sellerSupport = singlesellersupport?.thread?.find(
        (item) => item.from !== user?._id
        
      );
      data = { ...data, chat: singlesellersupport, to: sellerSupport?.from };
    } else {
      data = { ...data, chat: {} };
    }
    setDetailsData({ ...detailsData, ...data });
  }, [singlesellersupport, chatList]);

  useEffect(() => {
    divRef.current?.scrollIntoView({ block: "end", behavior: "smooth" });
  });

  const send = async (id) => {
    if (!message.trim()) {
      return toast.info(
        `${
          toastTranslation?.typeError
            ? toastTranslation?.typeError
            : "Type your message..."
        }`
      );
    }
    const data = { message, id, to: detailsData.to };
    if (type === "customer") {
      await dispatch(UpdateChatThread(data));
      dispatch(GetChat({}));
    } else {
      await dispatch(replySellerSupport(data));
      dispatch(getsingleSellerSupport(id));
    }
  };

  return (
    <>
      {loading ? (
        <div
          className="col-12 d-flex justify-content-center align-items-center"
          style={{ marginTop: "200px" }}
        >
          <i
            className="fas fa-spinner fa-pulse fa-2x"
            style={{ color: "green", textAlign: "center" }}
          ></i>
        </div>
      ) : (
        <>
          <ToastContainer />
          <div
            className="chat-margin"
            style={{ marginTop: type === "customer" && 0 }}
          >
            <div className="border p-3 rounded">
              <div className="row my-2">
                {type === "customer" ? (
                  <b>
                    {userDashboardTranslation?.chat
                      ? userDashboardTranslation?.chat
                      : "Chat With Seller"}
                  </b>
                ) : (
                  <b>
                    {userDashboardTranslation?.viewMessage
                      ? userDashboardTranslation?.viewMessage
                      : "View Message"}
                  </b>
                )}
              </div>
              {type !== "customer" && (
                <div
                  className=" border rounded p-3"
                  style={{ backgroundColor: "white" }}
                >
                  <div className="row">
                    <div className="col-5">
                      <b>
                        {" "}
                        {userDashboardTranslation?.AnnouncementId
                          ? userDashboardTranslation?.AnnouncementId
                          : "AnnouncementId"}
                      </b>
                    </div>
                    <div className="">{detailsData.chat?._id}</div>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <b>
                        {" "}
                        {userDashboardTranslation?.subjecttext
                          ? userDashboardTranslation?.subjecttext
                          : "Subject"}
                      </b>
                    </div>
                    <div className="">{detailsData.chat?.subject}</div>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <b>
                        {userDashboardTranslation?.dateTime
                          ? userDashboardTranslation?.dateTime
                          : "Date & Time"}
                      </b>
                    </div>
                    <div className="">
                      {moment(detailsData.chat?.createdAt).format(
                        "MMM / Do / YYYY  / hh:mm:ss"
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="my-3">
            <div>
              {detailsData.chat?.message && (
                <Row
                  className="announcementMessage"
                  style={{
                    float: detailsData.chat.from != user?._id ? "right" : "left",
                  }}
                >
                  {detailsData.chat.message}
                </Row>
              )}
              <br />
              {detailsData.chat?.thread?.map((item, index) => (
                <div key={index}>
                  <Row
                    className="announcementMessage"
                    style={{
                      float: item.from == user?._id ? "right" : "left",
                    }}
                  >
                    {item?.message}
                  </Row>
                  <br />
                </div>
              ))}
              <div ref={divRef} />
            </div>
            <Row className={type === "customer" ? "send-field" : "send-field1"}>
              <InputGroup
                className="input-prepend"
                style={{ width: type === "customer" && "72%" }}
              >
                <Input
                  placeholder={
                    userDashboardTranslation?.type
                      ? userDashboardTranslation.type
                      : "Type..."
                  }
                  name="message"
                  size="16"
                  type="text"
                  onChange={(e) => setMessage(e.target.value)}
                />
                <InputGroupAddon addonType="append">
                  <Button
                    color="warning"
                    onClick={() => send(detailsData.chat?._id)}
                  >
                    {userDashboardTranslation?.send
                      ? userDashboardTranslation?.send
                      : "Send"}
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default ViewSellerSupportDetail;
