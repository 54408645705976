// Process.js
import React, { useState } from 'react';
import './Process.css';

function Process({ content }) {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { title: content.stepstitle1, description: content.stepsdescription1 },
    { title: content.stepstitle2, description: content.stepsdescription2 },
    { title: content.stepstitle3, description: content.stepsdescription3 },
    { title: content.stepstitle4, description: content.stepsdescription4 },
    { title: content.stepstitle5, description: content.stepsdescription5 },
    { title: content.stepstitle6, description: content.stepsdescription6 },
    { title: content.stepstitle7, description: content.stepsdescription7 },
    { title: content.stepstitle8, description: content.stepsdescription8 },
  ];

  return (
    <section className="processSection">
      <div className="processHeader">
        <h2 className="processHeading">Our Process</h2>
        <div className="processUnderline"></div>
      </div>
      
      <div className="processContainer">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`processStep ${index === activeStep ? 'active' : ''}`}
            onClick={() => setActiveStep(index)}
          >
            <div className="stepNumber">{index + 1}</div>
            <div className="stepContent">
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
            {index === activeStep && <div className="activeIndicator"></div>}
          </div>
        ))}
      </div>
    </section>
  );
}

export default Process;