import React from 'react';
import { useHistory } from 'react-router-dom';
import './MediaComponents.css';

const EventCard = ({ content, onEventClick }) => {
  const eventDate = new Date(content.date + 'T' + content.time);
  const now = new Date();
  const isUpcoming = eventDate > now;

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }).format(date);
  };

  return (
    <div className="event-card" onClick={() => onEventClick(content)}>
      <div className="event-status">
        <span className={`status-indicator ${isUpcoming ? 'upcoming' : 'past'}`}>
          {isUpcoming ? 'Upcoming' : 'Past Event'}
        </span>
      </div>
      <div 
        className="media-thumbnail" 
        style={{ backgroundImage: `url(${content.thumbnailUrl})` }}
      >
        <div className="event-overlay">
          <div className="event-date-badge">
            <span className="event-month">
              {eventDate.toLocaleString('default', { month: 'short' })}
            </span>
            <span className="event-day">
              {eventDate.getDate()}
            </span>
          </div>
        </div>
      </div>
      <div className="media-content">
        <h3 className="media-title">{content.eventTitle}</h3>
        <p className="event-datetime">{formatDate(eventDate)}</p>
        <p className="media-description">{content.description}</p>
        <div className="event-price">
          
          <button className="join-button">Join Event</button>
        </div>
      </div>
    </div>
  );
};

const CourseCard = ({ content }) => {
  const history = useHistory();
  const uploadDate = new Date(content.date + 'T' + content.time);

  const handleCourseClick = () => {
    history.push(`/media/${content.eventTitle}`, {
      title: content.eventTitle,
      videoUrl: content.videoUrl,
      description: content.description,
    });
  };

  return (
    <div className="course-card" onClick={handleCourseClick}>
      <div 
        className="media-thumbnail" 
        style={{ backgroundImage: `url(${content.thumbnailUrl})` }}
      >
        <div className="course-overlay">
          <span className="play-icon">▶</span>
        </div>
      </div>
      <div className="media-content">
        <h3 className="media-title">{content.eventTitle}</h3>
        <p className="upload-date">
          Uploaded on {uploadDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}
        </p>
        <p className="media-description">{content.description}</p>
        <div className="course-price">
          
          <button className="enroll-button">Start Learning</button>
        </div>
      </div>
    </div>
  );
};

const MediaGrid = ({ contents, onEventClick }) => {
  return (
    <div className="media-grid">
      {contents.map((content, index) => (
        content.category === 'event' ? (
          <EventCard 
            key={content._id || index} 
            content={content} 
            onEventClick={onEventClick}
          />
        ) : (
          <CourseCard 
            key={content._id || index} 
            content={content}
          />
        )
      ))}
    </div>
  );
};

export { MediaGrid, EventCard, CourseCard };