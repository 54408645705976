import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getAllWebContent } from '../../redux/reducers/user';
import CallToAction from '../PersonalFinance/components/CallToAction';
import Footer from "../PersonalFinance/components/Footer";
import Header from "../PersonalFinance/components/Header";
import './About.css';

import team from './team.jpg';


const About = () => {
  const dispatch = useDispatch();

  const { translationState, allWebContent } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);

  // Use translation if available, otherwise fall back to default content
  const about = allWebContent?.[0]?.about || {};

  const aboutTranslation = translationState?.[0]?.translations?.about?.[userLanguage] || {};
  const content = Object.keys(aboutTranslation).length > 0 ? aboutTranslation : about;

  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);



  return (
    <>
      <Header content={content} />

      <div className="about-container">
        <div className="about-hero">
          <h1>{content.aboutTitle}</h1>
          <p>{content.aboutDescription}</p>
        </div>

        <div className="about-stats">
          <div className="stat-item">
            <div className="stat-number">{content.statFounded}</div>
            <p>{content.statFoundedLabel}</p>
          </div>
          <div className="stat-item">
            <div className="stat-number">{content.statClients}</div>
            <p>{content.statClientsLabel}</p>
          </div>
          <div className="stat-item">
            <div className="stat-number">{content.statUnbiased}</div>
            <p>{content.statUnbiasedLabel}</p>
          </div>
        </div>

        <div className="team-section">
          <h2>{content.teamTitle}</h2>
          <p>{content.teamDescription}</p>
          <img
            src={team}
            alt={content.teamImageAlt}
            className="team-image"
          />
        </div>

        <div className="mission-section">
          <h2>{content.missionTitle}</h2>
          <p>
            {content.missionDescriptionPart1}
          </p>
          <p>{content.missionDescriptionPart2}</p>
        </div>
      </div>

      {/* <CallToAction content={content} /> */}
      <Footer content={content} />




    </>
  );
};

export default About;
