export default {
  items: [
    {
      name: "CMS",
      url: "/admin/content-management",
      icon: "fab fa-page4",
      badge: {
        variant: "info",
        text: "",
      },
    },
    {
      name: "Website Content",
      url: "/admin/translationfieldcomponent",
      icon: "fa-solid fa-pen-nib",
    },
    {
      name: "Courses and Events",
      url: "/admin/coursesandeventsfield",
      icon: "fa-solid fa-chalkboard-teacher",  // This icon represents teaching or courses
    },
    
    {
      name: "Media Content",
      url: "/admin/mediacontent",
      icon: "fa-solid fa-video",  // This icon represents media content
    },

        
    

    {
      name: "Contact Us",
      url: "/admin/personalInfo",
      icon: "fa fa-handshake-o",
    },
    {
      name: "Digital Categories",
      url: "/admin/product/category/digital",
      icon: "fas fa-sitemap",
    },
    {
      name: "Physical Categories",
      url: "/admin/product/category/physical",
      icon: "fas fa-sitemap",
    },
    {
      name: "Res. Categories",
      url: "/admin/product/category/residency",
      icon: "fas fa-sitemap",
    },
    {
      name: "Product",
      url: "/admin/products",
      icon: "fab fa-product-hunt",
    },
    {
      name: "Digital Products",
      url: "/admin/digitalproductlist",
      icon: "fab fa-dyalog",
    },
    {
      name: "Sellers",
      url: "/admin/sellers",
      icon: "fas fa-users",
      badge: {
        variant: "info",
        text: "",
      },
    },
    {
      name: "Users",
      url: "/admin/users",
      icon: "fas fa-users",
      badge: {
        variant: "info",
        text: "",
      },
    },
    {
      name: "Orders",
      url: "/admin/order",
      icon: "fab fa-first-order-alt",
    },
    {
      name: "Customers",
      url: "/admin/customer",
      icon: "fas fa-user-tie",
      badge: {
        variant: "info",
        text: "",
      },
    },
    {
      name: "Seller Support",
      url: "/admin/seller-support",
      icon: "fa-sharp fa-solid fa-ticket",
    },
    {
      name: "Advertise Charges",
      url: "/admin/charges",
      icon: "fab fa-adversal",
    },
    {
      name: "Advertise",
      url: "/admin/advertisement",
      icon: "fab fa-adversal",
    },
    {
      name: "Announcement",
      url: "/admin/announcementlist",
      icon: "fas fa-bullhorn",
    },
    {
      name: "DMCA Takedown",
      url: "/admin/dmca-copyrights",
      icon: "fas fa-flag",
    },
    {
      name: "Lend Loan",
      url: "/admin/lend-loan",
      icon: "fas fa-dollar",
    },
    {
      name: "Payout Requests",
      url: "/admin/payout-requests",
      icon: "fas fa-dollar",
    },
    {
      name: "Wallets",
      url: "/admin/wallet-lists",
      icon: "fas fa-wallet",
    },
    {
      name: "Disputes",
      url: "/admin/disputeManagement",
      icon: "fas fa-frown",
    },
    {
      name: "System settings",
      url: "/admin/systemSettings",
      icon: "fas fa-cog",
    },
    {
      name: "Social Links",
      url: "/admin/socialLinks",
      icon: "fas fa-cog",
    },
    {
      name: "Streaming Config",
      url: "/admin/streamingConfig",
      icon: "fas fa-users",
      badge: {
        variant: "info",
        text: "",
      },
    },
    {
      name: "Update Password",
      url: "/admin/updateAdminPassword",
      icon: "fa-solid fa-lock",
    },
    {
      name: "Update Plans",
      url: "/admin/updateMembershipPrices",
      icon: "fab fa-meetup",
    },
  ],
};
