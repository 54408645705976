import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getYinnExhangeRate,
  buyWithLocalBank,
  buyWithYinnCoin,
  setClearItem,
} from "../../../redux/reducers/user";
import "../../checkout/shipping.css";
import CourseSummary from "./CourseSummary";
import ynCoinIcon from "../../../assets/img/checkout/yinncoin.jpg";
import bankTransferIcon from "../../../assets/img/checkout/bank-transfer-svgrepo-com.svg";
import "react-toastify/dist/ReactToastify.css";

// Comprehensive translation object
const translation = {
  paymentInformation: {
    en: "Payment Information",
    es: "Información de Pago",
    fr: "Informations de Paiement",
    zh: "支付信息",
    pt: "Informações de Pagamento",
    ar: "معلومات الدفع"
  },
  paymentMethod: {
    en: "Payment Method",
    es: "Método de Pago",
    fr: "Méthode de Paiement",
    zh: "支付方式",
    pt: "Método de Pagamento",
    ar: "طريقة الدفع"
  },
  completePayment: {
    en: "Complete Payment",
    es: "Completar Pago",
    fr: "Finaliser le Paiement",
    zh: "完成支付",
    pt: "Concluir Pagamento",
    ar: "إتمام الدفع"
  },
  yinnCoinToPay: {
    en: "Yinn Coin to Pay",
    es: "Yinn Coin a Pagar",
    fr: "Yinn Coin à Payer",
    zh: "要支付的 Yinn 币",
    pt: "Yinn Coin a Pagar",
    ar: "Yinn Coin للدفع"
  },
  errors: {
    selectPaymentMethod: {
      en: "Please select a payment method.",
      es: "Por favor, seleccione un método de pago.",
      fr: "Veuillez sélectionner un mode de paiement.",
      zh: "请选择支付方式。",
      pt: "Por favor, selecione um método de pagamento.",
      ar: "يرجى اختيار طريقة الدفع."
    },
    fillPaymentDetails: {
      en: "Please fill in all payment details.",
      es: "Por favor, complete todos los detalles de pago.",
      fr: "Veuillez remplir tous les détails de paiement.",
      zh: "请填写所有支付详细信息。",
      pt: "Por favor, preencha todos os detalhes de pagamento.",
      ar: "يرجى ملء جميع تفاصيل الدفع."
    },
    transactionFailed: {
      en: "Unable to complete the transaction.",
      es: "No se pudo completar la transacción.",
      fr: "Impossible de terminer la transaction.",
      zh: "无法完成交易。",
      pt: "Não foi possível concluir a transação.",
      ar: "تعذر إتمام المعاملة."
    }
  },
  inputs: {
    firstName: {
      en: "First Name",
      es: "Nombre",
      fr: "Prénom",
      zh: "名字",
      pt: "Primeiro Nome",
      ar: "الاسم الأول"
    },
    lastName: {
      en: "Last Name",
      es: "Apellido",
      fr: "Nom de famille",
      zh: "姓氏",
      pt: "Sobrenome",
      ar: "اسم العائلة"
    },
    zipCode: {
      en: "ZIP Code",
      es: "Código Postal",
      fr: "Code Postal",
      zh: "邮政编码",
      pt: "Código Postal",
      ar: "الرمز البريدي"
    },
    cardNumber: {
      en: "Card Number",
      es: "Número de Tarjeta",
      fr: "Numéro de Carte",
      zh: "卡号",
      pt: "Número do Cartão",
      ar: "رقم البطاقة"
    },
    securityCode: {
      en: "Security Code",
      es: "Código de Seguridad",
      fr: "Code de Sécurité",
      zh: "安全码",
      pt: "Código de Segurança",
      ar: "رمز الأمان"
    },
    expirationMonth: {
      en: "Expiration Month",
      es: "Mes de Expiración",
      fr: "Mois d'Expiration",
      zh: "到期月份",
      pt: "Mês de Expiração",
      ar: "شهر الانتهاء"
    },
    expirationYear: {
      en: "Expiration Year",
      es: "Año de Expiración",
      fr: "Année d'Expiration",
      zh: "到期年份",
      pt: "Ano de Expiração",
      ar: "سنة الانتهاء"
    }
  }
};

const paymentMethods = [
  {
    name: "Local Bank Transfer",
    imgSrc: bankTransferIcon,
  },
  {
    name: "Yinn Coin",
    imgSrc: ynCoinIcon,
  },
];

const Payment = (props) => {
  const history = useHistory();
  const { usdRate } = useSelector((state) => state.auth);
  const { userCurrency } = useSelector((state) => state.auth);
  const { userLanguage } = useSelector((state) => state.auth || {});
  const dispatch = useDispatch();

  // Language selection logic
  const selectedLanguage = (() => {
    const normalizedLanguage = userLanguage ? userLanguage.toLowerCase().split('-')[0] : null;
    
    if (normalizedLanguage && translation.paymentInformation[normalizedLanguage]) {
      return normalizedLanguage;
    }
    
    return 'en';
  })();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [coinExchangeValue, setCoinExchangeValue] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState({
    firstName: "",
    lastName: "",
    zip: "",
    cardNumber: "",
    securityCode: "",
    expirationMonth: "",
    expirationYear: "",
  });

  const total = localStorage.getItem("totalInLocalCurrency");

  useEffect(() => {
    window.scrollTo(0, 0);
    loadYinnCoinExchangeRate();
    dispatch(setClearItem({ field: "localBankTransferAccountDetails" }));
  }, [dispatch]);

  const loadYinnCoinExchangeRate = async () => {
    dispatch(getYinnExhangeRate()).then((response) => {
      setCoinExchangeValue(response?.payload?.yinnCoinExchnageRate);
    });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const yinnCoinToPay = coinExchangeValue * total;

  const data = props.data;



  const makePayment = async () => {
    if (!selectedPaymentMethod) {
      toast.error(translation.errors.selectPaymentMethod[selectedLanguage], {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (
      selectedPaymentMethod === "Local Bank Transfer" &&
      (!paymentDetails.firstName ||
        !paymentDetails.lastName ||
        !paymentDetails.zip ||
        !paymentDetails.cardNumber ||
        !paymentDetails.securityCode ||
        !paymentDetails.expirationMonth ||
        !paymentDetails.expirationYear)
    ) {
      toast.error(translation.errors.fillPaymentDetails[selectedLanguage], {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const paymentData = {
      usdRate,
      total,
      userId: data.user_id,
      courseId: data.event.id,
      userCurrency,
    };

    try {
      if (selectedPaymentMethod === "Local Bank Transfer") {
        paymentData.paymentDetails = paymentDetails;
        const result = await dispatch(buyWithLocalBank(paymentData)).unwrap();

        toast.success(
          `Purchase successful! Transaction ID: ${result.transactionId}`,
          { position: toast.POSITION.TOP_RIGHT }
        );

        // Delay the navigation
        setTimeout(() => {
          history.push("/my-courses");
        }, 2500);
      } else if (selectedPaymentMethod === "Yinn Coin") {
        paymentData.yinnCoinAmount = yinnCoinToPay;
        const result = await dispatch(buyWithYinnCoin(paymentData)).unwrap();

        toast.success(
          `Purchase successful! Remaining Yinn Coin Balance: ${result.remainingBalance}`,
          { position: toast.POSITION.TOP_RIGHT }
        );

        // Delay the navigation
        setTimeout(() => {
          history.push("/my-courses");
        }, 2500);
      }
    } catch (error) {
      toast.error(
        error?.message || "An error occurred during the payment process.",
        { position: toast.POSITION.TOP_RIGHT }
      );
    }
  };
  



  const renderPaymentInputs = () => (
    <div style={{ marginTop: "1.5rem" }}>
      {[
        { label: translation.inputs.firstName[selectedLanguage], name: "firstName", type: "text" },
        { label: translation.inputs.lastName[selectedLanguage], name: "lastName", type: "text" },
        { label: translation.inputs.zipCode[selectedLanguage], name: "zip", type: "text" },
        { label: translation.inputs.cardNumber[selectedLanguage], name: "cardNumber", type: "text" },
        { label: translation.inputs.securityCode[selectedLanguage], name: "securityCode", type: "text" },
        { label: translation.inputs.expirationMonth[selectedLanguage], name: "expirationMonth", type: "text" },
        { label: translation.inputs.expirationYear[selectedLanguage], name: "expirationYear", type: "text" },
      ].map((input) => (
        <div key={input.name} style={{ marginBottom: "1rem" }}>
          <label
            htmlFor={input.name}
            style={{
              display: "block",
              marginBottom: "0.5rem",
              color: "#475569",
              fontWeight: "500",
            }}
          >
            {input.label}
          </label>
          <input
            type={input.type}
            id={input.name}
            name={input.name}
            value={paymentDetails[input.name]}
            onChange={handleChange}
            style={{
              width: "100%",
              padding: "0.75rem 1rem",
              border: "2px solid #EEAC1A",
              borderRadius: "8px",
              fontSize: "1rem",
              transition: "border-color 0.2s ease",
              outline: "none",
            }}
            placeholder={`Enter ${input.label}`}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "2rem" }}>
      <Row>
        <ToastContainer autoClose={5000}  style={{ zIndex: 9999999 }} />
        <Col xs="12" lg="7">
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "16px",
              padding: "2rem",
            }}
          >
            <h2
              style={{
                fontSize: "1.875rem",
                fontWeight: "600",
                marginBottom: "1.5rem",
              }}
            >
              {translation.paymentInformation[selectedLanguage]}
            </h2>
            <div>
              <div style={{ fontSize: "1.125rem", marginBottom: "1rem" }}>
                {translation.paymentMethod[selectedLanguage]}
              </div>
              {paymentMethods.map((method) => (
                <div
                  key={method.name}
                  style={{
                    cursor: "pointer",
                    border:
                      selectedPaymentMethod === method.name
                        ? "2px solid #EEAC1A"
                        : "2px solid #e2e8f0",
                    borderRadius: "12px",
                    margin: "0 0 1rem 0",
                    padding: "1.25rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => setSelectedPaymentMethod(method.name)}
                >
                  <img
                    src={method.imgSrc}
                    alt={method.name}
                    style={{
                      width: "48px",
                      height: "48px",
                      marginRight: "1rem",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                  />
                  <span style={{ fontSize: "1rem", fontWeight: "500" }}>
                    {method.name}
                  </span>
                </div>
              ))}
              {selectedPaymentMethod === "Local Bank Transfer" &&
                renderPaymentInputs()}
              {selectedPaymentMethod === "Yinn Coin" && (
                <div
                  style={{
                    marginTop: "1rem",
                    padding: "1rem",
                    backgroundColor: "#EEAC1A",
                    borderRadius: "8px",
                  }}
                >
                  {translation.yinnCoinToPay[selectedLanguage]}: {yinnCoinToPay || "Calculating..."}
                </div>
              )}
            </div>
            <button
              onClick={makePayment}
              style={{
                width: "100%",
                padding: "1rem",
                backgroundColor: selectedPaymentMethod
                  ? "#EEAC1A"
                  : "#94a3b8",
                color: "white",
                border: "none",
                borderRadius: "8px",
                marginTop: "1.5rem",
                cursor: selectedPaymentMethod ? "pointer" : "not-allowed",
              }}
              disabled={!selectedPaymentMethod}
            >
              {translation.completePayment[selectedLanguage]}
            </button>
          </div>
        </Col>
        <Col xs="12" lg="5">
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "16px",
              padding: "2rem",
            }}
          >
            <CourseSummary event={props.data.event} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Payment;