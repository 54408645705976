import React from 'react';
import './TestimonialContact.css';

function TestimonialContact({ content }) {
  return (

    <div className="TestimonialContact-main" >

      <div >
        <h1 className="TestimonialContact-heading">
          {content.availableHeading}
        </h1>
      </div>


      <div className="TestimonialContact">




        <div className="Contact1">
          <h2>
            {content.availableText1}
          </h2>
        </div>

        <div className="Contact2">
          <h2>
            {content.availableText2}
          </h2>
        </div>




      </div>


    </div>


  )
}

export default TestimonialContact