import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../../config/axios.js";

import Config from "../../../config/aws";
import {
  getPresigedUrl,
  uploadToS3ViaSignedUrl,
} from "../../../helpers/s3Utils";
import {
  MediaTranslationContent,
  deleteCoursesContent,
} from "../../../redux/reducers/admin";
import {
  StopRecording
} from "../../../redux/reducers/seller";
import { getAllWebContent } from "../../../redux/reducers/user";
import { Plus, X, Upload } from "lucide-react";
import { createEventAnnouncement } from "../../../redux/reducers/admin";
import WebRTCComponent from "../../Events/videoEvent/publisher";
import "react-toastify/dist/ReactToastify.css";
import { sleep } from "../../../helpers/custom.js";


const style = `
  .media-content {
    padding: 2rem;
    width: 100%;
    margin: 0 auto;
    background: linear-gradient(to bottom right, #ffffff, #f8f9fa);
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    overflow-x: auto;
  }

  .media11-controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
    padding: 1rem;
  }

  .media11-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background: linear-gradient(135deg, #3b82f6, #2563eb);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(59, 130, 246, 0.2);
    text-decoration: none;
    min-width: fit-content;
  }

  .media11-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(59, 130, 246, 0.3);
    opacity: 0.9;
  }

  .media11-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(59, 130, 246, 0.2);
  }

  .media11-button svg {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }

  .media11-button span {
    white-space: nowrap;
  }

  .media-header h1 {
    font-size: 2rem;
    font-weight: 700;
    color: #1f2937;
    margin: 2rem 0;
    border-bottom: 3px solid #3b82f6;
    padding-bottom: 0.5rem;
    display: inline-block;
  }

  .media-table {
    width: 100%;
    border-spacing: 0;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  .media-table th, .media-table td {
    padding: 1.25rem 1rem;
    font-weight: bold;
    color: #374151;
    font-size: 0.875rem;
    text-align: center;
    justify-self: center;
  }

  .media-table th {
    background: #f3f4f6;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .media-table td {
    border-bottom: 1px solid #e5e7eb;
    color: #4b5563;
  }

  .media-table tr:hover {
    background-color: #f9fafb;
  }

  .media-thumbnail {
    width: 100%;
    height: 20vh;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }

  .media-thumbnail:hover {
    transform: scale(1.05);
  }

  .delete-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: linear-gradient(135deg, #ef4444, #dc2626);
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.875rem;
    opacity: 0.9;
    margin: 0 auto;
  }

  .delete-button:hover {
    opacity: 1;
    transform: translateY(-1px);
  }

  .media-form {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    border: 1px solid #e5e7eb;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  .media-form h2 {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: #1f2937;
    border-bottom: 2px solid #3b82f6;
    padding-bottom: 0.5rem;
    display: inline-block;
  }

  .form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
  }

  .form-grid div {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .form-grid label {
    font-weight: 600;
    color: #374151;
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  
.form-grid input[type="text"],
.form-grid input[type="date"],
.form-grid input[type="time"],
.form-grid textarea,
.form-grid select {
  padding: 0.875rem;
  border: 2px solid #e5e7eb;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-grid input[type="text"]:focus,
.form-grid input[type="date"]:focus,
.form-grid input[type="time"]:focus,
.form-grid textarea:focus,
.form-grid select:focus {
  border-color: #3b82f6;
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.form-grid select option {
  font-size: 1rem;
}


  .file-input-container {
    position: relative;
  }

  .file-input-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.875rem;
    background: #f3f4f6;
    border: 2px dashed #d1d5db;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .file-input-label:hover {
    border-color: #3b82f6;
    background: #f0f9ff;
  }

  .file-input {
    position: absolute;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
  }

  .submit-button {
    padding: 0.875rem 2rem;
    background: linear-gradient(135deg, #3b82f6, #2563eb);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
    min-width: 120px;
  }

  .submit-button:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(59, 130, 246, 0.3);
  }

  .submit-button:disabled {
    background: #d1d5db;
    cursor: not-allowed;
  }

  .loading-text {
    color: #6b7280;
    font-size: 0.875rem;
    font-style: italic;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .loading-text::before {
    content: "";
    width: 16px;
    height: 16px;
    border: 2px solid #6b7280;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to { transform: rotate(360deg); }
  }

  @media (max-width: 767px) {
    .media-content {
      padding: 1rem;
    }

    .media-header h1 {
      font-size: 1.5rem;
    }

    .form-grid {
      grid-template-columns: 1fr;
    }

    .media-table th,
    .media-table td {
      padding: 0.75rem;
      font-size: 0.75rem;
    }

    .media-thumbnail {
      height: 100px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .media-content {
      padding: 1.5rem;
    }

    .form-grid {
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }

    .media-thumbnail {
      height: 80px;
    }
  }

  @media (min-width: 1024px) {
    .media-content {
      padding: 2.5rem;
    }

    .media-header h1 {
      font-size: 2rem;
    }

    .form-grid {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .media-thumbnail {
      height: 120px;
    }
  }
`;

const CoursesandEventsContent = () => {


  const dispatch = useDispatch();
  const [imageLoading, setImageLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [disable, setDisable] = useState(true);
  const [imageName, setImageName] = useState("");
  const [showWebRTC, setShowWebRTC] = useState(false);
  const [rtcProp, setRtcProps] = useState({ eventTokenId: "", productId: "" });

  const [formData, setFormData] = useState({
    page: "coursesAndEvents",
    _id: "",
    eventTitle: "",
    category: "",
    description: "",
    date: "",
    time: "",
    thumbnailUrl: "",
    productEventTokenId: "",
    pricing: "",
    videoUrl: "", // Add video URL field
  });

  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

  const { allWebContent } = useSelector((state) => state.user);
  const MediaData = allWebContent?.[0]?.coursesAndEvents || [];

  const uploadMedia = async (file, fileType) => {
    const params = {
      Body: file,
      Bucket: Config.bucketName,
      Key: file.name,
    };

    if (fileType === "thumbnailUrl") {
      setImageLoading(true);
      setImageName(file.name);
    }

    try {
      const signedUrlResp = await getPresigedUrl({
        fileName: params.Key,
        bucketName: params.Bucket,
        contentType: file.type,
      });

      await uploadToS3ViaSignedUrl({
        signedUrl: signedUrlResp.signedUrl,
        contentType: file.type,
        body: params.Body,
      });

      const fileUrl = `${Config.digitalOceanSpaces}/${file.name}`;
      setFormData((prevData) => ({
        ...prevData,
        [fileType]: fileUrl,
      }));
      toast.success("File uploaded successfully!");
    } catch (error) {
      toast.error("Error in uploading file! Please try again");
    } finally {
      if (fileType === "thumbnailUrl") {
        setImageLoading(false);
      }
    }
  };

  const handleCloseLiveButton = async () => {
    try {
      setShowWebRTC(false);
      await dispatch(StopRecording({ streamName: rtcProp.eventTokenId }));
      await sleep(5);
      
      const recordingUrl = `https://live.yinn.ca:5443/live/streams/${rtcProp.eventTokenId}.flv?accessToken=Root001`;
      const response = await axios.get(recordingUrl, { responseType: "blob" });
      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.data;
      if (blob.size === 0) {
        throw new Error("Downloaded file is empty");
      }
      const urlBlob = URL.createObjectURL(blob);
      // Create a link element to trigger the download
      const a = document.createElement("a");
      a.href = urlBlob;
      a.download = `${rtcProp.eventTokenId}.flv`;
      document.body.appendChild(a);
      a.click();
      a.parentNode.removeChild(a);
      URL.revokeObjectURL(urlBlob);
    

    } catch (err) {
      console.error("🚀 ~ file: Streaming.js:76 ~ err:", err.message);
      toast.error(err.message);

    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDisable(false);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLive = async (event) => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    } catch (error) {
      toast.error(
        "Access to camera and microphone is blocked. Please allow it."
      );
      return;
    }

    const { _id: productId, productEventTokenId } = event;
    const message = `Event is starting on ID: ${productEventTokenId}`;
    const data = { productId, message };
    dispatch(createEventAnnouncement(data));

    setRtcProps({ eventTokenId: productEventTokenId, productId });
    setShowWebRTC(true);

  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    if (file) {
      uploadMedia(file, fileType);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    const randomToken = Math.floor(Math.random() * 10000000)?.toString();
    formData.productEventTokenId = randomToken;

    if (formData.category === "course") {
      const currentDate = new Date();
      
      formData.date = currentDate.toISOString().split("T")[0]; 
      
      formData.time = currentDate.toTimeString().split(" ")[0].slice(0, 5); 
    }
    

    dispatch(MediaTranslationContent(formData))
      .then(() => {
        toast.success("Media content added successfully!");
        setShowForm(false);
        resetForm();
        dispatch(getAllWebContent());
      })
      .catch(() => {
        toast.error("Error adding media content!");
      });
  };

  const resetForm = () => {
    setFormData({
      page: "coursesAndEvents",
      _id: "",
      eventTitle: "",
      category: "",
      description: "",
      date: "",
      time: "",
      thumbnailUrl: "",
      productEventTokenId: "",
      pricing: "",
      videoUrl: "",
    });
    setImageName("");
    setDisable(true);
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this Course?")) {
      
      dispatch(deleteCoursesContent({ id }))
        .then(() => {
          toast.success("Course deleted successfully!");
          dispatch(getAllWebContent());
        })
        .catch((error) => {
          toast.error("Error deleting media");
          console.error("Delete error:", error);
        });
    }
  };



  return (
    <>
                 

      <style>{style}</style>
      {showWebRTC ? (
        <WebRTCComponent rtcProp={rtcProp} onClose={handleCloseLiveButton} />
      ) : (
        <div className="media-content">
          <div className="media11-controls">
            <button
              className="media11-button"
              onClick={() => setShowForm(!showForm)}
            >
              {showForm ? <X size={20} /> : <Plus size={20} />}
              <span>{showForm ? "Close Form" : "Add New Courses/Events"}</span>
            </button>
          </div>

          {showForm && (
            <div className="media-form">
  <h2>Add New Courses/Events</h2>
  <form onSubmit={handleSubmit}>
    <div className="form-grid">
      <div>
        <label>Title</label>
        <input
          type="text"
          name="eventTitle"
          value={formData.eventTitle}
          onChange={handleInputChange}
          placeholder="Enter title"
          required
        />
      </div>
      <div>
        <label>Category</label>
        <select
          name="category"
          value={formData.category}
          onChange={handleInputChange}
          required
        >
          <option value="">Select a category</option>
          <option value="course">Course</option>
          <option value="event">Event</option>
        </select>
      </div>

      <div>
        <label>Description</label>
        <textarea
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Enter description"
          required
        ></textarea>
      </div>

      {/* Render Date and Time fields only if category is "event" */}
      {formData.category !== "course" && (
        <>
          <div>
            <label>Date</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleInputChange}
              required
            />
          </div>

          <div>
            <label>Time</label>
            <input
              type="time"
              name="time"
              value={formData.time}
              onChange={handleInputChange}
              required
            />
          </div>
        </>
      )}

      <div>
        <label>Pricing</label>
        <input
          type="text"
          name="pricing"
          value={formData.pricing}
          onChange={handleInputChange}
          placeholder="Enter pricing details"
          required
        />
      </div>

      <div className="file-input-container">
       <label>Upload Thumbnail</label>
        <label className="file-input-label">
          <Upload size={20} />
          <span>{imageName || "Thumbnail"}</span>
          <input
            type="file"
            className="file-input"
            accept="image/*"
            onChange={(e) => handleFileChange(e, "thumbnailUrl")}
          />
        </label>
        {imageLoading && <span className="loading-text">Uploading...</span>}
      </div>

      {formData.category === "course" && (
        <div className="file-input-container">
          <label>Upload Thumbnail</label>
          <label className="file-input-label">
            <Upload size={20} />
            <span>
              {formData.videoUrl ? "Video Uploaded" : "Upload Video"}
            </span>
            <input
              type="file"
              className="file-input"
              accept="video/*"
              onChange={(e) => handleFileChange(e, "videoUrl")}
            />
          </label>
        </div>
      )}
    </div>

    <button
  type="submit"
  className="submit-button"
  disabled={disable}
  style={{
    backgroundColor: disable ? 'gray' : 'blue', // Change the color based on the disable state
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    cursor: disable ? 'not-allowed' : 'pointer'
  }}
>
  {disable ? 'Uploading...' : 'Submit'}
</button>

  </form>
</div>

          )}

          <div className="media-header">
            <h1>Courses and Events</h1>
          </div>

          <table className="media-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Category</th>
                <th>Description</th>
                <th>Date & Time</th>
                <th>Pricing</th>
                <th>Thumbnail</th>
                <th>Video / Live</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {MediaData.map((item, index) => (
                <tr key={index}>
                  <td>{item.eventTitle}</td>
                  <td>{item.category}</td>
                  <td>{item.description}</td>
                  <td>
                    {item.date} <br /> {item.time}
                  </td>
                  <td>{item.pricing}</td>
                  <td>
                    <img
                      src={item.thumbnailUrl}
                      alt="Thumbnail"
                      className="thumbnail-image"
                    />
                  </td>
                  <td>
                    {item.category === "course" ? (
                      item.videoUrl ? (
                        <a
                          href={item.videoUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Video
                        </a>
                      ) : (
                        "No video available"
                      )
                    ) : (
                      <button
                        onClick={() => handleLive(item)}
                        className="live-button"
                      >
                        Start Live
                      </button>
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => handleDelete(item._id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default CoursesandEventsContent;
