import React from "react";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import "../../checkout/summary.css";

const CourseSummary = (props) => {
  const { userCurrency } = useSelector((state) => state.auth);

  const { usdRate, paymentConfiguration, userLanguage } = useSelector((state) => state.auth);
  const { translationState } = useSelector((state) => state.user);


  const summaryTranslation = translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const myCartTranslation = translationState?.[0]?.translations?.myCart?.[userLanguage];

  // Assuming 'total' is passed as a prop, which is the event price.
  let totalInUSD = parseFloat(props.event.price); // Ensure it's a number

  // Convert to the user's local currency
  const convertToCurrency = (amountInUSD) => {
    return amountInUSD * usdRate; // Return as a number
  };

  // Convert the base price to the local currency
  const basePriceInLocalCurrency = convertToCurrency(totalInUSD);

  // Convert the checkout fee to the local currency
  const checkoutFeeInUSD = paymentConfiguration?.checkoutPaymentFixCharge || 0; // Default to 0 if not available
  const checkoutFeeInLocalCurrency = checkoutFeeInUSD * usdRate;

  // Calculate the total in the local currency
  const totalInLocalCurrency = basePriceInLocalCurrency + checkoutFeeInLocalCurrency;

  const basetotalInUsd = totalInUSD+ checkoutFeeInUSD;

// Store it in localStorage
localStorage.setItem('totalInLocalCurrency', basetotalInUsd);

  // Format the values for display
  const basePriceFormatted = basePriceInLocalCurrency.toFixed(2) + ` ${userCurrency}`;
  const checkoutFeeFormatted = checkoutFeeInLocalCurrency.toFixed(2) + ` ${userCurrency}`;
  const totalFormatted = totalInLocalCurrency.toFixed(2) + ` ${userCurrency}`;

  return (
    <div className="summary-container">
      <Row>
        <Col xs="0" sm="1"></Col>
        <Col xs="12" sm="10">
          {/* Pricing */}
          <div className="summary-title-text" style={{ color: '#EEAC1A' }}>
            {summaryTranslation?.summary || "Summary"}
          </div>
          <div className="summary-description-text">
            {summaryTranslation?.paraLine || "Additional charges will be added based on your payment method."}
          </div>

          <div className="summary-entry-row">
            <div className="summary-entry-text">
              {summaryTranslation?.basePrice || "Base Price"}
            </div>
            <div className="summary-entry-value">
              {basePriceFormatted}
            </div>
          </div>

          {/* Checkout Payment Fee */}
          <div className="summary-entry-row">
            <div className="summary-entry-text">
              {summaryTranslation?.checkoutFee || "Checkout payment fee"}
            </div>
            <div className="summary-entry-value">
              {checkoutFeeFormatted}
            </div>
          </div>

          <div className="summary-separator"></div>

          {/* Total Price */}
          <div className="summary-entry-row">
            <div className="summary-total-text">
              {myCartTranslation?.total || "Total"}
            </div>
            <div className="summary-total-text">
              {totalFormatted}
            </div>
          </div>
        </Col>
        <Col xs="0" sm="1"></Col>
      </Row>
    </div>
  );
};

export default CourseSummary;
