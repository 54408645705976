import { AppHeader } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ContactUs } from "../../../redux/reducers/auth";
import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";
import "./contact.css";
const ContactYinn = () => {
  const { translationState, allWebContent } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const contectUs = allWebContent?.[0]?.contectUs;
  const contectUsTranslation = translationState?.[0]?.translations?.contectUs?.[userLanguage];
  const toastTranslation = translationState?.[0]?.translations?.toast?.[userLanguage];

  const dispatch = useDispatch();
  const [pageObject, setPageObject] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setPageObject({
      ...pageObject,
      [name]: value,
    });
  };
  const sendEmail = () => {
    const { name, email, contact, message } = pageObject;
    if (name == "" || email == "" || contact == "" || message == "") {
      toast.error(`${toastTranslation?.requried ? toastTranslation?.requried : "All fields are required"}`);
      return;
    }
    dispatch(ContactUs(pageObject));
    toast.success(`${toastTranslation?.success ? toastTranslation?.success : "Email sent successfully."}`);
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const response = await fetch("http://localhost:3001/yinn/contact/get");
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  return (
    <div
      className="app"
      style={{ "background-color": "white", minHeight: "60vh" }}
    >
      <ToastContainer autoClose={20000} />
      <AppHeader fixed>
        <DefaultHeader />
      </AppHeader>
      <Row style={{ marginTop: "130px" }}>
        <Col>
          <h4 style={{ textAlign: "center" }}>
            {contectUsTranslation?.topHeading ? contectUsTranslation?.topHeading : contectUs?.topHeading}
          </h4>
          <p style={{ textAlign: "center" }}>
            {contectUsTranslation?.lineOne ? contectUsTranslation?.lineOne : contectUs?.lineOne}
            {/* A Community Marketplace Where Local Small Businesses In Innovation
            And Creation Meet With Shoppers To Promote , Have A Micro-Loan And
            Sell Their Creation. */}
          </p>
          <p style={{ textAlign: "center" }}>
            {contectUsTranslation?.lineTwo ? contectUsTranslation?.lineTwo : contectUs?.lineTwo}
            {/* Ready to start trading with us? That's great! Give us a call or send
            us an email and we will get back to you as soon as possible! */}
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop: "1%" }}>
        <Col xs="0" sm="0" md="3" lg="3" />
        <Col xs="12" sm="12" md="6" lg="6">
          <Row className="mb5">
            <Col md="3">
              <Label>
                {contectUsTranslation?.name ? contectUsTranslation?.name : contectUs?.name}
                {/* Enter Name */}
              </Label>
            </Col>
            <Col xs="12" md="9">
              <Input
                autoFocus
                type="text"
                name="name"
                placeholder={contectUsTranslation?.name ? contectUsTranslation?.name : contectUs?.name}
                // "Enter Name"
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row className="mb5">
            <Col md="3">
              <Label>
                {contectUsTranslation?.email ? contectUsTranslation?.email : contectUs?.email}
                {/* Enter Email */}
              </Label>
            </Col>
            <Col xs="12" md="9">
              <Input
                type="email"
                name="email"
                placeholder={contectUsTranslation?.email ? contectUsTranslation?.email : contectUs?.email}
                // "Enter Email"
                autoComplete="email"
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row className="mb5">
            <Col md="3">
              <Label>
                {contectUsTranslation?.number ? contectUsTranslation?.number : contectUs?.number}
                {/* Contact Number */}
              </Label>
            </Col>
            <Col xs="12" md="9">
              <Input
                type="tel"
                name="contact"
                placeholder={contectUsTranslation?.number ? contectUsTranslation?.number : contectUs?.number}
                // "Enter Contact Number"
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row className="mb5">
            <Col md="3">
              <Label>
                {contectUsTranslation?.message ? contectUsTranslation?.message : contectUs?.message}
                {/* Message */}
              </Label>
            </Col>
            <Col xs="12" md="9">
              <Input
                type="textarea"
                name="message"
                rows="9"
                placeholder={contectUsTranslation?.message ? contectUsTranslation?.message : contectUs?.message}
                // "Content..."
                onChange={handleInputChange}
              />
            </Col>
          </Row>
          <Row className="mb5">
            <Col md="3"></Col>
            <Col md="9" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <Button
                className="send-button"
                type="submit"
                size="md"
                onClick={sendEmail}
              >
                <i className="fa fa-paper-plane"></i>
                <b style={{ color: "black" }}>
                  {contectUsTranslation?.button
                    ? contectUsTranslation?.button
                    : contectUs?.button}
                  {/* Send  */}
                </b>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div>
        {data?.data?.map((item) => (
          <div
            key={item.id}
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <Card
              color="dark"
              outline
              style={{
                width: "18rem",
              }}
            >
              <CardBody>
                <CardTitle tag="h5" style={{ color: "#eeac1a" }}>
                  Email
                </CardTitle>
                <div style={{ paddingBottom: "5px" }}>Primary:</div>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  {item.email1}
                </CardSubtitle>
                {item.email2 ? (
                  <div style={{ paddingBottom: "5px" }}>Secondary:</div>
                ) : (
                  <></>
                )}
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  {item.email2}
                </CardSubtitle>
              </CardBody>
            </Card>
            {/* Contact Card */}
            <Card
              color="dark"
              outline
              style={{
                width: "18rem",
                gap: "20px",
              }}
            >
              <CardBody>
                <CardTitle tag="h5" style={{ color: "#eeac1a" }}>
                  Number
                </CardTitle>
                <div style={{ paddingBottom: "5px" }}>Primary:</div>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  {item.contact1}
                </CardSubtitle>
                {item.contact2 ? (
                  <div style={{ paddingBottom: "5px" }}>Secondary:</div>
                ) : (
                  <></>
                )}
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  {item.contact2}
                </CardSubtitle>
              </CardBody>
            </Card>
            {/* Location Card */}
            <Card
              color="dark"
              outline
              style={{
                width: "18rem",
              }}
            >
              <CardBody>
                <CardTitle tag="h5" style={{ color: "#eeac1a" }}>
                  Location
                </CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6">
                  {item.address}
                </CardSubtitle>
              </CardBody>
            </Card>
          </div>
        ))}
      </div>
      <div>
        <DefaultFooter />
      </div>
    </div>
  );
};

export default ContactYinn;
