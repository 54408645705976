import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  CardFooter,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { GoogleLogin } from "react-google-login";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CourseLogin.css";
import {
  SignIn,
  ForgotPassword,
  SocialSignIn,
} from "../../../redux/reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import SkyLight from "react-skylight";
import { useHistory, useLocation } from "react-router-dom";
import yinnLogo from "../../../assets/img/brand/logo.png";
import { toastTranslation } from "../../TranslationHelper/Translation";



const CourseLogin = () => {
  const { translationState } = useSelector((state) => state.user);

  const { userLanguage } = useSelector((state) => state.auth);

  const registerTranslation =
    translationState?.[0]?.translations?.register?.[userLanguage];

  const logInTranslation =
    translationState?.[0]?.translations?.logIn?.[userLanguage];

  const forgetPasswordModal = useRef(null);

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const redirect = searchParams.get("redirect");

  const [signInObject, setSignInObject] = useState({
    email: "",
    password: "",
  });

  const [emailError, setEmailError] = useState(false);

  const [forgotemailError, setForgotEmailError] = useState(false);

  const [passwordError, setPasswordError] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const event = location.state?.event;
  


  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user?.user?.id && event) {

      history.push({
        pathname: "/course-checkout",
        state: { user_id: user.user.id, event },
      });
    }
  }, [user, history, event]);

  useEffect(() => {
    if (redirect === "/my-cart") {
      toast.error(
        toastTranslation["you_must_login_to_continue"][userLanguage]
      );
    }
  }, [redirect]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setSignInObject({
      ...signInObject,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    return isValid;
  };

  const clearErrors = () => {
    setForgotEmailError(false);
    setEmailError(false);
    setPasswordError(false);
  };

  const signIn = () => {
    clearErrors();
    if (signInObject.email === "" || !validateEmail(signInObject.email)) {
      setEmailError(true);
    } else if (signInObject.password === "") {
      setPasswordError(true);
    } else {
      dispatch(SignIn(signInObject)).then((response) => {
        if (response?.payload?.user?.id) {
          const user_id = response.payload.user.id;
          if (user_id && event) {
            history.push({
              pathname: "/course-checkout",
              state: { user_id, event },
            });
          } else {
            history.push("/personal-finance");
          }
        } else {
          toast.error("");
        }
      });
    }
  };

  const googleSignIn = (response) => {
    clearErrors();
    const { profileObj, tokenObj, details } = response;
    if (tokenObj?.id_token) {
      const { email, familyName, givenName } = profileObj;
      const data = {
        firstname: familyName,
        lastname: givenName,
        email,
      };
      dispatch(SocialSignIn(data));
    } else {
      if (details) toast.info(details);
      else toast.error("Some error occurred during Google SignIn");
    }
  };

  const forgot = () => {
    forgetPasswordModal.current.show();
  };

  const sendmailForgot = () => {
    clearErrors();
    if (signInObject.email === "" || !validateEmail(signInObject.email)) {
      setForgotEmailError(true);
    } else {
      dispatch(ForgotPassword(signInObject));
      forgetPasswordModal.current.hide();
    }
  };

  const myBigGreenDialog = {
    width: "27%",
    minHeight: "271px",
    top: "50%",
    left: "62%",
  };


  
  return (
    <div className="login-main-wrapper">

      <div className="app flex-row align-items-center login-background ">
        <ToastContainer autoClose={20000} />
        {/* <SkyLight
          dialogStyles={myBigGreenDialog}
          ref={forgetPasswordModal}
          title={
            <p style={{ display: "flex", justifyContent: "center" }}>
              <img src={yinnLogo} width="100" height="50" />
            </p>
          }
        >
          <div className="col-12 row" style={{ height: "29px" }}>
            <b>
              {registerTranslation?.reSetPassword
                ? registerTranslation?.reSetPassword
                : "Reset your password"}
            </b>
          </div>
          <div className="col-12 row" style={{ height: "29px" }}>
            {registerTranslation?.emailHeading
              ? registerTranslation?.emailHeading
              : "Enter the email address"}
          </div>
          <InputGroup className="row col-11 mb-3">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="icon-envelope-letter"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              name="email"
              value={signInObject.email}
              type="text"
              onChange={handleInputChange}
              placeholder={
                registerTranslation?.emailHeading
                  ? registerTranslation?.emailHeading
                  : "Enter the email address"
              }
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <div className="error-text" hidden={!forgotemailError}>
              {registerTranslation?.emailHeading
                ? registerTranslation?.emailHeading
                : "Please enter valid email address"}
            </div>
          </InputGroup>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={sendmailForgot} size="md" color="warning">
              {" "}
              {registerTranslation?.continueButton
                ? registerTranslation?.continueButton
                : "Continue"}{" "}
            </Button>
          </Row>
        </SkyLight> */}

        <Container>
          <Row>
            <Col xs="0" sm="0" md="6" lg="6">
              <div className="hide-on-mobile welcome-back">
                {logInTranslation?.headingOne
                  ? logInTranslation?.headingOne
                  : "Welcome Back,"}
              </div>
              <div className="hide-on-mobile open-the-door">
                {logInTranslation?.headingTwo
                  ? logInTranslation?.headingTwo
                  : "Open the door,"}
              </div>
              <div className="hide-on-mobile you-have-the-key">
                {logInTranslation?.headingThree
                  ? logInTranslation?.headingThree
                  : "you already have the key!"}
              </div>
            </Col>
            <Col xs="12" sm="12" md="6" lg="6">
              <Card style={{ width: "100%", padding: "0" }}>

                <CardBody style={{ height: "460px" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "30px",
                    }}
                  >
                    <div className="title-login">
                      {logInTranslation?.signInButton
                        ? logInTranslation?.signInButton
                        : "Sign In"}
                    </div>
                    <div className="login-tagline">
                      {logInTranslation?.paraOne
                        ? logInTranslation?.paraOne
                        : "Let's start the journey. Sign in here..."}
                    </div>
                    <InputGroup className="mb-1" style={{ marginTop: "80px" }}>
                      <Input
                        type="text"
                        onChange={handleInputChange}
                        value={signInObject.email}
                        name="email"
                        placeholder={
                          registerTranslation?.emailPlaceHolder
                            ? registerTranslation?.emailPlaceHolder
                            : "Email"
                        }
                        className="login-input"
                      />
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <div className="error-text" hidden={!emailError}>
                        {registerTranslation?.emailPlaceHolder
                          ? registerTranslation?.emailPlaceHolder
                          : "please enter valid email address."}
                      </div>
                    </InputGroup>
                    <InputGroup className="mb-1">
                      <Input
                        type="password"
                        onChange={handleInputChange}
                        value={signInObject.password}
                        name="password"
                        className="login-input"
                        placeholder={
                          registerTranslation?.passwordPlaceHolder
                            ? registerTranslation?.passwordPlaceHolder
                            : "Password"
                        }
                      />
                    </InputGroup>
                    <InputGroup>
                      <div className="error-text" hidden={!passwordError}>
                        {registerTranslation?.passwordPlaceHolder
                          ? registerTranslation?.passwordPlaceHolder
                          : "Please enter valid password."}
                      </div>
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <Button color="link" onClick={forgot} className="px-0">
                        {logInTranslation?.forgetPassword
                          ? logInTranslation?.forgetPassword
                          : "Forgot password?"}
                      </Button>
                    </InputGroup>
                    <InputGroup
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        onClick={() => signIn()}
                        className="sign-in-button"
                        block
                      >
                        <b>
                          {logInTranslation?.signInButton
                            ? logInTranslation?.signInButton
                            : "Sign In"}
                        </b>
                      </Button>





                    </InputGroup>

                    <p
  onClick={() =>
    history.push({
      pathname: "/register",
      state: { event }, // Pass only event if needed, or remove state if not required
    })
  }
  style={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}
  className=""
>
  <b>Sign Up</b>
</p>


                  </div>
                </CardBody>
                <CardFooter>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {logInTranslation?.signInWith
                      ? logInTranslation?.signInWith
                      : "or Sign in using"}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <GoogleLogin
                      clientId="367945858566-41odj8kbjqvsbhemsua8jc83emvs99pt.apps.googleusercontent.com"
                      buttonText="Login"
                      render={(renderProps) => (
                        <Button
                          className="google_btn"
                          disabled={renderProps.disabled}
                          onClick={renderProps.onClick}
                        >
                          Google
                        </Button>
                      )}
                      onSuccess={googleSignIn}
                      onFailure={googleSignIn}
                    />
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CourseLogin;
