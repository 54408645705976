import React from 'react';
import './BlogCard.css';

function BlogCard({ title, description, image, date, author, tag }) {
  return (
    <div className="blog-card">
      <div
        className="blog-card-thumbnail"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
      <div className="blog-card-content">
        <p className="blog-card-date">{date}</p>
        <h3 className="blog-card-title">{title}</h3>
        <p className="blog-card-author">{author}</p>
        <p className="blog-card-description">{description}</p>
        <a href="#" className="blog-card-link">{tag}</a>
      </div>
    </div>
  );
}

export default BlogCard;
