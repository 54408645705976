import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { TranslationContent } from "../../../redux/reducers/admin"; // Adjust path if necessary
import { getAllWebContent } from "../../../redux/reducers/user"; // Adjust path if necessary
import "./TranslationField.css";
import { useHistory } from "react-router-dom";


const TestimonialsField = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

  const { allWebContent } = useSelector((state) => state?.user);
  const testimonialData = allWebContent?.[0]?.testimonials;

  const [diable, setDiable] = useState(true);

  const [formData, setFormData] = useState({
    page: "testimonials",
    headerText: "",
    headerHeading: "",
    ClientsReviewHeading: "",
    Clientheading1: "",
    Clienttext1: "",
    Client1: "",
    Clientheading2: "",
    Clienttext2: "",
    Client2: "",
    Clientheading3: "",
    Clienttext3: "",
    Client3: "",
    Clientheading4: "",
    Clienttext4: "",
    Client4: "",
    Clientheading5: "",
    Clienttext5: "",
    Client5: "",
    Clientheading6: "",
    Clienttext6: "",
    Client6: "",
    name1: "",
    name2: "",
    name3: "",
    name4: "",
    name5: "",
    name6: "",
    availableHeading: "",
    availableText1: "",
    availableText2: "",
    address: "",
    copyrights: "",
  });

  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    setDiable(false);

    setFormData((prevData) => ({
      ...prevData,
      [section]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(TranslationContent(formData));
    console.log("🚀 ~ TestimonialsField Submit:", formData);
    setDiable(true);
  };

  useEffect(() => {
    if (testimonialData) {
      setFormData((prevState) => ({
        ...prevState,
        ...testimonialData,
      }));
    }
  }, [testimonialData]);

  return (
    <div className="container">
      <ToastContainer autoClose={20000} />
      <div className="tf-top mx-5 mt-2 mb-2">
        
          <button className="back-button" onClick={history.goBack}>
            &lt;</button>

            <h1 className="mt-3" s  style={{
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
  }}>
  Testimonials Page
</h1>

      </div>
      <form onSubmit={handleSubmit} className="mx-5">
        <h3>Header</h3>
        <div className="form-group">
          <label>Header Heading:</label>
          <input
            type="text"
            name="headerHeading"
            value={formData.headerHeading}
            onChange={(e) => handleInputChange(e, "headerHeading")}
            required
          />
        </div>
        <div className="form-group">
          <label>Header Text:</label>
          <textarea
            name="headerText"
            value={formData.headerText}
            onChange={(e) => handleInputChange(e, "headerText")}
            required
          />
        </div>

        <h3>Client Reviews</h3>
        {[...Array(6)].map((_, i) => (
          <div className="form-group" key={`client-${i}`}>
            <label>Client {i + 1} Heading:</label>
            <input
              type="text"
              name={`Clientheading${i + 1}`}
              value={formData[`Clientheading${i + 1}`]}
              onChange={(e) => handleInputChange(e, `Clientheading${i + 1}`)}
              required
            />
            <label>Client {i + 1} Text:</label>
            <textarea
              name={`Clienttext${i + 1}`}
              value={formData[`Clienttext${i + 1}`]}
              onChange={(e) => handleInputChange(e, `Clienttext${i + 1}`)}
              required
            />
            <label>Client {i + 1} Name:</label>
            <input
              type="text"
              name={`Client${i + 1}`}
              value={formData[`Client${i + 1}`]}
              onChange={(e) => handleInputChange(e, `Client${i + 1}`)}
              required
            />
          </div>
        ))}

        <h3>Process Information</h3>
        {[...Array(6)].map((_, i) => (
          <div className="form-group" key={`process-name-${i}`}>
            <label>Process Name {i + 1}:</label>
            <input
              type="text"
              name={`name${i + 1}`}
              value={formData[`name${i + 1}`]}
              onChange={(e) => handleInputChange(e, `name${i + 1}`)}
              required
            />
          </div>
        ))}

        <h3>Available Information</h3>
        <div className="form-group">
          <label>Available Heading:</label>
          <input
            type="text"
            name="availableHeading"
            value={formData.availableHeading}
            onChange={(e) => handleInputChange(e, "availableHeading")}
          />
        </div>
        <div className="form-group">
          <label>Available Text 1:</label>
          <textarea
            name="availableText1"
            value={formData.availableText1}
            onChange={(e) => handleInputChange(e, "availableText1")}
          />
        </div>
        <div className="form-group">
          <label>Available Text 2:</label>
          <textarea
            name="availableText2"
            value={formData.availableText2}
            onChange={(e) => handleInputChange(e, "availableText2")}
          />
        </div>

        <h3>Address and Copyrights</h3>
        <div className="form-group">
          <label>Address:</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={(e) => handleInputChange(e, "address")}
          />
        </div>
        <div className="form-group">
          <label>Copyright Text:</label>
          <textarea
            name="copyrights"
            value={formData.copyrights}
            onChange={(e) => handleInputChange(e, "copyrights")}
          />
        </div>

        <div className="form-group">
          <button type="submit" disabled={diable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default TestimonialsField;
