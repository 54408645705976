
import React from 'react';
import './QA.css';
const MT = ({ content }) => {

  return (
    <div className="aqCard">
      <h2 className="aq-heading">Common Misconceptions</h2>

      <div className="aqCard-container">
        <h3 className="aqCard-heading">1. {content.misconception1}</h3>
        <p className="aqCard-text">{content.truth1}</p>
      </div>

      <div className="aqCard-container">
        <h3 className="aqCard-heading">2. {content.misconception2}</h3>
        <p className="aqCard-text">{content.truth2}</p>
      </div>

      <div className="aqCard-container">
        <h3 className="aqCard-heading">3. {content.misconception3}</h3>
        <p className="aqCard-text">{content.truth3}</p>
      </div>

    </div>
  );
};

export default MT;

