import React from 'react';
import './Blog.css';
import asian from "../../../assets/img/personalFinance/asian-couple.jpg";
import image from "../../../assets/img/personalFinance/couple-in-cafe.jpg";
import financeimage from "../../../assets/img/personalFinance/mature-couple.jpg";
import pencil from "../../../assets/img/personalFinance/pencil.jpg";
import shakinghands from "../../../assets/img/personalFinance/shaking-hands.jpg";

const Blog = ({ content }) => {

  const blogPosts = [
    { tag:content.blog1Tag, img: financeimage, heading: content.blog1Heading, text: content.blog1Text },
    { tag:content.blog2Tag, img: image, heading: content.blog2Heading, text: content.blog2Text },
    { tag:content.blog3Tag, img: asian, heading: content.blog3Heading, text: content.blog3Text },
    { tag:content.blog4Tag, img: pencil, heading: content.blog4Heading, text: content.blog4Text },
    { tag:content.blog5Tag, img: shakinghands, heading: content.blog5Heading, text: content.blog5Text },
    { tag:content.blog6Tag, img: asian, heading: content.blog6Heading, text: content.blog6Text }
  ];

  return (
    <div className="blog-layout">
      {blogPosts.map((blog, index) => (
        <article key={index} className="blog-item">
          <div className="image-container">
            <img src={blog.img} alt={`Blog post ${index + 1}`} className="post-image" />
          </div>
          <div className="post-content">
            <div className="post-tag">{blog.tag}</div>
            <h2 className="post-heading">{blog.heading}</h2>
            <p className="post-text">{blog.text}</p>
            <button className="read-more-btn">{content.blogButton}</button>
          </div>
        </article>
      ))}
    </div>
  );
};

export default Blog;
