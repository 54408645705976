import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { TranslationContent } from "../../../redux/reducers/admin"; // Adjust path if necessary
import { getAllWebContent } from "../../../redux/reducers/user"; // Adjust path if necessary
import "./TranslationField.css";
import { useHistory } from "react-router-dom";


const PersonalFinanceContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

  const { allWebContent } = useSelector((state) => state?.user);
  const financeData = allWebContent?.[0]?.personalFinance;

  const [diable, setDiable] = useState(true);

  const [formData, setFormData] = useState({
    page: "personalFinance",
    isChange: false,
    headerHeading: "",
    headerText: "",
    blog1Heading: "",
    blog1Text: "",
    blog2Heading: "",
    blog2Text: "",
    blog3Heading: "",
    blog3Text: "",
    blog4Heading: "",
    blog4Text: "",
    blog5Heading: "",
    blog5Text: "",
    blog6Heading: "",
    blog6Text: "",
    blog7Heading: "",
    blog7Text: "",
    step1Heading: "",
    step1Text: "",
    step2Heading: "",
    step2Text: "",
    step3Heading: "",
    step3Text: "",
    step4Heading: "",
    step4Text: "",
    step5Heading: "",
    step5Text: "",
    name1: "", // Process 1 name
    name2: "", // Process 2 name
    name3: "", // Process 3 name
    name4: "", // Process 4 name
    name5: "", // Process 5 name
    name6: "", // Process 6 name
    address: "", // Single address field under copyrights
    copyrights: "",
  });

  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    setDiable(false);

    setFormData((prevData) => ({
      ...prevData,
      [section]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(TranslationContent(formData));
    console.log("🚀 ~ PersonalFinanceContent Submit:", formData);
    setDiable(true);
  };

  useEffect(() => {
    if (financeData) {
      setFormData(prevState => ({
        ...prevState,
        headerHeading: financeData?.headerHeading || "",
        headerText: financeData?.headerText || "",
        blog1Heading: financeData?.blog1Heading || "",
        blog1Text: financeData?.blog1Text || "",
        blog2Heading: financeData?.blog2Heading || "",
        blog2Text: financeData?.blog2Text || "",
        blog3Heading: financeData?.blog3Heading || "",
        blog3Text: financeData?.blog3Text || "",
        blog4Heading: financeData?.blog4Heading || "",
        blog4Text: financeData?.blog4Text || "",
        blog5Heading: financeData?.blog5Heading || "",
        blog5Text: financeData?.blog5Text || "",
        blog6Heading: financeData?.blog6Heading || "",
        blog6Text: financeData?.blog6Text || "",
        blog7Heading: financeData?.blog7Heading || "",
        blog7Text: financeData?.blog7Text || "",
        step1Heading: financeData?.step1Heading || "",
        step1Text: financeData?.step1Text || "",
        step2Heading: financeData?.step2Heading || "",
        step2Text: financeData?.step2Text || "",
        step3Heading: financeData?.step3Heading || "",
        step3Text: financeData?.step3Text || "",
        step4Heading: financeData?.step4Heading || "",
        step4Text: financeData?.step4Text || "",
        step5Heading: financeData?.step5Heading || "",
        step5Text: financeData?.step5Text || "",
        name1: financeData?.name1 || "", // Process 1 name
        name2: financeData?.name2 || "", // Process 2 name
        name3: financeData?.name3 || "", // Process 3 name
        name4: financeData?.name4 || "", // Process 4 name
        name5: financeData?.name5 || "", // Process 5 name
        name6: financeData?.name6 || "", // Process 6 name
        address: financeData?.address || financeData?.processes?.[0]?.address || "", // Address inside processes
        copyrights: financeData?.copyrights || "",
        isChange: financeData?.isChange || false,
      }));
    }
  }, [financeData]);

  return (
    <div className="container">
      <ToastContainer autoClose={20000} />
      <div className="tf-top mx-5 mt-2 mb-2">
        
          <button className="back-button" onClick={history.goBack}>
            &lt;</button>

            <h1 className="mt-3" s  style={{
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
  }}>
  Personal Finance Page
</h1>

      </div>
      <form onSubmit={handleSubmit} className="mx-5">
        <h3>Header</h3>
        <div className="form-group">
          <label>Header Heading:</label>
          <input
            type="text"
            name="headerHeading"
            value={formData.headerHeading}
            onChange={(e) => handleInputChange(e, "headerHeading")}
            required
          />
        </div>
        <div className="form-group">
          <label>Header Text:</label>
          <textarea
            name="headerText"
            value={formData.headerText}
            onChange={(e) => handleInputChange(e, "headerText")}
            required
          />
        </div>

        <h3>Blog Entries</h3>
        {[...Array(7)].map((_, i) => (
          <div className="form-group" key={`blog-${i}`}>
            <label>Blog {i + 1} Heading:</label>
            <input
              type="text"
              name={`blog${i + 1}Heading`}
              value={formData[`blog${i + 1}Heading`]}
              onChange={(e) => handleInputChange(e, `blog${i + 1}Heading`)}
              required
            />
            <label>Blog {i + 1} Text:</label>
            <textarea
              name={`blog${i + 1}Text`}
              value={formData[`blog${i + 1}Text`]}
              onChange={(e) => handleInputChange(e, `blog${i + 1}Text`)}
              required
            />
          </div>
        ))}

        <h3>Steps</h3>
        {[...Array(5)].map((_, i) => (
          <div className="form-group" key={`step-${i}`}>
            <label>Step {i + 1} Heading:</label>
            <input
              type="text"
              name={`step${i + 1}Heading`}
              value={formData[`step${i + 1}Heading`]}
              onChange={(e) => handleInputChange(e, `step${i + 1}Heading`)}
              required
            />
            <label>Step {i + 1} Text:</label>
            <textarea
              name={`step${i + 1}Text`}
              value={formData[`step${i + 1}Text`]}
              onChange={(e) => handleInputChange(e, `step${i + 1}Text`)}
              required
            />
          </div>
        ))}

        <h3>Process Information</h3>
        {[...Array(6)].map((_, i) => (
          <div className="form-group" key={`process-${i}`}>
            <label>Process {i + 1} Name:</label>
            <input
              type="text"
              name={`name${i + 1}`}
              value={formData[`name${i + 1}`]}
              onChange={(e) => handleInputChange(e, `name${i + 1}`)}
            />
          </div>
        ))}

        <h3>Copyrights and Address</h3>
        <div className="form-group">
          <label>Address:</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={(e) => handleInputChange(e, "address")}
          />
        </div>
        <div className="form-group">
          <label>Copyright Text:</label>
          <textarea
            name="copyrights"
            value={formData.copyrights}
            onChange={(e) => handleInputChange(e, "copyrights")}
          />
        </div>

        <div className="form-group">
          <button type="submit" disabled={diable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default PersonalFinanceContent;
