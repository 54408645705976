import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Config from "../../../config/aws";
import {
  getPresigedUrl,
  uploadToS3ViaSignedUrl,
} from "../../../helpers/s3Utils";
import {
  MediaTranslationContent,
  deleteMediaContent,
} from "../../../redux/reducers/admin";
import { getAllWebContent } from "../../../redux/reducers/user";
import { Plus, X, Trash2, Upload } from "lucide-react";
import "react-toastify/dist/ReactToastify.css";

const style = `
  .media-content {
    padding: 2rem;
    max-width: 100vw;
    margin: 0 auto;
    background: linear-gradient(to bottom right, #ffffff, #f8f9fa);
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  }

  .media-controls12 {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
  }

  .media-button12 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background: linear-gradient(135deg, #3b82f6, #2563eb);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(59, 130, 246, 0.2);
  }

  .media-button12:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(59, 130, 246, 0.3);
  }

  .media-header h1 {
    font-size: 2rem;
    font-weight: 700;
    color: #1f2937;
    margin: 2rem 0;
    border-bottom: 3px solid #3b82f6;
    padding-bottom: 0.5rem;
    display: inline-block;
  }

  .media-table {
    width: 100%;
    border-spacing: 0;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    justify
  }

.media-table th, .media-table td {
    padding: 1.25rem 1rem;
    font-weight: 600;
    color: #374151;
    font-size: 0.875rem;
    text-align: center;
    justify-self: center; /* centers content within the cell */
}


  .media-table th {
    background: #f3f4f6;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .media-table td {
    border-bottom: 1px solid #e5e7eb;
    color: #4b5563;
  }

    .media-table tr:hover {
    background-color: #f9fafb;
  }

.media-table tr {
    text-align: center;
    vertical-align: middle;
}


  .media-thumbnail {
    width: 100%;
    height: 20vh;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }

  .media-thumbnail:hover {
    transform: scale(1.05);
  }

.delete-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  padding: 0.5rem 1rem;
  background: linear-gradient(135deg, #ef4444, #dc2626);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.875rem;
  opacity: 0.9;
  
  margin-left: auto;
  margin-right: auto;
  display: block; /* or display: flex if within a flex container */
}


  .delete-button:hover {
    opacity: 1;
    transform: translateY(-1px);
  }

  .media-form {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    border: 1px solid #e5e7eb;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  .media-form h2 {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: #1f2937;
    border-bottom: 2px solid #3b82f6;
    padding-bottom: 0.5rem;
    display: inline-block;
  }

  .form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
  }

  .form-grid div {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .form-grid label {
    font-weight: 600;
    color: #374151;
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .form-grid input[type="text"] {
    padding: 0.875rem;
    border: 2px solid #e5e7eb;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.3s ease;
  }

  .form-grid input[type="text"]:focus {
    border-color: #3b82f6;
    outline: none;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }

  .file-input-container {
    position: relative;
  }

  .file-input-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.875rem;
    background: #f3f4f6;
    border: 2px dashed #d1d5db;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .file-input-label:hover {
    border-color: #3b82f6;
    background: #f0f9ff;
  }

  .file-input {
    position: absolute;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
  }

  .submit-button {
    padding: 0.875rem 2rem;
    background: linear-gradient(135deg, #3b82f6, #2563eb);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.3s ease;
    min-width: 120px;
  }

  .submit-button:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(59, 130, 246, 0.3);
  }

  .submit-button:disabled {
    background: #d1d5db;
    cursor: not-allowed;
  }

  .loading-text {
    color: #6b7280;
    font-size: 0.875rem;
    font-style: italic;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .loading-text::before {
    content: "";
    width: 16px;
    height: 16px;
    border: 2px solid #6b7280;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to { transform: rotate(360deg); }
  }

  @media (max-width: 768px) {
    .media-content {
      padding: 1rem;
    }
    
    .media-table th, .media-table td {
      padding: 0.75rem;
      font-size: 0.75rem;
    }
    
    .media-thumbnail {
      width: 100px;
      height: 60px;
    }

    .media-header h1 {
      font-size: 1.5rem;
    }

    .form-grid {
      grid-template-columns: 1fr;
    }
  }
`;

const MediaContent = () => {
  const dispatch = useDispatch();
  const [imageLoading, setImageLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [disable, setDisable] = useState(true);
  const [imageName, setImageName] = useState("");
  const [videoName, setVideoName] = useState("");

  const [formData, setFormData] = useState({
    page: "media",
    thumbnailUrl: "",
    title: "",
    dateUploaded: "",
    videoUrl: "",
    description: "",
  });

  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

  const { allWebContent } = useSelector((state) => state.user);
  const MediaData = allWebContent?.[0]?.media || [];

  const uploadMedia = async (file, fileType) => {
    const params = {
      Body: file,
      Bucket: Config.bucketName,
      Key: file.name,
    };

    if (fileType === "thumbnailUrl") {
      setImageLoading(true);
      setImageName(file.name);
    } else if (fileType === "videoUrl") {
      setVideoLoading(true);
      setVideoName(file.name);
    }

    try {
      const signedUrlResp = await getPresigedUrl({
        fileName: params.Key,
        bucketName: params.Bucket,
        contentType: file.type,
      });

      await uploadToS3ViaSignedUrl({
        signedUrl: signedUrlResp.signedUrl,
        contentType: file.type,
        body: params.Body,
      });

      const fileUrl = `${Config.digitalOceanSpaces}/${file.name}`;
      setFormData((prevData) => ({
        ...prevData,
        [fileType]: fileUrl,
      }));
      toast.success("File uploaded successfully!");
    } catch (error) {
      toast.error("Error in uploading file! Please try again");
    } finally {
      if (fileType === "thumbnailUrl") {
        setImageLoading(false);
      } else if (fileType === "videoUrl") {
        setVideoLoading(false);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDisable(false);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    if (file) {
      uploadMedia(file, fileType);
    }
  };

  const getCurrentFormattedDate = () => {
    const today = new Date();
    return today.toLocaleDateString("en-US");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisable(true);

    const updatedFormData = {
      ...formData,
      dateUploaded: getCurrentFormattedDate(),
    };

    dispatch(MediaTranslationContent(updatedFormData))
      .then(() => {
        toast.success("Media content added successfully!");
        setShowForm(false);
        resetForm();
        dispatch(getAllWebContent());
      })
      .catch(() => {
        toast.error("Error adding media content!");
      });
  };

  const resetForm = () => {
    setFormData({
      page: "media",
      thumbnailUrl: "",
      title: "",
      dateUploaded: "",
      videoUrl: "",
      description: "",
    });
    setImageName("");
    setVideoName("");
    setDisable(true);
  };

  const handleDelete = (index) => {
    if (window.confirm("Are you sure you want to delete this Media?")) {
      dispatch(deleteMediaContent({ index }))
        .then(() => {
          toast.success("Media deleted successfully!");
          dispatch(getAllWebContent());
        })
        .catch((error) => {
          toast.error("Error deleting media");
          console.error("Delete error:", error);
        });
    }
  };

  return (
    <>
      <style>{style}</style>
      <div className="media-content">
        <div className="media-controls12">
          <button
            onClick={() => setShowForm(!showForm)}
            className="media-button12"
          >
            {showForm ? <X size={20} /> : <Plus size={20} />}
            {showForm ? "Close Form" : "Add New Media"}
          </button>
        </div>

        {showForm && (
          <div className="media-form">
            <h2>Add New Media</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-grid">
                <div>
                  <label>Title</label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    placeholder="Enter media title"
                    required
                  />
                </div>

                <div className="file-input-container">
                  <label>Thumbnail Image</label>
                  <label className="file-input-label">
                    <Upload size={20} />
                    Choose Thumbnail
                    <input
                      className="file-input"
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, "thumbnailUrl")}
                    />
                  </label>
                  {imageLoading && (
                    <p className="loading-text">Uploading image...</p>
                  )}
                  {imageName && <p>File Name: {imageName}</p>}
                </div>
                <div className="file-input-container">
                  <label>Video File</label>
                  <label className="file-input-label">
                    <Upload size={20} />
                    Choose Video
                    <input
                      className="file-input"
                      type="file"
                      accept="video/*"
                      onChange={(e) => handleFileChange(e, "videoUrl")}
                    />
                  </label>
                  {videoLoading && (
                    <p className="loading-text">Uploading video...</p>
                  )}
                  {videoName && <p>File Name: {videoName}</p>}
                </div>

                <div style={{ gridColumn: "1 / -1" }}>
                  <label>Description</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Enter media description"
                    required
                    rows="3"
                    style={{
                      padding: "0.875rem",
                      borderRadius: "8px",
                      border: "2px solid #e5e7eb",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
              <div className="form-buttons">
                <button
                  type="submit"
                  className="submit-button"
                  disabled={disable}
                  style={{
                    backgroundColor: disable ? "gray" : "blue", // Change the color based on the disable state
                    color: "white",
                    border: "none",
                    padding: "10px 20px",
                    cursor: disable ? "not-allowed" : "pointer",
                  }}
                >
                  {disable ? "Uploading..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        )}

        <div className="media-header">
          <h1>Media Library</h1>
        </div>

        <div className="media-table-container" style={{ overflowX: 'scroll' }}>
          <table className="media-table">
            <thead>
              <tr>
                <th>Thumbnail</th>
                <th>Title</th>
                <th>Date Uploaded</th>
                <th>Description</th>
                <th>Video</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {MediaData.map((content, index) => (
                <tr key={index}>
                  <td>
                    <div
                      className="media-thumbnail"
                      style={{
                        backgroundImage: `url(${content.thumbnailUrl})`,
                      }}
                    ></div>
                  </td>
                  <td>{content.title}</td>
                  <td>{content.dateUploaded}</td>
                  <td>{content.description}</td>
                  <td>{content.videoUrl ? (
                        <a
                          href={content.videoUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Video
                        </a>
                      ) : (
                        "No video available"
                      )} </td>
                  

                  <td>
                    <button
                      className="delete-button"
                      onClick={() => handleDelete(index)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default MediaContent;
