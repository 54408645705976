import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { TranslationContent } from "../../../redux/reducers/admin"; // Adjust path if necessary
import { getAllWebContent } from "../../../redux/reducers/user"; // Adjust path if necessary
import "./TranslationField.css";
import { useHistory } from "react-router-dom";


const RebuildCreditContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

  const { allWebContent } = useSelector((state) => state?.user);
  const RB_Data = allWebContent?.[0]?.rebuildcreditscores;

  const [disable, setDisable] = useState(true);

  const [formData, setFormData] = useState({
    page: "rebuildcreditscores",
    isChange: false,
    headerHeading: "",
    headerText: "",
    blog1Heading: "",
    blog1Text: "",
    blog1Tag: "",
    blog2Heading: "",
    blog2Text: "",
    blog2Tag: "",
    blog3Heading: "",
    blog3Text: "",
    blog3Tag: "",
    blog4Heading: "",
    blog4Text: "",
    blog4Tag: "",
    blog5Heading: "",
    blog5Text: "",
    blog5Tag: "",
    blog6Heading: "",
    blog6Text: "",
    blog6Tag: "",
    blogButton: "",  // Blog button text field
    stepsTitle:"",  // Steps title
    stepsSubTitle: "", // Steps subtitle
    step1Heading: "",
    step1Text: "",
    step2Heading: "",
    step2Text: "",
    step3Heading: "",
    step3Text: "",
    step4Heading: "",
    step4Text: "",
    step5Heading: "",
    step5Text: "",
    step6Heading: "",
    step6Text: "",
    stepsButton: "",  // Steps button text field
    address: "",
    copyrights: "",
  });

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setDisable(false);

    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(TranslationContent(formData));
    console.log("🚀 ~ Rebuild credit scores Content Submit:", formData);
    setDisable(true);
  };

  useEffect(() => {
    if (RB_Data) {
      setFormData({
        page: "rebuildcreditscores",
        isChange: false,
        headerHeading: RB_Data.headerHeading || "",
        headerText: RB_Data.headerText || "",
        blog1Heading: RB_Data.blog1Heading || "",
        blog1Text: RB_Data.blog1Text || "",
        blog1Tag: RB_Data.blog1Tag || "",
        blog2Heading: RB_Data.blog2Heading || "",
        blog2Text: RB_Data.blog2Text || "",
        blog2Tag: RB_Data.blog2Tag || "",
        blog3Heading: RB_Data.blog3Heading || "",
        blog3Text: RB_Data.blog3Text || "",
        blog3Tag: RB_Data.blog3Tag || "",
        blog4Heading: RB_Data.blog4Heading || "",
        blog4Text: RB_Data.blog4Text || "",
        blog4Tag: RB_Data.blog4Tag || "",
        blog5Heading: RB_Data.blog5Heading || "",
        blog5Text: RB_Data.blog5Text || "",
        blog5Tag: RB_Data.blog5Tag || "",
        blog6Heading: RB_Data.blog6Heading || "",
        blog6Text: RB_Data.blog6Text || "",
        blog6Tag: RB_Data.blog6Tag || "",
        blogButton: RB_Data.blogButton || "",  // Populate blogButton
        stepsTitle: RB_Data.stepsTitle || "",  // Populate stepsTitle
        stepsSubTitle: RB_Data.stepsSubTitle || "",  // Populate stepsSubTitle
        step1Heading: RB_Data.step1Heading || "",
        step1Text: RB_Data.step1Text || "",
        step2Heading: RB_Data.step2Heading || "",
        step2Text: RB_Data.step2Text || "",
        step3Heading: RB_Data.step3Heading || "",
        step3Text: RB_Data.step3Text || "",
        step4Heading: RB_Data.step4Heading || "",
        step4Text: RB_Data.step4Text || "",
        step5Heading: RB_Data.step5Heading || "",
        step5Text: RB_Data.step5Text || "",
        step6Heading: RB_Data.step5Heading || "",
        step6Text: RB_Data.step5Text || "",
        stepsButton: RB_Data.stepsButton || "",  // Populate stepsButton
        address: RB_Data.address || "",
        copyrights: RB_Data.copyrights || "",
      });
    }
  }, [RB_Data]);

  return (
    <div className="container">
      <ToastContainer autoClose={20000} />
      <div className="tf-top mx-5 mt-2 mb-2">
        
          <button className="back-button" onClick={history.goBack}>
            &lt;</button>

            <h1 className="mt-3" s  style={{
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
  }}>
  Rebuild Credit Page
</h1>

      </div>
      <form onSubmit={handleSubmit} className="mx-5">
        <h3>Header</h3>
        <div className="form-group">
          <label>Header Heading:</label>
          <input
            type="text"
            name="headerHeading"
            value={formData.headerHeading}
            onChange={(e) => handleInputChange(e, "headerHeading")}
            required
          />
        </div>
        <div className="form-group">
          <label>Header Text:</label>
          <textarea
            name="headerText"
            value={formData.headerText}
            onChange={(e) => handleInputChange(e, "headerText")}
            required
          />
        </div>

        <h3>Blog Entries</h3>
        {[...Array(6)].map((_, i) => (
          <div className="form-group" key={`blog-${i}`}>
            <label>Blog {i + 1} Heading:</label>
            <input
              type="text"
              name={`blog${i + 1}Heading`}
              value={formData[`blog${i + 1}Heading`]}
              onChange={(e) => handleInputChange(e, `blog${i + 1}Heading`)}
              required
            />
            <label>Blog {i + 1} Text:</label>
            <textarea
              name={`blog${i + 1}Text`}
              value={formData[`blog${i + 1}Text`]}
              onChange={(e) => handleInputChange(e, `blog${i + 1}Text`)}
              required
            />
            <label>Blog {i + 1} Tag:</label>
            <input
              type="text"
              name={`blog${i + 1}Tag`}
              value={formData[`blog${i + 1}Tag`]}
              onChange={(e) => handleInputChange(e, `blog${i + 1}Tag`)}
            />
          </div>
        ))}

        <div className="form-group">
          <label>Blog Button Text:</label>
          <input
            type="text"
            name="blogButton"
            value={formData.blogButton}
            onChange={(e) => handleInputChange(e, "blogButton")}
            required
          />
        </div>

        <h3>Steps</h3>
        <div className="form-group">
          <label>Steps Title:</label>
          <input
            type="text"
            name="stepsTitle"
            value={formData.stepsTitle}
            onChange={(e) => handleInputChange(e, "stepsTitle")}
            required
          />
        </div>
        <div className="form-group">
          <label>Steps Subtitle:</label>
          <textarea
            name="stepsSubTitle"
            value={formData.stepsSubTitle}
            onChange={(e) => handleInputChange(e, "stepsSubTitle")}
            required
          />
        </div>

        {[...Array(6)].map((_, i) => (
          <div className="form-group" key={`step-${i}`}>
            <label>Step {i + 1} Heading:</label>
            <input
              type="text"
              name={`step${i + 1}Heading`}
              value={formData[`step${i + 1}Heading`]}
              onChange={(e) => handleInputChange(e, `step${i + 1}Heading`)}
              required
            />
            <label>Step {i + 1} Text:</label>
            <textarea
              name={`step${i + 1}Text`}
              value={formData[`step${i + 1}Text`]}
              onChange={(e) => handleInputChange(e, `step${i + 1}Text`)}
              required
            />
          </div>
        ))}

        <div className="form-group">
          <label>Steps Button Text:</label>
          <input
            type="text"
            name="stepsButton"
            value={formData.stepsButton}
            onChange={(e) => handleInputChange(e, "stepsButton")}
            required
          />
        </div>

        <h3>Copyrights and Address</h3>
        <div className="form-group">
          <label>Address:</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={(e) => handleInputChange(e, "address")}
          />
        </div>
        <div className="form-group">
          <label>Copyright Text:</label>
          <textarea
            name="copyrights"
            value={formData.copyrights}
            onChange={(e) => handleInputChange(e, "copyrights")}
          />
        </div>

        <div className="form-group">
          <button type="submit" disabled={disable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default RebuildCreditContent;
