import { default as React } from 'react';
import './Footer.css'; // Import the custom CSS

const Footer = ({ content }) => {

  return (
    <footer className="footer-container">
      {/* Address Section */}
      <div className="footer-address">
        <p>
          {content.address}
        </p>
      </div>

      {/* Copyright and Links */}
      <div className="footer-info">
        <p>
          {content.copyrights}{' '}
        </p>
      </div>


    </footer>
  );
};

export default Footer;

