import React from "react";
import { useSelector } from "react-redux";
import "./PaidMediaCards.css";

const EventCard = ({ content, onEventClick }) => {
  // Translation object with 'zh' for Chinese
  const translation = {
    upcoming: {
      en: "Upcoming",
      es: "Próximo",
      fr: "À venir",
      de: "Bevorstehend",
      pt: "Próximo",
      ar: "قادم",
      zh: "即将到来",
    },
    pastEvent: {
      en: "Past Event",
      es: "Evento Pasado",
      fr: "Événement Passé",
      de: "Vergangene Veranstaltung",
      pt: "Evento Passado",
      ar: "حدث سابق",
      zh: "过去的事件",
    },
    registerNow: {
      en: "Register Now",
      es: "Registrarse Ahora",
      fr: "S'inscrire Maintenant",
      de: "Jetzt Registrieren",
      pt: "Registrar Agora",
      ar: "سجل الآن",
      zh: "立即注册",
    },
    uploadedOn: {
      en: "Uploaded on",
      es: "Subido el",
      fr: "Téléchargé le",
      de: "Hochgeladen am",
      pt: "Enviado em",
      ar: "تم الرفع في",
      zh: "上传于",
    },
    enrollNow: {
      en: "Enroll Now",
      es: "Inscribirse Ahora",
      fr: "S'inscrire Maintenant",
      de: "Jetzt Anmelden",
      pt: "Inscrever-se Agora",
      ar: "سجل الآن",
      zh: "立即报名",
    },
  };

  // Language selection logic
  const { userLanguage } = useSelector((state) => state.auth || {});

  const selectedLanguage = (() => {
    const normalizedLanguage = userLanguage
      ? userLanguage.toLowerCase().split("-")[0]
      : null;

    if (normalizedLanguage && translation.upcoming[normalizedLanguage]) {
      return normalizedLanguage;
    }

    return "en";
  })();

  const eventDate = new Date(content.date + "T" + content.time);
  const now = new Date();
  const isUpcoming = eventDate > now;

  const formatDate = (date) => {
    return new Intl.DateTimeFormat(selectedLanguage, {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(date);
  };


  const getStarRating = (product) => {
    let price = product ? product?.pricing : null;
    if (price) {
      if (price < 20) {
        return 1;
      } else if (price < 100) {
        return 2;
      } else if (price < 500) {
        return 3;
      } else if (price < 1500) {
        return 4;
      } else {
        return 5;
      }
    } else {
      return null;
    }
  };

  const pricerating = getStarRating(content);
  const extendedRating = pricerating * 2;

  return (
    <div className="event-card" onClick={() => onEventClick(content)}>
      <div className="event-status">
        <span
          className={`status-indicator ${isUpcoming ? "upcoming" : "past"}`}
        >
          {isUpcoming
            ? translation.upcoming[selectedLanguage]
            : translation.pastEvent[selectedLanguage]}
        </span>
      </div>
      <div
        className="media-thumbnail"
        style={{ backgroundImage: `url(${content.thumbnailUrl})` }}
      >
        <div className="event-overlay">

          <div className="event-date-badge">
            <span className="event-month">
              {eventDate.toLocaleString(selectedLanguage, { month: "short" })}
            </span>
            <span className="event-day">{eventDate.getDate()}</span>
          </div>
        </div>
      </div>
      <div className="media-content">
        <h3 className="media-title">{content.eventTitle}</h3>
        <p className="event-datetime">{formatDate(eventDate)}</p>
        <p className="media-description">{content.description}</p>
        <div className="event-price">
          <span className="price-tag">


    
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: 8, color: "#1a1a1a", fontSize: 12 }}>
                <b>$</b>
              </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  minWidth: 80,
                }}
              >
                {Array(extendedRating)
                  .fill()
                  .map((star, i) => (
                    <svg width="6" height="14" key={i}>
                      <rect
                        width="20"
                        height="50"
                        style={{ fill: "#EEAC1A", strokeWidth: "3" }}
                      />
                    </svg>
                  ))}
                {Array(10 - extendedRating)
                  .fill()
                  .map((star, i) => (
                    <svg width="6" height="14" key={i}>
                      <rect
                        width="20"
                        height="50"
                        style={{ fill: "#cecedb", strokeWidth: "3" }}
                      />
                    </svg>
                  ))}
              </div>
              <span className="rating-value" style={{ fontSize: 12, color: "#1a1a1a" }}>
                {extendedRating}/10
              </span>
            </div>
          </span>

        </div>

        <button className="join-button">
            {translation.registerNow[selectedLanguage]}
          </button>
      </div>
    </div>
  );
};

const CourseCard = ({ content, onEventClick }) => {
  // Reuse translation object from EventCard
  const translation = {
    upcoming: {
      en: "Upcoming",
      es: "Próximo",
      fr: "À venir",
      de: "Bevorstehend",
      pt: "Próximo",
      ar: "قادم",
      zh: "即将到来",
    },
    pastEvent: {
      en: "Past Event",
      es: "Evento Pasado",
      fr: "Événement Passé",
      de: "Vergangene Veranstaltung",
      pt: "Evento Passado",
      ar: "حدث سابق",
      zh: "过去的事件",
    },
    registerNow: {
      en: "Register Now",
      es: "Registrarse Ahora",
      fr: "S'inscrire Maintenant",
      de: "Jetzt Registrieren",
      pt: "Registrar Agora",
      ar: "سجل الآن",
      zh: "立即注册",
    },
    uploadedOn: {
      en: "Uploaded on",
      es: "Subido el",
      fr: "Téléchargé le",
      de: "Hochgeladen am",
      pt: "Enviado em",
      ar: "تم الرفع في",
      zh: "上传于",
    },
    enrollNow: {
      en: "Enroll Now",
      es: "Inscribirse Ahora",
      fr: "S'inscrire Maintenant",
      de: "Jetzt Anmelden",
      pt: "Inscrever-se Agora",
      ar: "سجل الآن",
      zh: "立即报名",
    },
  };

  // Language selection logic
  const { userLanguage } = useSelector((state) => state.auth || {});

  const selectedLanguage = (() => {
    const normalizedLanguage = userLanguage
      ? userLanguage.toLowerCase().split("-")[0]
      : null;

    if (normalizedLanguage && translation.upcoming[normalizedLanguage]) {
      return normalizedLanguage;
    }

    return "en";
  })();

  const uploadDate = new Date(content.date + "T" + content.time);

  const handleCourseClick = () => {
    if (onEventClick) {
      onEventClick(content);
    }
  };


  const getStarRating = (product) => {
    let price = product ? product?.pricing : null;
    if (price) {
      if (price < 20) {
        return 1;
      } else if (price < 100) {
        return 2;
      } else if (price < 500) {
        return 3;
      } else if (price < 1500) {
        return 4;
      } else {
        return 5;
      }
    } else {
      return null;
    }
  };

  const pricerating = getStarRating(content);
  const extendedRating = pricerating * 2;

  return (
    <div className="course-card" onClick={handleCourseClick}>
      <div
        className="media-thumbnail"
        style={{ backgroundImage: `url(${content.thumbnailUrl})` }}
      >
        <div className="course-overlay">
        </div>
      </div>
      <div className="media-content">
        <h3 className="media-title">{content.eventTitle}</h3>
        <p className="upload-date">
          {translation.uploadedOn[selectedLanguage]}{" "}
          {uploadDate.toLocaleDateString(selectedLanguage, {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>
        <p className="media-description">{content.description}</p>
        <div className="course-price">
        <span className="price-tag">


    
<div style={{ display: "flex", alignItems: "center" }}>
  <span style={{ marginRight: 8, color: "#1a1a1a", fontSize: 12 }}>
    <b>$</b>
  </span>
  <div
    style={{
      display: "flex",
      justifyContent: "space-around",
      minWidth: 80,
    }}
  >
    {Array(extendedRating)
      .fill()
      .map((star, i) => (
        <svg width="6" height="14" key={i}>
          <rect
            width="20"
            height="50"
            style={{ fill: "#EEAC1A", strokeWidth: "3" }}
          />
        </svg>
      ))}
    {Array(10 - extendedRating)
      .fill()
      .map((star, i) => (
        <svg width="6" height="14" key={i}>
          <rect
            width="20"
            height="50"
            style={{ fill: "#cecedb", strokeWidth: "3" }}
          />
        </svg>
      ))}
  </div>
  <span className="rating-value" style={{ fontSize: 12, color: "#1a1a1a" }}>
    {extendedRating}/10
  </span>
</div>
</span>

        </div>
        <button className="enroll-button">
            {translation.enrollNow[selectedLanguage]}
          </button>
      </div>
    </div>
  );
};

const MediaGrid = ({ contents, onEventClick }) => {
  return (
    <div className="media-grid">
      {contents.map((content, index) =>
        content.category === "event" ? (
          <EventCard
            key={content._id || index}
            content={content}
            onEventClick={onEventClick}
          />
        ) : (
          <CourseCard
            key={content._id || index}
            content={content}
            onEventClick={onEventClick}
          />
        )
      )}
    </div>
  );
};

export { MediaGrid, EventCard, CourseCard };
