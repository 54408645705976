import React, { useEffect, useState } from "react";
import "./TranslationField.css";
import { useDispatch, useSelector } from "react-redux";
import { TranslationContent } from "../../../redux/reducers/admin";
import { getAllWebContent } from "../../../redux/reducers/user";
import { useHistory } from "react-router-dom";


const HeaderField = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

  const { allWebContent } = useSelector((state) => state?.user);        
  const headerData = allWebContent?.[0]?.header || {};

  const [disable, setDisable] = useState(true);
  const [formData, setFormData] = useState({
    home: "",
    shop: "",
    sell: "",
    contactUs: "",
    verify: "",
    pFinance: "",
    pFinanceContact: "",
    pFinanceAbout: "",
    pFinanceCandE: "",
    pFinanceFP: "",
    pFinanceMedia: "",
    pFinanceNICAF: "",
    pFinanceRCC: "",
    pFinanceT: "",
    pFinanceFAQ: "",
    pFinanceMyCourses: "",
    page: "header",
    isChange: false,
  });

  useEffect(() => {
    if (headerData) {
      setFormData((prevData) => ({
        ...prevData,
        ...headerData,
        isChange: false,
      }));
    }
  }, [headerData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDisable(false);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(TranslationContent(formData));
    setDisable(true);
  };

  return (
    <div className="container">
      <div className="tf-top mx-5 mt-2 mb-2">
        
        <button className="back-button" onClick={history.goBack}>
          &lt;</button>

          <h1 className="mt-3" s  style={{
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'absolute',
}}>
Header Page
</h1>

    </div>
      <form onSubmit={handleSubmit}>
        {[
          { label: "Home", name: "home" },
          { label: "Shop", name: "shop" },
          { label: "Sell", name: "sell" },
          { label: "Contact Us", name: "contactUs" },
          { label: "Verify", name: "verify" },
          { label: "Personal Finance", name: "pFinance" },
          { label: "Personal Finance Contact", name: "pFinanceContact" },
          { label: "Personal Finance About", name: "pFinanceAbout" },
          { label: "Personal Finance Courses and Events", name: "pFinanceCandE" },
          { label: "Personal Finance Finance Planning", name: "pFinanceFP" },
          { label: "Personal Finance Media", name: "pFinanceMedia" },
          { label: "Personal Finance NICAF", name: "pFinanceNICAF" },
          { label: "Personal Finance RCC", name: "pFinanceRCC" },
          { label: "Personal Finance Testimonials", name: "pFinanceT" },
          { label: "Personal Finance FAQ", name: "pFinanceFAQ" },
          { label: "Personal Finance My Courses", name: "pFinanceMyCourses" },
        ].map(({ label, name }) => (
          <div className="form-group" key={name}>
            <label>{label}:</label>
            <input
              type="text"
              name={name}
              value={formData[name]}
              onChange={handleInputChange}
            />
          </div>
        ))}
        <div className="form-group">
          <button type="submit" disabled={disable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default HeaderField;
