import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { TranslationContent } from "../../../redux/reducers/admin"; // Adjust path if necessary
import { getAllWebContent } from "../../../redux/reducers/user"; // Adjust path if necessary
import "./TranslationField.css";
import { useHistory } from "react-router-dom";


const PFContactUsContent = () => {
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(true);
  const history = useHistory();


  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);


  const { allWebContent } = useSelector((state) => state?.user);
  const PFC_Data = allWebContent?.[0]?.personalfinancecontactus;

  const [formData, setFormData] = useState({
    page: "personalfinancecontactus",
    isChange: false,
    headerHeading: "Contact Us",
    headerText: "Get in touch with us for inquiries, support, or partnerships.",
    addressTitle: "Visit Us",
    addressDescription: "",
    callUsTitle: "Call Us",
    callUsDescription: "",
    emailUsTitle: "Email Us",
    emailUsDescription: "",
    websiteTitle: "Website",
    websiteDescription: "Layaliart.com",
    contactHeading: "Speak With Us",
    contactDescription:
      "A Community Marketplace Where Local Small Businesses In Innovation And Creation Meet With Shoppers To Promote, Have A Micro-Loan And Sell Their Creation.",
    formHeading: "Let's Get In Touch",
    successMessage: "Thank you for reaching out to us! We'll get back to you soon.",
    serviceOption1: "Consultation",
    serviceOption2: "Partnership Inquiry",
    serviceOption3: "Customer Support",
    serviceOption4: "Product Information",
    serviceOption5: "Other",
    copyrights: "© 2024 Layali Art. All rights reserved.",
    address: "sasdasd",
    placeholderName: "Enter your name",
    placeholderEmail: "Enter your email",
    placeholderContact: "Enter your contact number",
    placeholderMessage: "Enter your message",
    labelName: "Enter Name",
    labelEmail: "Enter Email",
    labelContact: "Contact Number",
    labelMessage: "Message",
    labelService: "Select Service",
    buttonSubmit: "Send Message"
  });

  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setDisable(false);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(TranslationContent(formData));
    console.log("🚀 ~ PFContactUs Submit:", formData);
    setDisable(true);
  };

  useEffect(() => {
    if (PFC_Data) {
      setFormData((prevState) => ({
        ...prevState,
        ...PFC_Data,
      }));
    }
  }, [PFC_Data]);


  return (
    <div className="container">
      <ToastContainer autoClose={20000} />
      <div className="tf-top mx-5 mt-2 mb-2">
        
          <button className="back-button" onClick={history.goBack}>
            &lt;</button>

            <h1 className="mt-3" s  style={{
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
  }}>
  Personal Finance Contact Us Page
</h1>

      </div>
      <form onSubmit={handleSubmit} className="mx-5">
        <h3>Header Information</h3>
        <div className="form-group">
          <label>Header Heading:</label>
          <input
            type="text"
            value={formData.headerHeading}
            onChange={(e) => handleInputChange(e, "headerHeading")}
            required
          />
        </div>
        <div className="form-group">
          <label>Header Text:</label>
          <textarea
            value={formData.headerText}
            onChange={(e) => handleInputChange(e, "headerText")}
            required
          />
        </div>



        <h3>Contact Section</h3>
        <div className="form-group">
          <label>Contact Heading:</label>
          <input
            type="text"
            value={formData.contactHeading}
            onChange={(e) => handleInputChange(e, "contactHeading")}
          />
        </div>
        <div className="form-group">
          <label>Contact Description:</label>
          <textarea
            value={formData.contactDescription}
            onChange={(e) => handleInputChange(e, "contactDescription")}
          />
        </div>

        <h3>Contact Details</h3>
        <div className="form-group">
          <label>Address Title:</label>
          <input
            type="text"
            value={formData.addressTitle}
            onChange={(e) => handleInputChange(e, "addressTitle")}
            required
          />
        </div>
        <div className="form-group">
          <label>Address Description:</label>
          <textarea
            value={formData.addressDescription}
            onChange={(e) => handleInputChange(e, "addressDescription")}
          />
        </div>

        <div className="form-group">
          <label>Call Us Title:</label>
          <input
            type="text"
            value={formData.callUsTitle}
            onChange={(e) => handleInputChange(e, "callUsTitle")}
          />
        </div>
        <div className="form-group">
          <label>Call Us Description:</label>
          <input
            type="text"
            value={formData.callUsDescription}
            onChange={(e) => handleInputChange(e, "callUsDescription")}
          />
        </div>

        <div className="form-group">
          <label>Email Us Title:</label>
          <input
            type="text"
            value={formData.emailUsTitle}
            onChange={(e) => handleInputChange(e, "emailUsTitle")}
          />
        </div>
        <div className="form-group">
          <label>Email Us Description:</label>
          <input
            type="text"
            value={formData.emailUsDescription}
            onChange={(e) => handleInputChange(e, "emailUsDescription")}
          />
        </div>

        <div className="form-group">
          <label>Website Title:</label>
          <input
            type="text"
            value={formData.websiteTitle}
            onChange={(e) => handleInputChange(e, "websiteTitle")}
          />
        </div>
        <div className="form-group">
          <label>Website Description:</label>
          <input
            type="text"
            value={formData.websiteDescription}
            onChange={(e) => handleInputChange(e, "websiteDescription")}
          />
        </div>

        <h3>Form Section</h3>
        <div className="form-group">
          <label>Form Heading:</label>
          <input
            type="text"
            value={formData.formHeading}
            onChange={(e) => handleInputChange(e, "formHeading")}
          />
        </div>
        <div className="form-group">
          <label>Success Message:</label>
          <input
            type="text"
            value={formData.successMessage}
            onChange={(e) => handleInputChange(e, "successMessage")}
          />
        </div>


        <h3>Inquiry Options</h3>
        {[1, 2, 3, 4, 5].map((i) => (
          <div className="form-group" key={`service-option-${i}`}>
            <label>Service Option {i}:</label>
            <input
              type="text"
              value={formData[`serviceOption${i}`]}
              onChange={(e) => handleInputChange(e, `serviceOption${i}`)}
            />
          </div>
        ))}



        <h3>Footer</h3>
        <div className="form-group">
          <label>Address:</label>
          <input
            type="text"
            value={formData.address}
            onChange={(e) => handleInputChange(e, "address")}
          />
        </div>
        <div className="form-group">
          <label>Copyrights:</label>
          <input
            type="text"
            value={formData.copyrights}
            onChange={(e) => handleInputChange(e, "copyrights")}
          />
        </div>



        <div className="form-group">
          <button type="submit" disabled={disable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default PFContactUsContent;
