import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getAllWebContent } from '../../redux/reducers/user';
import PaidCoursesAndEvents from './components/PaidCoursesAndEvents';
import './CoursesAndEvents.css';
import PFHeader from "../PersonalFinance/components/PFHeader";


function CoursesAndEvents() {
  const dispatch = useDispatch();

  const { translationState, allWebContent } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);

  // Use translation if available, otherwise fall back to default content
  const cae = allWebContent?.[0]?.coursesAndEvents || {};
  const caeTranslation = translationState?.[0]?.translations?.coursesAndEvents?.[userLanguage] || {};
  const content = Object.keys(caeTranslation).length > 0 ? caeTranslation : cae;






  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

return (

  <div>

<PFHeader content={content} />

      {content && (
    <div>
      
      <PaidCoursesAndEvents content={content} />
      
    </div>
  )}
  </div>


  
);
}

export default CoursesAndEvents;