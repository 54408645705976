import React, { useEffect, useState } from "react";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarToggler,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  logout,
  switchRole,
  getExhangeRate,
  SetState as AuthSetState,
} from "../../redux/reducers/auth";
import { SetState } from "../../redux/reducers/user";
import { AppHeaderDropdown, AppNavbarBrand } from "@coreui/react";
import logo from "../../assets/img/brand/logo.png";
import "./defaultHeader.css";
import { getAllWebContent } from "../../redux/reducers/user";
import { IoMdArrowDropdown } from "react-icons/io";
import { translations } from "../TranslationHelper/Translation";

import "flag-icon-css/css/flag-icon.css"; // Import the flag-icon-css styles

const currencyFlags = [
  { AUD: "au" }, // Australia
  { BGN: "bg" }, // Bulgaria
  { BRL: "br" }, // Brazil
  { CAD: "ca" }, // Canada
  { CHF: "ch" }, // Switzerland
  { CNY: "cn" }, // China
  { CZK: "cz" }, // Czech Republic
  { DKK: "dk" }, // Denmark
  { EUR: "eu" }, // European Union
  { GBP: "gb" }, // United Kingdom
  { HKD: "hk" }, // Hong Kong
  { HRK: "hr" }, // Croatia
  { HUF: "hu" }, // Hungary
  { IDR: "id" }, // Indonesia
  { ILS: "il" }, // Israel
  { INR: "in" }, // India
  { ISK: "is" }, // Iceland
  { JPY: "jp" }, // Japan
  { KRW: "kr" }, // South Korea
  { MXN: "mx" }, // Mexico
  { MYR: "my" }, // Malaysia
  { NOK: "no" }, // Norway
  { NZD: "nz" }, // New Zealand
  { PHP: "ph" }, // Philippines
  { PLN: "pl" }, // Poland
  { RON: "ro" }, // Romania
  { RUB: "ru" }, // Russia
  { SEK: "se" }, // Sweden
  { SGD: "sg" }, // Singapore
  { THB: "th" }, // Thailand
  { TRY: "tr" }, // Turkey
  { USD: "us" }, // United States
  { ZAR: "za" }, // South Africa
];

const DefaultHeader = (props) => {
  const renderFlagIcon = (code) => {
    return <span className={`flag-icon flag-icon-${code.toLowerCase()}`} />;
  };

  const { translationState, allWebContent } = useSelector(
    (state) => state.user
  );
  const { userLanguage } = useSelector((state) => state.auth);
  const webContent = allWebContent?.[0]?.header;
  const orignalTranslation =
    translationState?.[0]?.translations?.header?.[userLanguage];

  useEffect(() => {
    dispatch(getAllWebContent());
  }, []);

  const dispatch = useDispatch();
  const history = useHistory();
  const { i18n } = useTranslation();
  const { token, user, cart } = useSelector((state) => state.auth);
  const { userCurrency, localBankTransferAccountDetails } = useSelector(
    (state) => state.user
  );
  const [isOpen, setIsOpen] = useState(false);
  const redirectProfile = () => {
    if (user?.userType === "admin") {
      history.push("/admin/profile");
    } else {
      history.push("/customer-account/account-details");
    }
  };
  const redirectToHome = () => {
    history.push("/");
  };
  const goTOCart = () => {
    if (token) {
      history.push("/my-cart");
    } else {
      history.push("/login");
    }
  };

  const sellerLogin = async () => {
    const data = { role: "seller" };
    dispatch(switchRole(data)).then(({ payload }) => {
      if (payload.user) {
        history.push(`/${payload.user?.userType}/profile`);
      }
    });
  };

  const currencyDropDown = currencyFlags.map((currencyObj, index) => (
    <DropdownItem
      key={index}
      style={{ borderBottom: "none" }}
      onClick={() => {
        // do not update summary when local Bank is selected as payment method
        if (!localBankTransferAccountDetails) {
          dispatch(
            SetState({
              field: "userCurrency",
              value: Object.keys(currencyObj)[0],
            })
          );
          dispatch(getExhangeRate(Object.keys(currencyObj)[0]));
        }
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {Object.keys(currencyObj)[0]}
        {renderFlagIcon(Object.values(currencyObj)[0])}
      </div>
    </DropdownItem>
  ));
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(AuthSetState({ field: "userLanguage", value: lng }));
  };
  return (
    <>
      <div className="hide-on-mobile">
        <img
          className="hide-on-mobile  header-logo mx-1"
          onClick={(e) => redirectToHome(e)}
          src={logo}
        />
      </div>

      <Nav className="d-md-down-none hide-on-mobile-navbar align-items-center">
        <NavItem className="px-1">
          <NavLink style={{ height: "auto" }} onClick={() => history.push("/")}>
            {orignalTranslation?.home
              ? orignalTranslation?.home
              : webContent?.home}
          </NavLink>
        </NavItem>

        <NavItem className="px-1" onClick={() => history.push("/search")}>
          <NavLink style={{ height: "auto" }} href="#">
            {orignalTranslation?.shop
              ? orignalTranslation?.shop
              : webContent?.shop}
          </NavLink>
        </NavItem>

        {user?.userType === "seller" ? (
          <NavItem className="px-1" onClick={sellerLogin}>
            <NavLink style={{ height: "auto" }} href="#">
              {orignalTranslation?.sell
                ? orignalTranslation?.sell
                : webContent?.sell}
            </NavLink>
          </NavItem>
        ) : (
          user?.userType === "user" && (
            <NavItem className="px-1" onClick={sellerLogin}>
              <NavLink style={{ height: "auto" }} href="#">
                {orignalTranslation?.sell
                  ? orignalTranslation?.sell
                  : "Sell on YINN"}
              </NavLink>
            </NavItem>
          )
        )}
        <NavItem className="px-1">
          <NavLink
            style={{ height: "auto" }}
            onClick={() => history.push("/support")}
          >
            {orignalTranslation?.contactUs
              ? orignalTranslation?.contactUs
              : webContent?.contactUs}
          </NavLink>
        </NavItem>

        <NavItem className="px-1">
          <NavLink
            style={{ height: "auto" }}
            onClick={() => history.push("/verify")}
          >
            {orignalTranslation?.verify
              ? orignalTranslation?.verify
              : webContent?.verify}
          </NavLink>
        </NavItem>

        <NavItem className="px-1">
          <NavLink
            style={{ height: "auto" }}
            onClick={() => history.push("/personal-finance")}
          >
            {orignalTranslation?.pFinance
              ? orignalTranslation?.pFinance
              : webContent?.pFinance}
          </NavLink>
        </NavItem>

        <NavItem
          onClick={() => history.push("/auth/login")}
          className="px-1"
          hidden={token}
        >
          <NavLink style={{ height: "auto" }}>
            {orignalTranslation?.signIn
              ? orignalTranslation?.signIn
              : "Sign In"}
          </NavLink>
        </NavItem>

        <NavItem
          onClick={() => history.push("/auth/register")}
          className="px-1 navbar-register "
          hidden={token}
        >
          <NavLink className="navbar-register-text">
            {orignalTranslation?.register
              ? orignalTranslation?.register
              : "Register"}
          </NavLink>
        </NavItem>
      </Nav>

      {/* WEB NAV */}
      <Nav className="ml-auto mt-2 hide-on-mobile" navbar>
        <AppHeaderDropdown direction="down">
          <DropdownToggle nav>
            <p className="profile-nav-link d-flex align-items-center">
              {userLanguage}
              <IoMdArrowDropdown size={30} />
            </p>
          </DropdownToggle>
          <DropdownMenu
            className="profile-dropdown profle-dropdown-text"
            style={{ right: "auto", width: "auto" }}
          >
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("ar")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                Arabic
                {renderFlagIcon("sa")}
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("zh")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                Chinese
                {renderFlagIcon("cn")}
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("en")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                English
                {renderFlagIcon("gb")}
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("fr")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                French
                {renderFlagIcon("fr")}
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("pt")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                Portuguese
                {renderFlagIcon("pt")}
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("es")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                Spanish
                {renderFlagIcon("es")}
              </div>
            </DropdownItem>
          </DropdownMenu>
        </AppHeaderDropdown>
        <AppHeaderDropdown direction="down">
          <DropdownToggle nav>
            <p className="profile-nav-link d-flex align-items-center">
              {userCurrency}
              <IoMdArrowDropdown size={30} />
            </p>
          </DropdownToggle>
          <DropdownMenu
            className="profile-dropdown profle-dropdown-text"
            right
            style={{
              right: "auto",
              height: "300px",
              overflowY: "scroll",
              paddingRight: "8px",
              width: "auto",
            }}
          >
            {currencyDropDown}
          </DropdownMenu>
        </AppHeaderDropdown>

        <AppHeaderDropdown hidden={!token} direction="down">
          <DropdownToggle nav>
            <p
              className="profile-nav-link d-flex align-items-center"
              hidden={!token}
            >
              {user?.firstname}
              <IoMdArrowDropdown size={30} />
            </p>
          </DropdownToggle>
          <DropdownMenu
            className="profile-dropdown profle-dropdown-text"
            right
            style={{ right: "auto", width: "auto" }}
          >
            {user?.userType !== "admin" && (
              <DropdownItem
                style={{ borderBottom: "none" }}
                hidden={!token}
                onClick={(e) => redirectProfile()}
              >
                {translations["myProfile"][userLanguage]}
              </DropdownItem>
            )}
            {user?.userType !== "admin" && (
              <DropdownItem
                style={{ borderBottom: "none" }}
                hidden={!token}
                onClick={(e) => history.push("/customer-account")}
              >
                {translations["myAccount"][userLanguage]}
              </DropdownItem>
            )}
            {user?.userType !== "admin" && (
              <DropdownItem
                style={{ borderBottom: "none" }}
                onClick={(e) => history.push("/customer-account/orders")}
                hidden={!token}
              >
                {translations["myOrders"][userLanguage]}
              </DropdownItem>
            )}
            {user?.userType !== "admin" && (
              <DropdownItem
                style={{ borderBottom: "none" }}
                onClick={(e) =>
                  history.push("/customer-account/user-purchase-coin")
                }
                hidden={!token}
              >
                {translations["yinnCoins"][userLanguage]}
              </DropdownItem>
            )}
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={(e) => history.push("/auth/register")}
              hidden={token}
            >
              Register as user
            </DropdownItem>
            <DropdownItem
              onClick={(e) => history.push("/auth/login")}
              hidden={token}
            >
              Login
            </DropdownItem>
            {user?.userType === "seller" ? (
              <DropdownItem onClick={sellerLogin}>
                {translations["switchToSellerView"][userLanguage]}
              </DropdownItem>
            ) : (
              user?.userType === "user" && (
                <DropdownItem onClick={sellerLogin}>
                  {translations["registerAsSeller"][userLanguage]}
                </DropdownItem>
              )
            )}
            {user?.userType === "admin" && (
              <DropdownItem
                onClick={() => {
                  history.push("/admin/content-management");
                }}
              >
                {translations["switchToAdminView"][userLanguage]}
              </DropdownItem>
            )}
            <DropdownItem
              hidden={!token}
              onClick={() => {
                dispatch(logout()), history.push("/auth/login");
              }}
            >
              {translations["logout"][userLanguage]}
            </DropdownItem>
          </DropdownMenu>
        </AppHeaderDropdown>

        {token && (
          <i
            style={{ color: "white" }}
            className="nav-cart-icon"
            onClick={(e) => goTOCart()}
          >
            {" "}
            {cart?.length > 0 ? (
              <span
                className="badge badge-warning badge-pill"
                style={{ marginLeft: "25px", marginBottom: "25px" }}
              >
                <strong>{cart?.length}</strong>
              </span>
            ) : (
              <span style={{ color: "black" }}>.</span>
            )}
          </i>
        )}
      </Nav>

      {/* MOBILE NAV */}
      <Nav
        className="ml-auto mt-2 hide-on-non-mobile"
        navbar
        style={{ zIndex: 1000, position: "absolute", right: 20 }}
      >
        <AppHeaderDropdown direction="down">
          <DropdownToggle nav>
            <i>
              <div style={{ display: "flex" }}>
                <p className="profile-nav-link">{userLanguage}</p>
                <IoMdArrowDropdown size={20} />
              </div>
            </i>
          </DropdownToggle>
          <DropdownMenu
            className="profile-dropdown profle-dropdown-text"
            style={{ right: "auto", width: "auto" }}
          >
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("ar")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {renderFlagIcon("sa")}
                Arabic
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("zh")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {renderFlagIcon("cn")}
                Chinese
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("en")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {renderFlagIcon("gb")}
                English
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("fr")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {renderFlagIcon("fr")}
                French
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("pt")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {renderFlagIcon("pt")}
                Portuguese
              </div>
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={() => changeLanguage("es")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {renderFlagIcon("es")}
                Spanish
              </div>
            </DropdownItem>
          </DropdownMenu>
        </AppHeaderDropdown>
        <AppHeaderDropdown direction="down">
          <DropdownToggle nav>
            <i>
              <div style={{ display: "flex" }}>
                <p className="profile-nav-link">{userCurrency}</p>
                <IoMdArrowDropdown size={20} />
              </div>
            </i>
          </DropdownToggle>
          <DropdownMenu
            className="profile-dropdown profle-dropdown-text"
            right
            style={{
              right: "auto",
              height: "300px",
              overflowY: "scroll",
              paddingRight: "8px",
              width: "auto",
            }}
          >
            {currencyDropDown}
          </DropdownMenu>
        </AppHeaderDropdown>

        <AppHeaderDropdown hidden={!token} direction="down">
          <DropdownToggle nav>
            <p className="profile-nav-link d-flex align-items-center">
              {user?.firstname} <IoMdArrowDropdown size={20} />
            </p>
          </DropdownToggle>
          <DropdownMenu
            className="profile-dropdown profle-dropdown-text"
            right
            style={{ right: "auto", width: "auto" }}
          >
            <DropdownItem
              style={{ borderBottom: "none" }}
              hidden={!token}
              onClick={(e) => redirectProfile()}
            >
              {translations["myProfile"][userLanguage]}
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={(e) => history.push("/customer-account/orders")}
              hidden={!token}
            >
              {translations["myOrders"][userLanguage]}
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={(e) => history.push("/my-cart")}
              hidden={!token}
            >
              {orignalTranslation?.cart ? orignalTranslation?.cart : "Cart"}
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={(e) =>
                history.push("/customer-account/user-purchase-coin")
              }
              hidden={!token}
            >
              {translations["yinnCoins"][userLanguage]}
            </DropdownItem>
            <DropdownItem
              style={{ borderBottom: "none" }}
              onClick={(e) => history.push("/register")}
              hidden={token}
            >
              Register as user
            </DropdownItem>
            <DropdownItem
              onClick={(e) => history.push("/login")}
              hidden={token}
            >
              Login
            </DropdownItem>
            {user?.userType === "seller" ? (
              <DropdownItem onClick={sellerLogin}>
                {translations["switchToSellerView"][userLanguage]}
              </DropdownItem>
            ) : (
              user?.userType === "user" && (
                <DropdownItem onClick={sellerLogin}>
                  {translations["registerAsSeller"][userLanguage]}
                </DropdownItem>
              )
            )}
            {user?.userType === "admin" && (
              <DropdownItem
                onClick={() => {
                  history.push("/admin/content-management");
                }}
              >
                {translations["switchToAdminView"][userLanguage]}
              </DropdownItem>
            )}
            <DropdownItem
              hidden={!token}
              onClick={() => {
                dispatch(logout()), history.push("/auth/login");
              }}
            >
              {translations["logout"][userLanguage]}
            </DropdownItem>
          </DropdownMenu>
        </AppHeaderDropdown>
      </Nav>
      <Navbar
        className="hide-on-non-mobile"
        expand="lg"
        style={{ zIndex: "99", width: "100%", backgroundColor: "#000000" }}
      >
        <NavbarToggler
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />

        <Collapse isOpen={isOpen} navbar>
          <Nav
            navbar
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <NavItem className="px-2">
              <NavLink
                style={{
                  height: "auto",
                  marginBottom: 20,
                  marginTop: 20,
                  textAlign: "left",
                }}
                onClick={() => history.push("/")}
              >
                {orignalTranslation?.home
                  ? orignalTranslation?.home
                  : webContent?.home}
              </NavLink>
            </NavItem>

            <NavItem className="px-2" onClick={() => history.push("/search")}>
              <NavLink
                style={{ height: "auto", marginBottom: 25, textAlign: "left" }}
                href="#"
              >
                {orignalTranslation?.shop
                  ? orignalTranslation?.shop
                  : webContent?.shop}
              </NavLink>
            </NavItem>

            {user?.userType === "seller" ? (
              <NavItem className="px-2" onClick={sellerLogin}>
                <NavLink
                  style={{
                    height: "auto",
                    marginBottom: 25,
                    textAlign: "left",
                  }}
                  href="#"
                >
                  {orignalTranslation?.sell
                    ? orignalTranslation?.sell
                    : webContent?.sell}
                </NavLink>
              </NavItem>
            ) : (
              user?.userType === "user" && (
                <NavItem className="px-2" onClick={sellerLogin}>
                  <NavLink
                    style={{
                      height: "auto",
                      marginBottom: 25,
                      textAlign: "left",
                    }}
                    href="#"
                  >
                    {translations["sellOnYinn"][userLanguage]}
                  </NavLink>
                </NavItem>
              )
            )}
            <NavItem className="px-2">
              <NavLink
                style={{ height: "auto", marginBottom: 25, textAlign: "left" }}
                onClick={() => history.push("/support")}
              >
                {orignalTranslation?.contactUs
                  ? orignalTranslation?.contactUs
                  : webContent?.contactUs}
              </NavLink>
            </NavItem>

            <NavItem className="px-2">
              <NavLink
                style={{ height: "auto", marginBottom: 25, textAlign: "left" }}
                onClick={() => history.push("/verify")}
              >
                {orignalTranslation?.verify
                  ? orignalTranslation?.verify
                  : "Verify"}
              </NavLink>
            </NavItem>

            <NavItem className="px-2">
              <NavLink
                style={{ height: "auto", marginBottom: 25, textAlign: "left" }}
                onClick={() => history.push("/personal-finance")}
              >
                {orignalTranslation?.pFinance
                  ? orignalTranslation?.pFinance
                  : "Personal Finance"}
              </NavLink>
            </NavItem>

            <NavItem className="px-2" hidden={!token}>
              <NavLink
                style={{ height: "auto", marginBottom: 10, textAlign: "left" }}
                href="/my-cart"
              >
                {orignalTranslation?.cart ? orignalTranslation?.cart : "Cart"}
              </NavLink>
            </NavItem>

            <NavItem
              onClick={() => history.push("/auth/login")}
              className="px-2"
              hidden={token}
            >
              <NavLink
                style={{ height: "auto", marginBottom: 10, textAlign: "left" }}
              >
                {orignalTranslation?.signIn
                  ? orignalTranslation?.signIn
                  : "Sign in"}
              </NavLink>
            </NavItem>

            <NavItem
              onClick={() => history.push("/auth/register")}
              className="px-2 navbar-register "
              hidden={token}
            >
              <NavLink className="navbar-register-text">
                {" "}
                {orignalTranslation?.signIn
                  ? orignalTranslation?.signIn
                  : "Register"}
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

export default DefaultHeader;
