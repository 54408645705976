import { Globe, Mail, MapPin, Phone } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getAllWebContent } from '../../redux/reducers/user';
import Footer from '../PersonalFinance/components/Footer';
import Header from '../PersonalFinance/components/Header';
import axios from "axios";
import './Contact.css';

const Contact = () => {

  const dispatch = useDispatch();

  const { translationState, allWebContent } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);

  // Use translation if available, otherwise fall back to default content
  const data = allWebContent?.[0]?.personalfinancecontactus || {};

  const dataTranslation = translationState?.[0]?.translations?.personalfinancecontactus?.[userLanguage] || {};
  const content = Object.keys(dataTranslation).length > 0 ? dataTranslation : data;



  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);




  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    message: '',
    services: ''
  });


  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (Object.values(formData).every(value => value)) {
      try {
        const apiUrl = '/personal-finance-contact/email';
  
        const payload = {
          name: formData.name,
          subject: formData.services,
          contact: formData.contact,
          message: formData.message,
          user_email: formData.email,
        };
  
        // Use axios to make the API call
        const response = await axios.post(apiUrl, payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        // Check for a successful response
        if (response.status === 200) {
          setFormData({ name: '', email: '', contact: '', message: '', services: '' });
          setSubmitted(true);
        } else {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }
  };
  

  return (
    <div>
      <Header content={content} />
      <div className="contact-container">
        <div className="contact-grid">
          <div className="contact-info">
            <div className="contact-info-header">
              <h2>{content.contactHeading}</h2>
              <p>{content.contactDescription}</p>
            </div>

            <div className="contact-items">
              <div className="contact-item">
                <MapPin className="contact-item-icon" />
                <div className="contact-item-content">
                  <h1>{content.addressTitle}</h1>
                  <p>{content.addressDescription}</p>
                </div>
              </div>

              <div className="contact-item">
                <Phone className="contact-item-icon" />
                <div className="contact-item-content">
                  <h1>{content.callUsTitle}</h1>
                  <p>{content.callUsDescription}</p>
                </div>
              </div>

              <div className="contact-item">
                <Mail className="contact-item-icon" />
                <div className="contact-item-content">
                  <h1>{content.emailUsTitle}</h1>
                  <p>{content.emailUsDescription}</p>
                </div>
              </div>

              <div className="contact-item">
                <Globe className="contact-item-icon" />
                <div className="contact-item-content">
                  <h1>{content.websiteTitle}</h1>
                  <p>{content.websiteDescription}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="contact-form-section">
            <h2>{content.formHeading}</h2>
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="form-group">
                <label>{content.labelName}</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder={content.placeholderName}
                  required
                />
              </div>

              <div className="form-group">
                <label>{content.labelEmail}</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder={content.placeholderEmail}
                  required
                />
              </div>

              <div className="form-group">
                <label>{content.labelContact}</label>
                <input
                  type="tel"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChange}
                  placeholder={content.placeholderContact}
                  required
                />
              </div>

              <div className="form-group">
                <label>{content.labelMessage}</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder={content.placeholderMessage}
                  required
                ></textarea>
              </div>

              <div className="form-group">
                <label>{content.labelService}</label>
                <select
                  name="services"
                  value={formData.services}
                  onChange={handleChange}
                  required
                >
                  <option value="">{content.labelService}</option>
                  <option value="Consultation">{content.serviceOption1}</option>
                  <option value="Partnership Inquiry">{content.serviceOption2}</option>
                  <option value="Customer Support">{content.serviceOption3}</option>
                  <option value="Product Information">{content.serviceOption4}</option>
                  <option value="Other">{content.serviceOption5}</option>
                </select>
              </div>

              <button type="submit" className="submit-button">
                {content.buttonSubmit}
              </button>
            </form>

            {submitted && (
              <div className="success-message">
                {content.successMessage}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer content={content} />
    </div>
  );
};

export default Contact;
