import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getAllWebContent } from '../../redux/reducers/user';
import Blog from './components/Blog';
import FinancialCoachingProcess from './components/FinancialCoachingProcess';
import Footer from '../PersonalFinance/components/Footer';
import Header from '../PersonalFinance/components/Header';
import './RebuildCreditScores.css';

function RebuildCreditScores() {
  const dispatch = useDispatch();

  const { translationState, allWebContent } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);

  // Use translation if available, otherwise fall back to default content
  const rebuildcreditscores = allWebContent?.[0]?.rebuildcreditscores || {};
  const rebuildcreditscoresTranslation = translationState?.[0]?.translations?.rebuildcreditscores?.[userLanguage] || {};
  const content = Object.keys(rebuildcreditscoresTranslation).length > 0 ? rebuildcreditscoresTranslation : rebuildcreditscores;



  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

  return (
    <>
      <Header content={content} />
      <Blog content={content} />
      <FinancialCoachingProcess content={content} />
      
      <Footer content={content} />
    </>
  );
}

export default RebuildCreditScores;
