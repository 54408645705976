import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getAllWebContent } from '../../redux/reducers/user';
import CallToAction from '../PersonalFinance/components/CallToAction';
import Footer from "../PersonalFinance/components/Footer";
import Header from "../PersonalFinance/components/Header";
import TestimonialsCard from "./components/TestimonialCard";
import TestimonialContact from "./components/TestimonialContact";

function Testimonials() {
  const dispatch = useDispatch();

  const { translationState, allWebContent } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);

  // Use translation if available, otherwise fall back to default content
  const testimonials = allWebContent?.[0]?.testimonials || {};

  const testimonialsTranslation = translationState?.[0]?.translations?.testimonials?.[userLanguage] || {};
  const content = Object.keys(testimonialsTranslation).length > 0 ? testimonialsTranslation : testimonials;



  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);


  return (
    <div>
      <Header content={content} />
      <TestimonialsCard content={content} />
      <TestimonialContact content={content} />
      {/* <CallToAction content={content} /> */}
      <Footer content={content} />


    </div>
  );
}

export default Testimonials;
