import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllWebContent } from "../../redux/reducers/user";
import { useHistory } from "react-router-dom";
import PFHeader from "../PersonalFinance/components/PFHeader";
import "./Media.css";

const MediaCard = ({ content }) => {
  const history = useHistory();

  const handleCardClick = () => {
    history.push(`/media/${content.title}`, {
      title: content.title,
      videoUrl: content.videoUrl,
      description: content.description,
    });
  };

  const encodedUrl = encodeURI(content.thumbnailUrl || "/fallback-image.jpg");

  return (
    <div className="media-card" onClick={handleCardClick}>
      <div
        className="media-card-thumbnail"
        style={{ backgroundImage: `url(${encodedUrl})` }}
      />
      <div className="media-card-content">
        <h3 className="media-card-title">{content.title}</h3>
        <p className="media-card-info">YINN • {content.dateUploaded}</p>
      </div>
    </div>
  );
};

function Media() {
  const dispatch = useDispatch();
  const { translationState, allWebContent } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);

  const media = allWebContent?.[0]?.media || [];
  const mediaTranslation =
    translationState?.[0]?.translations?.media?.[userLanguage] || [];
  const content = mediaTranslation.length > 0 ? mediaTranslation : media;

  const translation = {
    heading: {
      ar: "الإعلام المالي الشخصي",
      zh: "个人理财媒体",
      en: "Personal Finance Media",
      fr: "Médias de finance personnelle",
      pt: "Mídia de Finanças Pessoais",
      es: "Medios de Finanzas Personales",
    },
    error: {
      ar: "لا توجد محتويات متاحة.",
      zh: "没有可用内容。",
      en: "No content available.",
      fr: "Pas de contenu disponible.",
      pt: "Nenhum conteúdo disponível.",
      es: "No hay contenido disponible.",
    },
  };

  useEffect(() => {
    dispatch(getAllWebContent()).then(() => setLoading(false));
  }, [dispatch]);

  return (
    <div>
      <PFHeader content={content} />
      <div>
        {loading ? (
          <p className="loading-message">Loading content...</p>
        ) : (
          <>
            <div className="media-heading">
              <h1>{translation.heading[userLanguage] || translation.heading.en}</h1>
            </div>
            <div className="media-card-container">
              {content.length > 0 ? (
                content.map((item, index) => <MediaCard key={index} content={item} />)
              ) : (
                <p className="error-message">
                  {translation.error[userLanguage] || translation.error.en}
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Media;
