import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RTCSubscriber } from "red5pro-webrtc-sdk";

const EventPlayer = memo((props) => {
  const { subscriberProductToken, onClose } = props;
  const { userLanguage } = useSelector((state) => state.auth);
  const { translationState } = useSelector((state) => state.user);

  useEffect(() => {
    if (subscriberProductToken) {
      start();
    }
    return () => {
      handleDisclose();
    };
  }, [subscriberProductToken]);

  const subscriber = {
    instance: null,
    async start() {
      try {
        this.instance = new RTCSubscriber();
        await this.instance.init({
          protocol: "wss",
          port: 5443,
          host: "live.yinn.ca",
          app: "live",
          streamName: subscriberProductToken,
          rtcConfiguration: {
            iceServers: [{ urls: "stun:stun2.l.google.com:19302" }],
            iceCandidatePoolSize: 2,
            bundlePolicy: "max-bundle",
          },
          mediaElementId: "red5pro-subscriber",
          subscriptionId:
            subscriberProductToken +
            Math.floor(Math.random() * 0x10000).toString(16),
        });
        await this.instance.subscribe();
      } catch (e) {
        const translatedContent = translationState?.[0]?.translations?.event?.[userLanguage] || {};
        toast.error(
          `${
            translatedContent.subscriberError ||
            "An error occurred in establishing a subscriber session:"
          } ${e}`
        );
        console.error(
          `🚀 ~ ${
            translatedContent.subscriberError ||
            "An error occurred in establishing a subscriber session:"
          }`,
          e
        );
        handleDisclose();
      }
    },

    stop() {
      const video = document.getElementById("red5pro-subscriber");
      const mediaStream = video?.srcObject;
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
        video.srcObject = null;
      }
    },
  };

  const start = async () => {
    await subscriber.start();
  };

  const handleDisclose = () => {
    subscriber.stop();
    onClose();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",  // Full viewport height
        width: "100%",    // Full width
      }}
    >
      <video
        id="red5pro-subscriber"
        autoPlay
        controls
        playsInline
        style={{ width: "100%", height: "100%" }}
      ></video>
    </div>
  );
});

export default EventPlayer;
