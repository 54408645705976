
import React from 'react';
import './QA.css';
const QA = ({ content }) => {

  return (
    <div className="aqCard">
      <h2 className="aq-heading">Frequently Asked Questions</h2>

      <div className="aqCard-container">
        <h3 className="aqCard-heading">1. {content.q1}</h3>
        <p className="aqCard-text">{content.a1}</p>
      </div>

      <div className="aqCard-container">
        <h3 className="aqCard-heading">2. {content.q2}</h3>
        <p className="aqCard-text">{content.a2}</p>
      </div>

      <div className="aqCard-container">
        <h3 className="aqCard-heading">3. {content.q3}</h3>
        <p className="aqCard-text">{content.a3}</p>
      </div>

      <div className="aqCard-container">
        <h3 className="aqCard-heading">4. {content.q4}</h3>
        <p className="aqCard-text">{content.a4}</p>
      </div>

      <div className="aqCard-container">
        <h3 className="aqCard-heading">5. {content.q5}</h3>
        <p className="aqCard-text">{content.a5}</p>
      </div>

      <div className="aqCard-container">
        <h3 className="aqCard-heading">6. {content.q6}</h3>
        <p className="aqCard-text">{content.a6}</p>
      </div>

      <div className="aqCard-container">
        <h3 className="aqCard-heading">7. {content.q7}</h3>
        <p className="aqCard-text">{content.a7}</p>
      </div>

      <div className="aqCard-container">
        <h3 className="aqCard-heading">8. {content.q8}</h3>
        <p className="aqCard-text">{content.a8}</p>
      </div>

      <div className="aqCard-container">
        <h3 className="aqCard-heading">9. {content.q9}</h3>
        <p className="aqCard-text">{content.a9}</p>
      </div>

      <div className="aqCard-container">
        <h3 className="aqCard-heading">10. {content.q10}</h3>
        <p className="aqCard-text">{content.a10}</p>
      </div>


    </div>
  );
};

export default QA;

