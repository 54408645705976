import { default as React } from 'react';
import image from "../../../assets/img/personalFinance/dream.jpg";
import promise from "../../../assets/img/personalFinance/promise.jpg";
import './BlogBottom.css';

const BlogBottom = ({ content }) => {
  return (
    <div className="bb-finance-container">
      <div className="bb-finance-content">
        <img
          src={promise}
          alt="Couple discussing personal finance"
          className="bb-finance-image"
        />
        <div className="bb-finance-text">
          <h2>{content.blog6Heading}</h2>
          <p>{content.blog6Text}</p>
        </div>
      </div>

      <div className="bb-finance-content">
        <img
          src={image}
          alt="Couple looking at finances"
          className="bb-finance-image"
        />
        <div className="bb-finance-text">
          <h2>{content.blog7Heading}</h2>
          <p>{content.blog6Text}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogBottom;
