import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getAllWebContent, getMyCourses } from '../../../redux/reducers/user';
import EventPlayer from './EventPlayer';
import { MediaGrid } from './MediaComponents';
import "./MyCourses.css";
import { useHistory } from "react-router-dom";

import PFHeader from "../../PersonalFinance/components/PFHeader";

// Enhanced Translation Object
const translation = {
  myLearningJourney: {
    en: "My Learning Journey",
    es: "Mi Viaje de Aprendizaje",
    fr: "Mon Parcours d'Apprentissage",
    zh: "我的学习之旅",
    pt: "Minha Jornada de Aprendizado",
    ar: "رحلتي التعليمية"
  },
  enrolled: {
    en: "Enrolled",
    es: "Inscritos",
    fr: "Inscrits",
    zh: "已注册",
    pt: "Inscritos",
    ar: "مقيد"
  },
  events: {
    en: "Events",
    es: "Eventos",
    fr: "Événements",
    zh: "事件",
    pt: "Eventos",
    ar: "فعاليات"
  },
  courses: {
    en: "Courses",
    es: "Cursos",
    fr: "Cours",
    zh: "课程",
    pt: "Cursos",
    ar: "دورات"
  },
  noCoursesYet: {
    en: "No Courses Yet",
    es: "No hay cursos aún",
    fr: "Pas encore de cours",
    zh: "还没有课程",
    pt: "Ainda sem cursos",
    ar: "لا دورات بعد"
  },
  browseCourses: {
    en: "Browse Courses",
    es: "Explorar cursos",
    fr: "Parcourir les cours",
    zh: "浏览课程",
    pt: "Procurar cursos",
    ar: "تصفح الدورات"
  },
  emptyStateMessage: {
    en: "You haven't enrolled in any courses or events. Start learning today!",
    es: "Aún no te has inscrito en ningún curso o evento. ¡Comienza a aprender hoy!",
    fr: "Vous n'êtes inscrit à aucun cours ou événement. Commencez à apprendre aujourd'hui !",
    zh: "你还没有报名任何课程或活动。今天就开始学习吧！",
    pt: "Você ainda não se inscreveu em nenhum curso ou evento. Comece a aprender hoje!",
    ar: "لم تقم بالتسجيل في أي دورة أو فعالية. ابدأ التعلم اليوم!"
  },
  loading: {
    en: "",
    es: "",
    fr: "",
    zh: "",
    pt: "",
    ar: ""
  },
  loadingError: {
    en: "Failed to load courses. Please try again later.",
    es: "No se pudieron cargar los cursos. Inténtelo de nuevo más tarde.",
    fr: "Échec du chargement des cours. Veuillez réessayer plus tard.",
    zh: "加载课程失败。请稍后重试。",
    pt: "Falha ao carregar os cursos. Por favor, tente novamente mais tarde.",
    ar: "فشل تحميل الدورات. يرجى المحاولة مرة أخرى لاحقًا."
  }
};

function MyCourses() {
  const dispatch = useDispatch();
  const { translationState, allWebContent } = useSelector((state) => state.user);
  const { userLanguage, user } = useSelector((state) => state.auth);
  const [coursesId, setCoursesId] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();




  // Improved Language Selection Logic
  const selectedLanguage = (() => {
    // If userLanguage is not set, default to 'en'
    if (!userLanguage) return 'en';

    // Normalize the language code (convert to lowercase, take first part of locale)
    const normalizedLanguage = userLanguage.toLowerCase().split('-')[0];
    
    // Check if the normalized language exists in our translation object
    const supportedLanguages = Object.keys(translation.myLearningJourney);
    return supportedLanguages.includes(normalizedLanguage) 
      ? normalizedLanguage 
      : 'en';
  })();

  useEffect(() => {
    if(!user)
      {
        history.push("/login");
      }

  }, []);

  useEffect(() => {

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await dispatch(getMyCourses(user.id));
        if (response.payload) {
          setCoursesId(response.payload.courseIds);
        }
        await dispatch(getAllWebContent());
        setIsLoading(false);
      } catch (err) {
        setError(translation.loadingError[selectedLanguage]);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, user?.id, selectedLanguage]);

  // Prepare content for translation
  const cae = allWebContent?.[0]?.coursesAndEvents || {};
  const caeTranslation =
    translationState?.[0]?.translations?.coursesAndEvents?.[userLanguage] || {};
  const content = Object.keys(caeTranslation).length > 0 ? caeTranslation : cae;

  // Filter content based on user's enrolled courses
  const filteredContent = Array.isArray(content)
    ? content.filter(item => Array.isArray(coursesId) && coursesId.includes(item._id))
    : [];

  // Event handling methods
  const handleEventClick = (eventContent) => {
    setSelectedEvent(eventContent);
  };

  const handleCloseEventPlayer = () => {
    setSelectedEvent(null);
  };

  // Render methods
  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="loader-container">
          <div className="loader">{translation.loading[selectedLanguage]}</div>
        </div>
      );
    }

    if (error) {
      return <div className="error-message">{error}</div>;
    }

    if (filteredContent.length === 0) {
      return (
        <div className="empty-state">
          <h2>{translation.noCoursesYet[selectedLanguage]}</h2>
          <p>{translation.emptyStateMessage[selectedLanguage]}</p>
          <button 
            className="browse-courses-btn" 
            onClick={() => window.location.href = '/courses'}
          >
            {translation.browseCourses[selectedLanguage]}
          </button>
        </div>
      );
    }

    return (
      <MediaGrid 
        contents={filteredContent} 
        onEventClick={handleEventClick}
      />
    );
  };

  // Render selected event player if an event is selected
  if (selectedEvent) {
    return (
      <div className="event-player-wrapper">
        <button className="close-event-btn" onClick={handleCloseEventPlayer}>
          ✕
        </button>
        <EventPlayer
          subscriberProductToken={selectedEvent.productEventTokenId}
          onClose={handleCloseEventPlayer}
        />
      </div>
    );
  }

  // Main component render
  return (
    <div className="my-courses-page">
      <PFHeader content={content} />

      <div className="page-content">
        <div className="page-header">
          <h1>{translation.myLearningJourney[selectedLanguage]}</h1>
          <div className="header-stats">
            <div className="stat-item">
              <span className="stat-value">{filteredContent.length}</span>
              <span className="stat-label">{translation.enrolled[selectedLanguage]}</span>
            </div>
            <div className="stat-item">
              <span className="stat-value">
                {filteredContent.filter(item => item.category === 'event').length}
              </span>
              <span className="stat-label">{translation.events[selectedLanguage]}</span>
            </div>
            <div className="stat-item">
              <span className="stat-value">
                {filteredContent.filter(item => item.category === 'course').length}
              </span>
              <span className="stat-label">{translation.courses[selectedLanguage]}</span>
            </div>
          </div>
        </div>

        {renderContent()}
      </div>
    </div>
  );
}

export default MyCourses;