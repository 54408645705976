import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getAllWebContent } from "../../../redux/reducers/user";
import "./TranslationField.css";

import { Link, useHistory } from "react-router-dom";

function Styledbuttons() {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getAllWebContent());
  }, []);

  return (
    <div>
      <ToastContainer autoClose={20000} />
      <h1
        className="d-flex justify-content-center mt-5 mb-4"
        style={{ fontSize: "28px", fontWeight: "bold" }}
      >
        Select and Add your content
      </h1>
      <div className="d-flex flex-wrap justify-content-center">
        <Link to="/admin/translationhomefield">
          <button className="content-button">Home Page</button>
        </Link>
        <Link to="/admin/homefeaturefield">
          <button className="content-button">Home Features</button>
        </Link>

        <button
          className="content-button"
          onClick={() => history.push("/admin/personalfinancefield")}
        >
          Personal Finance
        </button>



        <button
          className="content-button"
          onClick={() => history.push("/admin/testimonialsfield")}
        >
          Testimonials
        </button>


        <button
          className="content-button"
          onClick={() => history.push("/admin/contactUsField")}
        >
          Contact Us
        </button>


        <button
          className="content-button"
          onClick={() => history.push("/admin/faqfield")}
        >
          FAQ
        </button>

        <button
          className="content-button"
          onClick={() => history.push("/admin/financeplanningcontent")}
        >
          Finance Planning
        </button>


        <button
          className="content-button"
          onClick={() => history.push("/admin/aboutcontent")}
        >
          About Us
        </button>

        <button
          className="content-button"
          onClick={() => history.push("/admin/pfcontactuscontent")}
        >
          Personal Finance Contact Us
        </button>

        <button
          className="content-button"
          onClick={() => history.push("/admin/rebuildcreditcontent")}
        >
          Rebuild Credit
        </button>

        <button
          className="content-button"
          onClick={() => history.push("/admin/nicaf-content")}
        >
          NICAF Content
        </button>



        <button
          className="content-button"
          onClick={() => history.push("/admin/shopfeild")}
        >
          Shop
        </button>

        <Link to="/admin/verifyfield">
          <button className="content-button">Verify</button>
        </Link>
        <Link to="/admin/translationfooterfield">
          <button className="content-button">Footer</button>
        </Link>
      </div>
      <div>
        <header />
      </div>
    </div>
  );
}

export default Styledbuttons;

// import React from "react";

// function Styledbuttons() {
//   return <div>TranslationField</div>;
// }

// export default Styledbuttons;
