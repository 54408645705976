import React, { useEffect, useState, useRef } from "react";
import { delay } from "lodash";
import { Button, Label, Input, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import { ToastContainer, toast } from "react-toastify";
import {
  addFilter,
  addToCart,
  clearProduct,
  getAds,
  getProducts,
  updateAd,
} from "../../../redux/reducers/auth";
import {
  getSingleProduct,
  getSuggestedPrice,
} from "../../../redux/reducers/auth";
import "./productdetail.css";
import plusButton from "../../../assets/img/plus-button.svg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AppHeader } from "@coreui/react";
import SkyLight from "react-skylight";
import ReactImageMagnify from "react-image-magnify";
import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";
import RatingWidget, { MiniRatingWidget } from "./RatingWidget";
import avatarPlaceholder from "../../../assets/img/avatar-placeholder.svg";
import DateRangePicker from "../../Shared/DateRangePicker";
import CarouselImageMagnify from "../Home/CarouselImageMagnify";
import RelatedProducts from "./RelatedProducts";
import { IoMdAdd } from "react-icons/io";
import { FaMinus } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { materialList } from "../../../views/Product/addproduct/Materiallist";
import { translations } from "../../TranslationHelper/Translation";

const ProductDetail = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const priceModal = useRef(null);
  const [qty, setQty] = useState(1);
  const [buttonType, setButtonType] = useState("");
  const [customLoader, setCustomLoader] = useState(false);
  const [category, setCategory] = useState("");
  const [suggestedValue, setSuggestedValue] = useState(0);
  const [residentStartDate, setResidentStartDate] = useState("");
  const [residentEndDate, setResidentEndDate] = useState("");
  const urlArray = history.location.pathname.split("/");
  const productId = urlArray[urlArray.length - 1];
  const {
    singleProduct,
    suggestedPrice,
    loading,
    user,
    ads,
    currentIP,
    products,
    userLanguage,
    cart,
  } = useSelector((state) => state.auth);
  const { translationState, allWebContent } = useSelector(
    (state) => state.user
  );
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [newPrice, setNewPrice] = useState("");
  const checkCart = cart?.map((item) => item?.product?.eventCategory);
  const productDetailContent = allWebContent?.[0]?.productDetail;
  const productDetailTranslation =
    translationState?.[0]?.translations?.productDetail?.[userLanguage];
  const addproductTranslation =
    translationState?.[0]?.translations?.addProduct?.[userLanguage];
  const productDigitalTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];
  const ShopContent = allWebContent?.[0]?.shop;
  const shopTranslation =
    translationState?.[0]?.translations?.shop?.[userLanguage];
  const EventTranslation =
    translationState?.[0]?.translations?.event?.[userLanguage];
  const ChatGPTTranslated = singleProduct?.translatedArray?.filter(
    (item) => item.language == userLanguage
  );

  const filterProduct = products?.filter((id) => id._id !== singleProduct?._id);
  const features = ads?.slice(0, 5) || [];

  useEffect(() => {
    dispatch(clearProduct());
    const arr = [];
    dispatch(getSingleProduct(productId)).then((res) => {
      if (res?.payload?.data?.mainCategory) {
        arr.push(res?.payload?.data?.mainCategory);
      }
      if (res?.payload?.data?.subCategory) {
        arr.push(res?.payload?.data?.subCategory);
      }
      dispatch(addFilter({ field: "related", value: arr }));
      dispatch(addFilter({ field: "search", value: "" }));
      dispatch(addFilter({ field: "pageNo", value: 1 }));
      dispatch(addFilter({ field: "perPage", value: 8 }));
      dispatch(getAds());
      if (arr.length > 0) {
        dispatch(getProducts());
      }
      setCustomLoader(false);
    });

    setCustomLoader(true);
    scrollToTop();
    return () => {
      dispatch(addFilter({ field: "related", value: [] }));
    };
  }, [productId]);

  useEffect(() => {
    if (suggestedPrice) {
      delay(() => {
        if (buttonType == "shipping") shipping();
        else if (buttonType == "addToCart") addCart();
      }, 2000);
    }
  }, [suggestedPrice]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const convertCommentFromJSONToHTML = (text) => {
    return stateToHTML(convertFromRaw({ entityMap: {}, blocks: text.blocks }));
  };
  const incrementCounter = () => {
    if (qty < singleProduct.stockQuantity) {
      setQty(qty + 1);
    }
  };

  const decrementCounter = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  const checkSuggestedPriceEvent = (type, suggestedValue) => {
    setButtonType(type);
    const price = parseFloat(suggestedValue);
    setSuggestedPrice(price);
    dispatch(
      getSuggestedPrice({
        price,
        productId,
      })
    ).then(({ payload }) => {

      if (payload.message) {
        toast.success(translations["offerAccepted"][userLanguage], {
          autoClose: 3000,
        });
      } else {
        toast.error(translations["tooFarFromValuingProduct"][userLanguage], {
          onClose: 3000,
        });
      }
    });
  };

  const checkSuggestedPrice = (type) => {
    if (!user) {
      toast.error("Please login before buying any product.", {
        onClose: 3000,
      });
      return;
    }
    
    setButtonType(type);
    const price = parseFloat(suggestedValue);
  
    dispatch(
      getSuggestedPrice({
        price,
        productId,
      })
    ).then(({ payload }) => {

       if (payload.message) {
        toast.success(translations["offerAccepted"][userLanguage]);
        setIsOpen(false);
      } else {
        setNewPrice(payload.newPrice);
  
        if (payload.checkPrice <= 10) {
          toast.error(translations["closeToValuingProduct"][userLanguage], {
            autoClose: 3000,
          });
        } else {
          toast.error(translations["tooFarFromValuingProduct"][userLanguage], {
            autoClose: 3000,
          });
        }
  
        let updatedCount = count + 1;
        setCount(updatedCount);
  
        if (updatedCount === 2) {
          setIsOpen(true);
          return;
        }
      }
    });
  };
  

  const shipping = () => {
    const data = {
      product: { ...singleProduct, unitPrice: parseFloat(suggestedValue) },
      qty,
    };
    if (checkCart?.[0] === "event") {
      toast.error(translations["categoryNotSame"][userLanguage], {
        onClose: 3000,
      });
    } else {
      if (data?.product?.eventCategory !== "event") {
        dispatch(addToCart(data));
        history.push("/my-cart");
      } else if (cart?.length === 0) {
        dispatch(addToCart(data));
        history.push("/my-cart");
      } else {
        toast.error(translations["categoryNotSame"][userLanguage], {
          onClose: 3000,
        });
      }
    }
  };
  const addCart = () => {
    const data = {
      product: { ...singleProduct, unitPrice: parseFloat(suggestedValue) },
      qty,
    };
    toast.success("Added to cart");
    dispatch(addToCart(data));
  };

  const getStarRating = () => {
    let price = singleProduct
      ? singleProduct?.dynamicPrice?.currentPrice
      : null;
    if (price) {
      if (price < 20) {
        return 1;
      } else if (price < 100) {
        return 2;
      } else if (price < 500) {
        return 3;
      } else if (price < 1500) {
        return 4;
      } else {
        return 5;
      }
    } else {
      return null;
    }
  };

  const setSuggestedPrice = (price) => {
    setSuggestedValue(price);
  };

  const viewProduct = (item, user) => {
    const ip = item?.ips?.findIndex((ip) => ip === currentIP);
    let id = 0;
    if (user !== null) {
      id = item?.userIds?.findIndex((id) => id === user?._id);
    }
    if (ip === -1 || id === -1) {
      const data = {
        productId: item?.productId?._id,
        currentIP,
        userId: user?._id || null,
      };
      dispatch(updateAd(data)).then(() => dispatch(getAds()));
    }
    if (id == null) {
      history.push(
        `/${item?.type == "digital" ? "digitalproduct" : "productdetail"}/${
          item?._id
        }`
      );
    } else {
      history.push(
        `/${
          item?.productId.type == "digital" ? "digitalproduct" : "productdetail"
        }/${item?.productId._id}`
      );
    }
  };
  const closeHanlder = () => {
    setIsOpen(false);
    setCount(0);
  };

  
  return (
    <div className="animated fadeIn" style={{ position: "relative" }}>
      {isOpen && (
        <div className="price-detail-wrapper">
          <div className="priceModal">
            <div className="close_icon">
              <IoClose onClick={closeHanlder} />
            </div>

            <p className="error-tag-line">
              {productDetailTranslation?.suggestedPriceline
                ? productDetailTranslation?.suggestedPriceline
                : `We suggest setting the price from ${newPrice}$ and upwards for the best results.`}
            </p>
            <div
              style={{
                display: "flex",
                marginBottom: 8,
                justifyContent: "space-in-between",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  minWidth: 205,
                  marginBottom: 10,
                  paddingLeft: 20,
                }}
              >
                <input
                  autoFocus
                  className={
                    !suggestedPrice ? "price-input" : "price-input-accepted"
                  }
                  type="number"
                  min={0}
                  placeholder={
                    productDetailTranslation?.placeHolderUSD
                      ? productDetailTranslation?.placeHolderUSD + " USD"
                      : "Offer in USD"
                  }
                  onChange={(e) => setSuggestedPrice(e.target.value)}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  minWidth: 205,
                  paddingLeft: 20,
                }}
              >
                <button
                  className="buy-now"
                  style={{
                    backgroundColor:
                      singleProduct?.stockQuantity <= 0 && !(singleProduct.hasOwnProperty("eventCategory")) ? "white" : "black",
                    color:
                      singleProduct?.stockQuantity <= 0 && !(singleProduct.hasOwnProperty("eventCategory")) ? "black" : "white",
                    cursor:
                      singleProduct?.stockQuantity <= 0 && !(singleProduct.hasOwnProperty("eventCategory"))
                        ? "not-allowed"
                        : "pointer",
                  }}
                  onClick={() => checkSuggestedPrice("shipping")}
                  disabled={singleProduct?.stockQuantity <= 0 && !(singleProduct.hasOwnProperty("eventCategory"))}
                >
                  {singleProduct?.isResidency
                    ? `${
                        productDetailTranslation?.rentbutton
                          ? productDetailTranslation?.rentbutton
                          : "Rent Now"
                      }`
                    : singleProduct?.eventCategory !== "event"
                    ? `${
                        productDetailTranslation?.buyButton
                          ? productDetailTranslation?.buyButton
                          : "Buy Now"
                      }`
                    : productDetailTranslation?.instructedParaLine
                    ? productDetailTranslation?.instructedParaLine
                    : "Buy Event Ticket"}
                </button>

                {!singleProduct?.isResidency && (
                  <button
                  disabled={singleProduct?.stockQuantity <= 0 && !(singleProduct.hasOwnProperty("eventCategory"))}
                    className="add-to-cart"
                    onClick={() => checkSuggestedPrice("addToCart")}
                  />
                )}
              </div>
            </div>
            <h2 className="product-detail-heading mb-3">
              {productDetailTranslation?.productheading
                ? productDetailTranslation?.productheading
                : "Product Details"}
            </h2>
            <div className="row my-3">
              <div className="col-md-4 col-12 price-detail-heading ">
                <p>
                  {productDetailTranslation?.carbonEmission
                    ? productDetailTranslation?.carbonEmission
                    : "Carbon Emission"}
                </p>
              </div>
              <div className="col-md-8 col-12 pl-4">
                {singleProduct?.carbonEmission?.toFixed(4)
                  ? singleProduct?.carbonEmission?.toFixed(4)
                  : productDetailTranslation?.undefineline
                  ? productDetailTranslation?.undefineline
                  : "Data is not available for this product"}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-4 col-12 price-detail-heading ">
                {productDigitalTranslation?.investment
                  ? productDigitalTranslation?.investment
                  : " Investment on Product:"}
              </div>
              <div className="col-md-8 col-12 pl-4">
                {singleProduct?.investmentStory
                  ? singleProduct?.investmentStory
                  : productDetailTranslation?.undefineline
                  ? productDetailTranslation?.undefineline
                  : "Data is not available for this product"}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-4 col-12 price-detail-heading">
                {productDigitalTranslation?.environment
                  ? productDigitalTranslation?.environment
                  : " Environmental Impact:"}
              </div>
              <div className="col-md-8 col-12 pl-4">
                {ChatGPTTranslated?.[0]?.environmentStory ? (
                  <div className="product-brand">
                    {ChatGPTTranslated[0]?.environmentStory}
                  </div>
                ) : (
                  <div>
                    {singleProduct?.environmentStory
                      ? singleProduct?.environmentStory
                      : productDetailTranslation?.undefineline
                      ? productDetailTranslation?.undefineline
                      : "Data is not available for this product"}
                  </div>
                )}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-4 col-12 price-detail-heading">
                {productDigitalTranslation?.productStory
                  ? productDigitalTranslation?.productStory
                  : "Brief Story Of Product:"}
              </div>
              <div className="col-md-8 col-12 pl-4">
                {ChatGPTTranslated?.[0]?.productStory ? (
                  <div className="product-brand">
                    {ChatGPTTranslated[0]?.productStory}
                  </div>
                ) : (
                  <div>
                    {singleProduct?.productStory
                      ? singleProduct?.productStory
                      : productDetailTranslation?.undefineline
                      ? productDetailTranslation?.undefineline
                      : "Data is not available for this product"}
                  </div>
                )}
              </div>
            </div>

            <div className="row my-3">
              <div className="col-md-4 col-12 price-detail-heading">
                <p>
                  {addproductTranslation?.materiallabel
                    ? addproductTranslation?.materiallabel
                    : "Materials Used:"}{" "}
                </p>
              </div>
              <div className="col-md-8 col-12 pl-4">
                {(() => {
                  if (singleProduct?.material?.length > 0) {
                    return singleProduct.material.map((material, index) => {
                      return (
                        <div className="mb-3" key={index}>
                          {index + 1}: &nbsp;
                          <span className="material-cost-text">
                            {materialList[userLanguage][material.name]}
                          </span>
                          &nbsp;
                          <span>{material.ranges}% &nbsp;</span>
                          <br />
                        </div>
                      );
                    });
                  } else {
                    return productDetailTranslation?.undefineline
                      ? productDetailTranslation.undefineline
                      : "Data is not available for this product";
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer autoClose={20000} />
      <div className="app" style={{ backgroundColor: "white" }}>
        <div>
          <AppHeader fixed>
            <DefaultHeader />
          </AppHeader>
        </div>
        <div
          style={{ marginTop: 75 }}
          className="pt-5 w-100 d-flex flex-column align-items-center"
        >
          {customLoader || loading ? (
            <div style={{ textAlign: "center", margin: "200px" }}>
              <i
                className="fas fa-spinner fa-pulse fa-2x"
                style={{ color: "green" }}
              ></i>
            </div>
          ) : (
            <>
              <div
                className="mt-5 d-flex flex-wrap column-gap justify-content-center w-100"
                style={{ maxWidth: 1366 }}
              >
                <div className="d-none d-sm-block mb-3">
                  <CarouselImageMagnify images={singleProduct?.images} />
                </div>
                <div className="d-sm-none mb-5">
                  {singleProduct?.images && (
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: "Product image",
                          width: 340,
                          height: 340,
                          src: singleProduct.images[0],
                        },
                        largeImage: {
                          src: singleProduct.images[0],
                          width: 600,
                          height: 600,
                        },
                        isHintEnabled: true,
                        enlargedImagePosition: "over",
                        shouldHideHintAfterFirstActivation: false,
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    width: 500,
                    minHeight: 480,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="product-content">
                    {ChatGPTTranslated?.[0] ? (
                      <div className="product-title">
                        {ChatGPTTranslated[0]?.name}
                      </div>
                    ) : (
                      <div className="product-title">{singleProduct?.name}</div>
                    )}
                    {ChatGPTTranslated?.[0] ? (
                      <div className="product-brand">
                        {ChatGPTTranslated[0]?.brand}
                      </div>
                    ) : (
                      <div className="product-brand">
                        {singleProduct?.brand}
                      </div>
                    )}
                    <div id="comment-div">
                      {ChatGPTTranslated?.[0] ? (
                        <div className="product-brand">
                          {ChatGPTTranslated[0]?.discription}
                        </div>
                      ) : (
                        singleProduct &&
                        singleProduct.specification && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: convertCommentFromJSONToHTML(
                                singleProduct.specification
                              ),
                            }}
                          />
                        )
                      )}
                    </div>

                    {singleProduct && singleProduct?.attributes?.length > 0
                      ? singleProduct?.attributes?.map((attributes, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              marginBottom: 10,
                              alignItems: "baseline",
                            }}
                          >
                            <div className="attribute-text">
                              {ChatGPTTranslated?.[0]?.attribute
                                ? ChatGPTTranslated?.[0]?.attribute
                                : attributes.name}
                              :
                              {attributes.variants.map((item) => (
                                <b>{item}</b>
                              ))}
                            </div>
                          </div>
                        ))
                      : ""}
                    {!singleProduct?.isResidency && (
                      <div className="d-flex align-items-center my-2">
                        <div>
                          {singleProduct?.eventCategory !== "event" && (
                            <div className="attribute-text-qantity">
                              {productDetailTranslation?.quantity
                                ? productDetailTranslation?.quantity
                                : "Quantity"}
                              :
                            </div>
                          )}
                        </div>
                        <div className="d-flex align-items-center">
                          {singleProduct?.eventCategory !== "event" && (
                            <FaMinus
                              onClick={decrementCounter}
                              className="quantity-minus"
                              size={20}
                            />
                          )}
                          {singleProduct?.eventCategory !== "event" && (
                            <div className="quanttity-count" id="counter">
                              {qty}
                            </div>
                          )}
                          {singleProduct?.eventCategory !== "event" && (
                            <IoMdAdd
                              onClick={incrementCounter}
                              size={20}
                              className="quantity-minus"
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {singleProduct?.eventCategory !== "event" && (
                      <div
                        hidden={false}
                        className={
                          singleProduct?.stockQuantity > 0
                            ? "availability-tag"
                            : "unavailability-tag"
                        }
                      >
                        <b>
                          {singleProduct?.stockQuantity > 0
                            ? `${
                                productDetailTranslation?.inStock
                                  ? productDetailTranslation?.inStock
                                  : "In stock"
                              }`
                            : `${
                                productDetailTranslation?.outStock
                                  ? productDetailTranslation?.outStock
                                  : "Out of Stock"
                              }`}
                        </b>
                      </div>
                    )}
                  </div>
                  {singleProduct?.isResidency && (
                    <DateRangePicker
                      setStartDate={setResidentStartDate}
                      setEndDate={setResidentEndDate}
                      validStartDate={singleProduct?.startDate}
                      validEndDate={singleProduct?.endDate}
                    />
                  )}
                  <div className="pricing-section">
                    {singleProduct?.dynamicPrice?.currentPrice !== 0 && (
                      <div
                        style={{
                          display: "flex",
                          marginBottom: 10,
                          alignItems: "baseline",
                          flexWrap: "wrap",
                        }}
                      >
                        <div className="attribute-text">
                          {productDetailTranslation?.priceRating
                            ? productDetailTranslation?.priceRating
                            : "Price rating"}
                          :
                        </div>
                        <div>
                          <RatingWidget
                            product={singleProduct}
                            rating={getStarRating()}
                          />
                        </div>
                        <div className="seperator">|</div>
                        <div
                          className="what-is-price-rate"
                          onClick={() => priceModal.current.show()}
                        >
                          <u>
                            {shopTranslation?.popUpHeading
                              ? shopTranslation?.popUpHeading
                              : ShopContent?.popUpHeading}
                            {/* What is price rate? */}
                          </u>
                        </div>
                      </div>
                    )}
                    {singleProduct?.eventCategory !== "event" && (
                      <div className="attribute-text">
                        {singleProduct?.isResidency
                          ? `${
                              productDetailTranslation?.suggestValue
                                ? productDetailTranslation?.suggestValue
                                : "Suggest a daily rate(USD)"
                            } :`
                          : `${
                              productDetailTranslation?.suggestValue
                                ? productDetailTranslation?.suggestValue
                                : "Suggest Value(USD)"
                            } :`}
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        marginBottom: 8,
                        justifyContent: "space-in-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          minWidth: 205,
                          marginBottom: 10,
                        }}
                      >
                        <input
                          autoFocus
                          className={
                            !suggestedPrice
                              ? "price-input"
                              : "price-input-accepted"
                          }
                          type="number"
                          min={0}
                          placeholder={
                            productDetailTranslation?.placeHolderUSD
                              ? productDetailTranslation?.placeHolderUSD +
                                " USD"
                              : "Offer in USD"
                          }
                          onChange={(e) => setSuggestedPrice(e.target.value)}
                        />
                      </div>

                      <div
                        style={{ width: "50%", display: "flex", minWidth: 205 }}
                      >
                        <button
                          className="buy-now"
                          style={{
                            backgroundColor:
                              singleProduct?.stockQuantity <= 0&& !(singleProduct.hasOwnProperty("eventCategory"))
                                ? "white"
                                : "black",
                            color:
                              singleProduct?.stockQuantity <= 0&& !(singleProduct.hasOwnProperty("eventCategory"))
                                ? "black"
                                : "white",
                            cursor:
                              singleProduct?.stockQuantity <= 0 && !(singleProduct.hasOwnProperty("eventCategory"))
                                ? "not-allowed"
                                : "pointer",
                          }}
                          onClick={() => checkSuggestedPrice("shipping")}
                          disabled={singleProduct?.stockQuantity <= 0 && !(singleProduct.hasOwnProperty("eventCategory"))}
                        >
                          {singleProduct?.isResidency
                            ? `${
                                productDetailTranslation?.rentbutton
                                  ? productDetailTranslation?.rentbutton
                                  : "Rent Now"
                              }`
                            : singleProduct?.eventCategory !== "event"
                            ? `${
                                productDetailTranslation?.buyButton
                                  ? productDetailTranslation?.buyButton
                                  : "Buy Now"
                              }`
                            : productDetailTranslation?.instructedParaLine
                            ? productDetailTranslation?.instructedParaLine
                            : "Buy Event Ticket"}
                        </button>

                        {!singleProduct?.isResidency && (
                          <button
                          disabled={singleProduct?.stockQuantity <= 0 && !(singleProduct.hasOwnProperty("eventCategory"))}
                            className="add-to-cart"
                            onClick={() => checkSuggestedPrice("addToCart")}
                          />
                        )}
                      </div>
                    </div>

                    <div className="pwiw-input-helper-text">
                      {loading
                        ? "Evaluating offer"
                        : singleProduct?.hasPriceError ||
                          !singleProduct?.hasMadeOffer
                        ? `${
                            productDetailTranslation?.instructedParaLine
                              ? productDetailTranslation?.instructedParaLine
                              : "Tell us how much you would value this product"
                          }`
                        : singleProduct?.isResidency
                        ? "You can rent this property now"
                        : "You can purchase this now"}
                      {singleProduct?.carbonEmission !== undefined && (
                        <p style={{ fontSize: "14px", paddingTop: "20px" }}>
                          {productDetailTranslation?.carbonEmission
                            ? productDetailTranslation?.carbonEmission
                            : "Carbon Emission "}
                          {singleProduct.carbonEmission.toFixed(3)} /kg
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <Row style={{ marginTop: 10, height: 20 }}>
                      <Label
                        className="error-text"
                        hidden={!singleProduct?.hasPriceError}
                      >
                        <div className="error-icon" />
                        {singleProduct?.suggestedPriceError === "LOW"
                          ? "Suggested offer cannot be accepted"
                          : singleProduct?.suggestedPriceError === "HIGH"
                          ? "Suggested price overvalued the product, try another price"
                          : "Offer accepted!"}
                      </Label>
                      <Label
                        className="accepted-text"
                        hidden={
                          singleProduct?.hasPriceError ||
                          !singleProduct?.hasMadeOffer
                        }
                      >
                        Offer accpted!
                      </Label>
                    </Row>
                  </div>
                </div>
              </div>
              <div style={{ alignItems: "start" }}>
                <h2>
                  {productDetailTranslation?.featuredProduct
                    ? productDetailTranslation?.featuredProduct
                    : productDetailContent?.featuredProduct}
                  {/* Featured Products */}
                </h2>
              </div>
              <Row className="mx-3 mb-5">
                <Col xs="12 d-flex justify-content-center pl-4">
                  <div style={{ display: "inline-flex", flexWrap: "wrap" }}>
                    {features?.map((item, index) => {
                      return (
                        <div className="product-preview-container">
                          <div className="product-preview-container-image-wrapper">
                            <img
                              className="product-preview-container-image"
                              src={item.images[0]}
                              alt="Avatar"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              height: "26.45%",
                            }}
                          >
                            <div style={{ width: "75%" }}>
                              <div className="product-preview-name-text">
                                {item.title}
                              </div>
                              <div className="product-preview-brand-text">
                                {item.companyName}
                              </div>
                              <div style={{ marginLeft: 10 }}></div>
                            </div>
                            <a
                              type="button"
                              onClick={() => {
                                viewProduct(item, user);
                              }}
                              className="product-preview-add-button"
                            >
                              <img style={{ width: "100%" }} src={plusButton} />
                            </a>
                          </div>
                        </div>
                      );
                    })}
                    {ads?.length <= 0 && (
                      <div style={{ marginTop: "80px" }}>
                        <h5>
                          {productDetailTranslation?.featureMessage
                            ? productDetailTranslation?.featureMessage
                            : "We didn't have any featured items."}
                        </h5>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              <RelatedProducts products={filterProduct} />
              <div
                style={{
                  marginTop: 60,
                  width: "100%",
                  maxWidth: 1130,
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="grey-container details-container-padding"
                  style={{ maxWidth: 650, minWidth: 320, width: "70%" }}
                >
                  <div className="product-details-title">
                    {productDetailTranslation?.productDetail
                      ? productDetailTranslation?.productDetail
                      : productDetailContent?.productDetail}
                    {/* Product Details */}
                  </div>
                  <div className="product-detail-row">
                    <div className="attribute-text">
                      {productDetailTranslation?.productId
                        ? productDetailTranslation?.productId
                        : productDetailContent?.productId}
                      {/* Product Id: */}
                    </div>
                    <div>{singleProduct?.displayId}</div>
                  </div>
                  <div className="product-detail-row">
                    <div className="attribute-text">
                      {productDetailTranslation?.productCategory
                        ? productDetailTranslation?.productCategory
                        : productDetailContent?.productCategory}
                      {/* Category: */}
                    </div>
                    <div>{singleProduct?.category?.[0]?.name}</div>
                  </div>
                  <div className="product-detail-row">
                    <div className="attribute-text">
                      {productDetailTranslation?.productDiscription
                        ? productDetailTranslation?.productDiscription
                        : productDetailContent?.productDiscription}
                      {/* Description: */}
                    </div>
                    <div>
                      {singleProduct && singleProduct?.specification && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: convertCommentFromJSONToHTML(
                              singleProduct?.specification
                            ),
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {singleProduct?.sellerId !== undefined ? (
                  <div
                    className="grey-container ml-1"
                    style={{
                      width: "40%",
                      minWidth: 320,
                      padding: "30px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        marginBottom: 40,
                      }}
                    >
                      <div style={{ width: 150 }}>
                        <div className="seller-profile-pic">
                          <img
                            src={
                              (singleProduct?.sellerId &&
                                singleProduct?.sellerId.profileAvatar) ||
                              avatarPlaceholder
                            }
                            style={{
                              width: 100,
                              height: 100,
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      </div>
                      <div className="seller-name-title">
                        <div>
                          {singleProduct?.sellerId &&
                            (singleProduct?.sellerId.firstname || "") +
                              " " +
                              (singleProduct?.sellerId.lastname || "")}
                        </div>
                        <Button
                          size="md"
                          color="success"
                          className="view-seller-profile"
                          onClick={() => history.push(`/sellerdetail`)}
                        >
                          <b>{translations["sellerStore"][userLanguage]}</b>
                        </Button>
                      </div>
                    </div>
                    <div>
                      <div className="seller-profile-text-row">
                        <div className="attribute-text-seller">
                          {productDetailTranslation?.sellerCountry
                            ? productDetailTranslation?.sellerCountry
                            : productDetailContent?.sellerCountry}
                          {/* Country :  */}
                        </div>
                        <span>
                          {singleProduct?.sellerId &&
                            singleProduct?.sellerId.country}
                        </span>
                      </div>
                      <div className="seller-profile-text-row flex-wrap">
                        <div className="attribute-text-seller">
                          {productDetailTranslation?.sellerId
                            ? productDetailTranslation?.sellerId
                            : productDetailContent?.sellerId}
                          {/* Yinn Seller ID : */}
                        </div>
                        <span> {singleProduct?.sellerId?._id} </span>
                      </div>
                      <div className="seller-profile-text-row">
                        <div className="attribute-text-seller">
                          {productDetailTranslation?.sellerMemberShip
                            ? productDetailTranslation?.sellerMemberShip
                            : productDetailContent?.sellerMemberShip}
                          {/* Yinn Member Since : */}
                        </div>
                        <span>
                          {singleProduct?.sellerId &&
                            new Date(
                              singleProduct?.sellerId.createdAt
                            ).toDateString()}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  "Seller details unavailable."
                )}
              </div>
            </>
          )}
        </div>

        <SkyLight
          hideOnOverlayClicked
          ref={priceModal}
          title={
            shopTranslation?.popUpHeading
              ? shopTranslation?.popUpHeading
              : ShopContent?.popUpHeading
          }
        >
          {shopTranslation?.popUp ? shopTranslation?.popUp : ShopContent?.popUp}
        </SkyLight>

        <div>
          <DefaultFooter />
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
