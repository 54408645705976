import React from 'react';
import "./PriceDialog.css";

const PriceDialog = ({ isOpen, onClose, newPrice, language, count }) => {
  const translations = {
    en: {
      title: "Price Suggestion",
      message: `Make your offer above $ ${newPrice}`,
      overlayTitle: "Tell us how much you would value this product",
      overlayMessage: "You are close to valuing this product, try again",
      closeBtn: "Close",
    },
    es: {
      title: "Sugerencia de precio",
      message: `Haga su oferta por encima de $ ${newPrice}`,
      overlayTitle: "Díganos cuánto valoraría este producto",
      overlayMessage: "Está cerca de valorar este producto, intente de nuevo",
      closeBtn: "Cerrar",
    },
    fr: {
      title: "Suggestion de prix",
      message: `Faites votre offre au-dessus de $ ${newPrice}`,
      overlayTitle: "Dites-nous combien vous évaluez ce produit",
      overlayMessage: "Vous êtes proche de valoriser ce produit, essayez à nouveau",
      closeBtn: "Fermer",
    },
    zh: {
      title: "价格建议",
      message: `提出高于 $ ${newPrice} 的报价`,
      overlayTitle: "告诉我们您对该产品的估价",
      overlayMessage: "您接近估值该产品，请重试",
      closeBtn: "关闭",
    },
    pt: {
      title: "Sugestão de preço",
      message: `Faça sua oferta acima de $ ${newPrice}`,
      overlayTitle: "Diga-nos quanto você valorizaria este produto",
      overlayMessage: "Você está perto de valorizar este produto, tente novamente",
      closeBtn: "Fechar",
    },
    ar: {
      title: "اقتراح السعر",
      message: `قدم عرضك أعلى من $ ${newPrice}`,
      overlayTitle: "أخبرنا كم تقدر قيمة هذا المنتج",
      overlayMessage: "أنت قريب من تقدير قيمة هذا المنتج، حاول مرة أخرى",
      closeBtn: "إغلاق",
    }
  };

  const currentTranslations = translations[language] || translations['en'];

  if (!isOpen) return null;




  return (
    count === 2 ? (
      <div className="price-dialog-overlay">
        <div className="price-dialog-container">
          <div className="price-dialog-content">
            <h2 className="price-dialog-title">{currentTranslations.title}</h2>
            <p className="price-dialog-message">
              {currentTranslations.message}
            </p>
            <button 
              className="price-dialog-close-btn" 
              onClick={onClose}
            >
              {currentTranslations.closeBtn}
            </button>
          </div>
        </div>
      </div>
    ) : (
      <div className="price-dialog-overlay">
        <div className="price-dialog-container">
          <div className="price-dialog-content">
            <h2 className="price-dialog-title">{currentTranslations.overlayTitle}</h2>
            <p className="price-dialog-message">
              {currentTranslations.overlayMessage}
            </p>
            <button 
              className="price-dialog-close-btn" 
              onClick={onClose}
            >
              {currentTranslations.closeBtn}
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default PriceDialog;
