import React, { useEffect, useState } from "react";
import "./TranslationField.css";
import { useDispatch, useSelector } from "react-redux";
import { TranslationContent } from "../../../redux/reducers/admin";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { getAllWebContent } from "../../../redux/reducers/user";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";


const HomeFeatureFeild = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    dispatch(getAllWebContent());
  }, []);

  const { allWebContent } = useSelector((state) => state?.user);

  const homeFeatureData = allWebContent?.[0]?.homeFeature;

  //   let contant = allWebContent[0]?.footer?.contant;
  //   let contactUs = allWebContent[0]?.footer?.contactUs;
  //   let copyright = allWebContent[0]?.footer?.copyright;
  //   let shopAt = allWebContent[0]?.footer?.shopAt;
  const [diable, setDiable] = useState(true);

  const [formData, setFormData] = useState({
    page: "homeFeature",
    isChange: false,
    headingOne: homeFeatureData?.headingOne || "",
    paraLineOne: homeFeatureData?.paraLineOne || "",
    paraLineTwo: homeFeatureData?.paraLineTwo || "",
    paraLineThree: homeFeatureData?.paraLineThree || "",
    featureHeadingOne: homeFeatureData?.featureHeadingOne || "",
    featureParaOne: homeFeatureData?.featureParaOne || "",
    //  linkOne: homeFeatureData?.linkOne || "",
    featureHeadingTwo: homeFeatureData?.featureHeadingTwo || "",
    featureParaTwo: homeFeatureData?.featureParaTwo || "",
    //  linkTwo: homeFeatureData?.linkTwo || "",
    featureHeadingThree: homeFeatureData?.featureHeadingThree || "",
    featureParaThree: homeFeatureData?.featureParaThree || "",
    //  linkThree: homeFeatureData?.linkThree || "",
    featureHeadingFour: homeFeatureData?.featureHeadingFour || "",
    featureParaFour: homeFeatureData?.featureParaFour || "",
    //  linkFour: homeFeatureData?.linkFour || "",
    featured: homeFeatureData?.featured || "",
    //  buttonFeatured: homeFeatureData?.buttonFeatured || "",
    LastLine: homeFeatureData?.LastLine || "",
    LastPara: homeFeatureData?.LastPara || "",
    //  LastButton: homeFeatureData?.LastButton || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDiable(false);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(TranslationContent(formData));
    setDiable(true);
  };

  useEffect(() => {
    setFormData({ ...formData, ...homeFeatureData, isChange: false });
  }, [homeFeatureData]);

  return (
    <div className="container">
      <ToastContainer autoClose={20000} />
      <div className="tf-top mx-5 mt-2 mb-2">
        
          <button className="back-button" onClick={history.goBack}>
            &lt;</button>

            <h1 className="mt-3" s  style={{
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
  }}>
  Home Feature Page
</h1>

      </div>
      <form onSubmit={handleSubmit} className="mx-5">
        <div className="form-group">
          <label>How Yinn Work</label>
          <input
            type="text"
            name="headingOne"
            value={formData.headingOne}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Paragraph Line One:</label>
          <input
            type="text"
            name="paraLineOne"
            value={formData.paraLineOne}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Paragraph Line Two:</label>
          <input
            type="text"
            name="paraLineTwo"
            value={formData.paraLineTwo}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Paragraph Line Three:</label>
          <input
            type="text"
            name="paraLineThree"
            value={formData.paraLineThree}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Curated Marketplace HeadingOne:</label>
          <input
            type="text"
            name="featureHeadingOne"
            value={formData.featureHeadingOne}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Paragraph One:</label>
          <input
            type="text"
            name="featureParaOne"
            value={formData.featureParaOne}
            onChange={handleInputChange}
          />
        </div>

        {/* <div className="form-group">
          <label>Link One:</label>
          <input
            type="text"
            name="linkOne"
            value={formData.linkOne}
            onChange={handleInputChange}
          />
        </div> */}

        <div className="form-group">
          <label>Content Streaming HeadingTwo:</label>
          <input
            type="text"
            name="featureHeadingTwo"
            value={formData.featureHeadingTwo}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Paragraph Two:</label>
          <input
            type="text"
            name="featureParaTwo"
            value={formData.featureParaTwo}
            onChange={handleInputChange}
          />
        </div>

        {/* <div className="form-group">
          <label>Link Two:</label>
          <input
            type="text"
            name="linkTwo"
            value={formData.linkTwo}
            onChange={handleInputChange}
          />
        </div> */}

        <div className="form-group">
          <label>Financial Services HeadingThree:</label>
          <input
            type="text"
            name="featureHeadingThree"
            value={formData.featureHeadingThree}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Paragraph Three:</label>
          <input
            type="text"
            name="featureParaThree"
            value={formData.featureParaThree}
            onChange={handleInputChange}
          />
        </div>

        {/* <div className="form-group">
          <label>Link Three:</label>
          <input
            type="text"
            name="linkThree"
            value={formData.linkThree}
            onChange={handleInputChange}
          />
        </div> */}

        <div className="form-group">
          <label>Residency Swap Heading Four:</label>
          <input
            type="text"
            name="featureHeadingFour"
            value={formData.featureHeadingFour}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Paragraph Four:</label>
          <input
            type="text"
            name="featureParaFour"
            value={formData.featureParaFour}
            onChange={handleInputChange}
          />
        </div>

        {/* <div className="form-group">
          <label>Link Four:</label>
          <input
            type="text"
            name="linkFour"
            value={formData.linkFour}
            onChange={handleInputChange}
          />
        </div> */}

        <div className="form-group">
          <label>Featured in our platform:</label>
          <input
            type="text"
            name="featured"
            value={formData.featured}
            onChange={handleInputChange}
          />
        </div>

        {/* <div className="form-group">
          <label>Button Featured :</label>
          <input
            type="text"
            name="buttonFeatured"
            value={formData.buttonFeatured}
            onChange={handleInputChange}
          />
        </div> */}

        <div className="form-group">
          <label>Selling online :</label>
          <input
            type="text"
            name="LastLine"
            value={formData.LastLine}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Selling online paragraph:</label>
          <input
            type="text"
            name="LastPara"
            value={formData.LastPara}
            onChange={handleInputChange}
          />
        </div>

        {/* <div className="form-group">
          <label>Create Free Account:</label>
          <input
            type="text"
            name="LastButton"
            value={formData.LastButton}
            onChange={handleInputChange}
          />
        </div> */}
        <div className="form-group">
          <button type="submit" disabled={diable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default HomeFeatureFeild;
