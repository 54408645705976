import React, { useEffect, useState } from "react";
import "./TranslationField.css";
import { useDispatch, useSelector } from "react-redux";
import { TranslationContent } from "../../../redux/reducers/admin";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { toast, ToastContainer } from "react-toastify";
import { getAllWebContent } from "../../../redux/reducers/user";
import { useHistory } from "react-router-dom";


const ContactUsFeild = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllWebContent());
  }, []);

  const { allWebContent } = useSelector((state) => state?.user);

  const contactData = allWebContent?.[0]?.contectUs;

  const [diable, setDiable] = useState(true);

  const [formData, setFormData] = useState({
    page: "contactUs",
    isChange: false,
    topHeading: contactData?.topHeading || "",
    lineOne: contactData?.lineOne || "",
    lineTwo: contactData?.lineTwo || "",
    email: contactData?.email || "",
    number: contactData?.number || "",
    message: contactData?.message || "",
    // button: contactData?.button || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDiable(false);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(TranslationContent(formData));
    console.log(
      "🚀 ~ file: contactUsContent.js:47 ~ handleSubmit ~ formData:",
      formData
    );
    setDiable(true);
  };

  useEffect(() => {
    setFormData({ ...formData, ...contactData, isChange: false });
  }, [contactData]);



  return (
    <div className="container">
      <ToastContainer autoClose={20000} />
      <div className="tf-top mx-5 mt-2 mb-2">
        
        <button className="back-button" onClick={history.goBack}>
          &lt;</button>

          <h1 className="mt-3" s  style={{
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'absolute',
}}>
Contact Us Page
</h1>

    </div>
      <form onSubmit={handleSubmit} className="mx-5">
        <div className="form-group">
          <label>Contact Us Heading:</label>
          <input
            type="text"
            name="topHeading"
            value={formData.topHeading}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Contact Us Paragraph :</label>
          <input
            type="text"
            name="lineOne"
            value={formData.lineOne}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>Contact Us Paragraph Two:</label>
          <input
            type="text"
            name="lineTwo"
            value={formData.lineTwo}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Email :</label>
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Number :</label>
          <input
            type="text"
            name="number"
            value={formData.number}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Message :</label>
          <input
            type="text"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
          />
        </div>

        {/* <div className="form-group">
          <label>Button Text:</label>
          <input
            type="text"
            name="button"
            value={formData.button}
            onChange={handleInputChange}
            />
        </div> */}
        <div className="form-group">
          <button type="submit" disabled={diable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactUsFeild;
