import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { AppHeaderDropdown } from '@coreui/react';
import { useHistory } from 'react-router-dom'; 
import { 
  IoMdArrowDropdown, 
  IoMdGlobe, 
  IoMdCash, 
  IoMdPerson, 
  IoMdMenu, 
  IoMdClose, 
  IoMdHome,
  IoMdInformationCircleOutline,
  IoMdCalendar,
  IoMdMail,
  IoMdHelp,
  IoMdTrendingUp,
  IoMdPaper,
  IoMdPeople,
  IoMdSearch,

} from 'react-icons/io';

import { DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { SetState as AuthSetState, getExhangeRate, logout } from "../../../redux/reducers/auth";
import { getAllWebContent } from "../../../redux/reducers/user";

import logo from "../../../assets/img/brand/logo.png";
import "flag-icon-css/css/flag-icon.css";
import './PFHeader.css';


const Header = ({ content }) => {
  const { userLanguage, userCurrency, localBankTransferAccountDetails } = useSelector(state => state.auth);
  const { translationState, allWebContent } = useSelector(state => state.user);

  const renderFlagIcon = (code) => {
    return <span className={`flag-icon flag-icon-${code.toLowerCase()}`} />;
  };

  const webContent = allWebContent?.[0]?.header;
  const orignalTranslation = translationState?.[0]?.translations?.header?.[userLanguage];

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const logoRef = useRef(null);

  const event = undefined;

  const history = useHistory(); 


  const currencyFlags = [
    { AUD: "au" }, { BGN: "bg" }, { BRL: "br" }, { CAD: "ca" }, 
    { CHF: "ch" }, { CNY: "cn" }, { CZK: "cz" }, { DKK: "dk" }, 
    { EUR: "eu" }, { GBP: "gb" }, { HKD: "hk" }, { HRK: "hr" }, 
    { HUF: "hu" }, { IDR: "id" }, { ILS: "il" }, { INR: "in" }, 
    { ISK: "is" }, { JPY: "jp" }, { KRW: "kr" }, { MXN: "mx" }, 
    { MYR: "my" }, { NOK: "no" }, { NZD: "nz" }, { PHP: "ph" }, 
    { PLN: "pl" }, { RON: "ro" }, { RUB: "ru" }, { SEK: "se" }, 
    { SGD: "sg" }, { THB: "th" }, { TRY: "tr" }, { USD: "us" }, 
    { ZAR: "za" }
  ];

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(AuthSetState({ field: "userLanguage", value: lng }));
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        logoRef.current &&
        !logoRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const currencyDropDown = currencyFlags.map((currencyObj, index) => (
    <DropdownItem
      key={index}
      onClick={() => {
        if (!localBankTransferAccountDetails) {
          dispatch(
            AuthSetState({
              field: "userCurrency",
              value: Object.keys(currencyObj)[0],
            })
          );
          dispatch(getExhangeRate(Object.keys(currencyObj)[0]));
        }
      }}
    >
      <div className="currency-dropdown-item">
        {Object.keys(currencyObj)[0]}
        {renderFlagIcon(Object.values(currencyObj)[0])}
      </div>
    </DropdownItem>
  ));

  const handleLogout = () => {
    dispatch(logout());
    history.push("/personal-finance");
  };

  const LanguageSelector = () => (
    <AppHeaderDropdown direction="down">
      <DropdownToggle
        className="lang-menu"
        style={{ backgroundColor: 'transparent', border: 'none' }}
      >
        <div className="language-selector">
          <IoMdGlobe size={20} />
          <span className='mob-drop'>{userLanguage}</span>
          <IoMdArrowDropdown size={20} />
        </div>
      </DropdownToggle>
      <DropdownMenu className="language-dropdown">
        <DropdownItem onClick={() => changeLanguage("ar")}>
          <span className="flag-icon flag-icon-sa" /> Arabic
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("zh")}>
          <span className="flag-icon flag-icon-cn" /> Chinese
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("en")}>
          <span className="flag-icon flag-icon-us" /> English
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("fr")}>
          <span className="flag-icon flag-icon-fr" /> French
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("pt")}>
          <span className="flag-icon flag-icon-br" /> Portuguese
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("es")}>
          <span className="flag-icon flag-icon-es" /> Spanish
        </DropdownItem>
      </DropdownMenu>
    </AppHeaderDropdown>
  );
   
  const CurrencySelector = () => (
    <AppHeaderDropdown direction="down">
      <DropdownToggle 
        className="lang-menu"
        style={{ backgroundColor: 'transparent', border: 'none' }}
      >
        <div className="language-selector">
          <IoMdCash size={20} />
          <span className='mob-drop'>{userCurrency}</span>
          <IoMdArrowDropdown size={20} />
        </div>
      </DropdownToggle>
      <DropdownMenu className="language-dropdown">
        {currencyDropDown}
      </DropdownMenu>
    </AppHeaderDropdown>
  );

  const UserSelector = () => {
    const dispatch = useDispatch();
    const { firstname } = useSelector((state) => state.auth.user || {});

  
    return (
      <AppHeaderDropdown direction="down">
        <DropdownToggle
          className="lang-menu"
          style={{ backgroundColor: 'transparent', border: 'none' }}
        >
          <div className="language-selector">
            <IoMdPerson size={20} />
            <span
              className="mob-drop"
              onClick={() => {
                if (!firstname) {
                  history.push({
                    pathname: '/login',
                    state: { event }
                  }); // Navigate to /login
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              {firstname ? `${firstname}` : 'Login'}
            </span>
            <IoMdArrowDropdown size={20} />
          </div>
        </DropdownToggle>
        {firstname ? (
          <DropdownMenu className="language-dropdown">
<DropdownItem
  onClick={handleLogout}
  className="user-dropdown-wrapper"
>
  Logout
</DropdownItem>;
          </DropdownMenu>
        ) : null}
      </AppHeaderDropdown>
    );
  };
  
  
  return (
    <div className="pf-header-container">
      <div className="pf-header-top" ref={logoRef}>
        <div className="logo-section">
        <Link to="/">
      <img
        src={logo}
        alt="Money Coaches Canada"
        className="logo-image"
        loading="lazy"
      />
    </Link>
        </div>

        <div className="nav-section">
          <div className="header-controls">

            <div className='drop-downs'>
            <LanguageSelector />
            <CurrencySelector/>
            <UserSelector/>
            </div>

            <div 
              className="menu-button" 
              onClick={toggleMenu}
              aria-label={isMenuOpen ? "Close Menu" : "Open Menu"}
            >
              {isMenuOpen ? <IoMdClose size={24} /> : <IoMdMenu size={24} />}
            </div>
          </div>
        </div>
      </div>

      <div 
        className={`mobile-menu ${isMenuOpen ? 'active' : ''}`} 
        ref={menuRef}
        aria-hidden={!isMenuOpen}
      >
        <div 
          className="close-menu-button" 
          onClick={toggleMenu}
          aria-label="Close Menu"
        >
          <IoMdClose size={24} />
        </div>

        <div className="menu-container">
        

<nav className="menu-items">
  <Link to="/personal-finance">
    <IoMdHome /> {orignalTranslation?.pFinance || webContent?.pFinance}
  </Link>
  <Link to="/about">
    <IoMdInformationCircleOutline /> {orignalTranslation?.pFinanceAbout || webContent?.pFinanceAbout}
  </Link>
  <Link to="/courses-and-events">
    <IoMdCalendar /> {orignalTranslation?.pFinanceCandE || webContent?.pFinanceCandE}
  </Link>
  <Link to="/contact-us">
    <IoMdMail /> {orignalTranslation?.pFinanceContact || webContent?.pFinanceContact}
  </Link>
  <Link to="/faq">
    <IoMdHelp /> {orignalTranslation?.pFinanceFAQ || webContent?.pFinanceFAQ}
  </Link>
  <Link to="/financeplanning">
    <IoMdTrendingUp /> {orignalTranslation?.pFinanceFP || webContent?.pFinanceFP}
  </Link>
  <Link to="/media">
    <IoMdPaper /> {orignalTranslation?.pFinanceMedia || webContent?.pFinanceMedia}
  </Link>
  <Link to="/new-immigrants-credits-and-finance">
    <IoMdPeople size={24} /> 
    <p style={{ width: "300px" }}>{orignalTranslation?.pFinanceNICAF || webContent?.pFinanceNICAF}</p>
  </Link>
  <Link to="/rebuild-credit-scores">
    <IoMdTrendingUp /> {orignalTranslation?.pFinanceRCC || webContent?.pFinanceRCC}
  </Link>
  <Link to="/testimonials">
    <IoMdPaper /> {orignalTranslation?.pFinanceT || webContent?.pFinanceT}
  </Link>
  <Link to="/search">
    <IoMdSearch /> {orignalTranslation?.shop || webContent?.shop}
  </Link>
  <Link to="/my-courses">
    <IoMdCalendar /> {orignalTranslation?.pFinanceMyCourses || webContent?.pFinanceMyCourses}
  </Link>
</nav>

        </div>
      </div>
    </div>
  );
};

export default Header;