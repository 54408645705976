import React, { useEffect, useRef } from 'react';
import './FinancialCoachingProcess.css';

const FinancialCoachingProcess = ({ content }) => {
  const processRef = useRef(null);
  const steps = [
    {
      title: content.step1Heading || "Initial Consultation",
      icon: "🤝",
      description: content.step1Text || "Begin your financial journey with a personalized consultation",
      className: "process-primary"
    },
    {
      title: content.step2Heading || "Financial Assessment",
      icon: "📊",
      description: content.step2Text || "Comprehensive analysis of your current financial situation",
      className: "process-secondary"
    },
    {
      title: content.step3Heading || "Strategy Development",
      icon: "📝",
      description: content.step3Text || "Create your customized financial roadmap",
      className: "process-tertiary"
    },
    {
      title: content.step4Heading || "Implementation",
      icon: "🎯",
      description: content.step4Text || "Put your financial plan into action",
      className: "process-quaternary"
    },
    {
      title: content.step5Heading || "Ongoing Support",
      icon: "🌟",
      description: content.step5Text || "Regular check-ins and plan adjustments",
      className: "process-quinary"
    },
    {
      title: content.step6Heading ,
      icon: "🔄",
      description: content.step6Text,
      className: "process-senary"  // "senary" means sixth in Latin, a logical next class name
    }
  ];
  

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      {
        threshold: 0.1
      }
    );

    const steps = document.querySelectorAll('.process-step');
    steps.forEach((step) => observer.observe(step));

    return () => {
      steps.forEach((step) => observer.unobserve(step));
    };
  }, []);

  const handleStepClick = (index) => {
    const steps = document.querySelectorAll('.process-step');
    steps.forEach((step) => step.classList.remove('active'));
    steps[index].classList.add('active');
  };

  return (
    <section className="coaching-process" ref={processRef}>
      <div className="coaching-container">
        <h2 className="coaching-title">{content.stepsTitle}</h2>
        <div className="coaching-subtitle">{content.stepsSubTitle}</div>
        
        <div className="steps-grid">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`process-step ${step.className}`}
              onClick={() => handleStepClick(index)}
              role="button"
              tabIndex={0}
            >
              <div className="step-content">
                <div className="icon-wrapper">
                  <span className="step-icon-symbol">{step.icon}</span>
                </div>
                <h3 className="step-heading">{step.title}</h3>
                <p className="step-details">{step.description}</p>
                <div className="step-index">{index + 1}</div>
              </div>
              <div className="progress-track">
                <div className="progress-fill"></div>
              </div>
            </div>
          ))}
        </div>

        <div className="coaching-footer">
          <button className="coaching-cta-btn">
            {content.stepsButton}
          </button>
        </div>
      </div>
    </section>
  );
};

export default FinancialCoachingProcess;
