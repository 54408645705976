import { AppHeaderDropdown } from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdArrowDropdown, IoMdPerson } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { SetState as AuthSetState, logout } from "../../../redux/reducers/auth";
import { Link, useHistory } from 'react-router-dom';
import { 
  IoMdHome,
  IoMdInformationCircleOutline,
  IoMdCalendar,
  IoMdMail,
  IoMdHelp,
  IoMdTrendingUp,
  IoMdPaper,
  IoMdPeople,
  IoMdSearch
} from 'react-icons/io';

import logo from "../../../assets/img/brand/logo.png";
import image from '../../../assets/img/personalFinance/banner.jpg';
import { getAllWebContent } from "../../../redux/reducers/user";
import './Header.css';

const Header = ({ content }) => {
  const { userLanguage } = useSelector(state => state.auth);
  const { translationState, allWebContent } = useSelector(state => state.user);

  const webContent = allWebContent?.[0]?.header;
  const orignalTranslation =
    translationState?.[0]?.translations?.header?.[userLanguage];

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const logoRef = useRef(null);

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(AuthSetState({ field: "userLanguage", value: lng }));
    setIsMenuOpen(false); // Close menu after language selection
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        logoRef.current &&
        !logoRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const UserSelector = () => {
    const { firstname } = useSelector((state) => state.auth.user || {});

    const handleLogin = () => {
      history.push({
        pathname: '/login',
        state: { event: undefined },
      });
    };

    const handleLogout = () => {
      dispatch(logout());
      history.push('/personal-finance');
    };

    return (
      <AppHeaderDropdown direction="down">
        <DropdownToggle
          className="lang-menu"
          style={{ backgroundColor: 'transparent', border: 'none' }}
        >
          <div className="language-selector">
            <IoMdPerson size={20} />
            <span
              className="mob-drop"
              onClick={() => {
                if (!firstname) handleLogin();
              }}
              style={{ cursor: 'pointer' }}
            >
              {firstname ? `${firstname}` : 'Login'}
            </span>
            <IoMdArrowDropdown size={20} />
          </div>
        </DropdownToggle>
        <DropdownMenu className="language-dropdown">
          {firstname ? (
            <DropdownItem
              onClick={handleLogout}
              className="user-dropdown-wrapper"
            >
              Logout
            </DropdownItem>
          ) : (
            <DropdownItem onClick={handleLogin} className="user-dropdown-wrapper">
              Login
            </DropdownItem>
          )}
        </DropdownMenu>
      </AppHeaderDropdown>
    );
  };

  const LanguageSelector = () => (
    <AppHeaderDropdown direction="down">
      <DropdownToggle
        className="lang-menu"
        style={{ backgroundColor: 'transparent', border: 'none' }}
      >
        <div className="language-selector">
          <span>{userLanguage}</span>
          <IoMdArrowDropdown size={30} />
        </div>
      </DropdownToggle>
      <DropdownMenu className="language-dropdown">
        <DropdownItem onClick={() => changeLanguage("ar")}>
          Arabic
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("zh")}>
          Chinese
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("en")}>
          English
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("fr")}>
          French
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("pt")}>
          Portuguese
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage("es")}>
          Spanish
        </DropdownItem>
      </DropdownMenu>
    </AppHeaderDropdown>
  );

  return (
    <header className="header-container">
      <img
        src={image}
        alt="People talking"
        className="header-background-image"
      />

      <div className="header-overlay">
        <div className="pf-header-content">
          <div className="header-top" ref={logoRef}>
            <div className="logo-section">
              <Link to="/">
                <img
                  src={logo}
                  alt="Money Coaches Canada"
                  className="logo-image"
                />
              </Link>
            </div>

            <div className="nav-section">
              <LanguageSelector />
              <UserSelector/>
              <div className="menu-button" onClick={toggleMenu}>
                {isMenuOpen ? '✕' : '☰'}
              </div>
            </div>
          </div>

          <div className="centered-text">
            <h1 className="header-title">{content.headerHeading}</h1>
            {content.headerText}
          </div>

          <div className={`mobile-menu ${isMenuOpen ? 'active' : ''}`} ref={menuRef}>
            <div className="close-menu-button" onClick={toggleMenu}>
              ✕
            </div>

            <div className="menu-container">
        

            <nav className="menu-items">
  <Link to="/personal-finance">
    <IoMdHome /> {orignalTranslation?.pFinance || webContent?.pFinance}
  </Link>
  <Link to="/about">
    <IoMdInformationCircleOutline /> {orignalTranslation?.pFinanceAbout || webContent?.pFinanceAbout}
  </Link>
  <Link to="/courses-and-events">
    <IoMdCalendar /> {orignalTranslation?.pFinanceCandE || webContent?.pFinanceCandE}
  </Link>
  <Link to="/contact-us">
    <IoMdMail /> {orignalTranslation?.pFinanceContact || webContent?.pFinanceContact}
  </Link>
  <Link to="/faq">
    <IoMdHelp /> {orignalTranslation?.pFinanceFAQ || webContent?.pFinanceFAQ}
  </Link>
  <Link to="/financeplanning">
    <IoMdTrendingUp /> {orignalTranslation?.pFinanceFP || webContent?.pFinanceFP}
  </Link>
  <Link to="/media">
    <IoMdPaper /> {orignalTranslation?.pFinanceMedia || webContent?.pFinanceMedia}
  </Link>
  <Link to="/new-immigrants-credits-and-finance">
    <IoMdPeople size={24} /> 
    <p style={{ width: "300px" }}>{orignalTranslation?.pFinanceNICAF || webContent?.pFinanceNICAF}</p>
  </Link>
  <Link to="/rebuild-credit-scores">
    <IoMdTrendingUp /> {orignalTranslation?.pFinanceRCC || webContent?.pFinanceRCC}
  </Link>
  <Link to="/testimonials">
    <IoMdPaper /> {orignalTranslation?.pFinanceT || webContent?.pFinanceT}
  </Link>
  <Link to="/search">
    <IoMdSearch /> {orignalTranslation?.shop || webContent?.shop}
  </Link>
  <Link to="/my-courses">
    <IoMdCalendar /> {orignalTranslation?.pFinanceMyCourses || webContent?.pFinanceMyCourses}
  </Link>
</nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
