import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { TranslationContent } from "../../../redux/reducers/admin"; // Adjust path if necessary
import { getAllWebContent } from "../../../redux/reducers/user"; // Adjust path if necessary
import "react-toastify/dist/ReactToastify.css";
import "./TranslationField.css";
import { useHistory } from "react-router-dom";


const NICAF = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    dispatch(getAllWebContent());
  }, [dispatch]);

  const { allWebContent } = useSelector((state) => state?.user);
  const RB_Data = allWebContent?.[0]?.NICAF;

  const [disable, setDisable] = useState(true);

  const [formData, setFormData] = useState({
    page: "nicaf",
    headerHeading: "sjdakljdklas",
    headerText: "hsajdaskjdka asd nasd as das d asd a sd asdasdasdasd",
    title: "New Immigrants Credit And Finance",
    address: "ksajdlkajskdj asd a sd asd",
    copyrights: "2023 kasjdkasjdkljasd asd asd a sd asd",
    blogtitle1: "Advice-Only Financial Planning: The Best Investment You’ll Ever Make",
    blogdescription1: "Description for Blog 1...",
    blogdate1: "September 17, 2024",
    blogauthor1: "By Neil D’Souza, CFP®",
    blogtag1: "sadasdas",
    blogtitle2: "Advice-Only Financial Planning: The Best Investment You’ll Ever Make",
    blogdescription2: "Description for Blog 2...",
    blogdate2: "September 17, 2024",
    blogauthor2: "By Neil D’Souza, CFP®",
    blogtag2: "sadasdas",
    blogtitle3: "Advice-Only Financial Planning: The Best Investment You’ll Ever Make",
    blogdescription3: "Description for Blog 3...",
    blogdate3: "September 17, 2024",
    blogauthor3: "By Neil D’Souza, CFP®",
    blogtag3: "sadasdas",
    blogtitle4: "Advice-Only Financial Planning: The Best Investment You’ll Ever Make",
    blogdescription4: "Description for Blog 4...",
    blogdate4: "September 17, 2024",
    blogauthor4: "By Neil D’Souza, CFP®",
    blogtag4: "sadasdas",
    stepstitle1: "Step 1",
    stepsdescription1: "Initial Planning and Research",
    stepstitle2: "Step 2",
    stepsdescription2: "Design and Prototyping",
    stepstitle3: "Step 3",
    stepsdescription3: "Development and Testing",
    stepstitle4: "Step 4",
    stepsdescription4: "Launch and Maintenance",
    stepstitle5: "Step 5",
    stepsdescription5: "Additional step",
    stepstitle6: "Step 6",
    stepsdescription6: "Final step",
    stepstitle7: "Step 7",
    stepsdescription7: "Final step",
    stepstitle8: "Step 8",
    stepsdescription8: "Final step",
  });

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setDisable(false);

    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(TranslationContent(formData));
    console.log("🚀 ~ NICAF Content Submit:", formData);
    setDisable(true);
  };

  useEffect(() => {
    if (RB_Data) {
      setFormData({
        ...formData,
        ...RB_Data,
      });
    }
  }, [RB_Data]);

  return (
    <div className="container">
      <ToastContainer autoClose={20000} />
      <div className="tf-top mx-5 mt-2 mb-2">
        
          <button className="back-button" onClick={history.goBack}>
            &lt;</button>

            <h1 className="mt-3" s  style={{
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
  }}>
  NICAF Page
</h1>

      </div>
      <form onSubmit={handleSubmit} className="mx-5">
        <h3>Header</h3>
        <div className="form-group">
          <label>Header Heading:</label>
          <input
            type="text"
            name="headerHeading"
            value={formData.headerHeading}
            onChange={(e) => handleInputChange(e, "headerHeading")}
            required
          />
        </div>
        <div className="form-group">
          <label>Header Text:</label>
          <textarea
            name="headerText"
            value={formData.headerText}
            onChange={(e) => handleInputChange(e, "headerText")}
            required
          />
        </div>

        <h3>Blog Entries</h3>
        {[...Array(4)].map((_, i) => (
          <div className="form-group" key={`blog-${i}`}>
            <label>Blog {i + 1} Title:</label>
            <input
              type="text"
              name={`blogtitle${i + 1}`}
              value={formData[`blogtitle${i + 1}`]}
              onChange={(e) => handleInputChange(e, `blogtitle${i + 1}`)}
              required
            />
            <label>Blog {i + 1} Description:</label>
            <textarea
              name={`blogdescription${i + 1}`}
              value={formData[`blogdescription${i + 1}`]}
              onChange={(e) => handleInputChange(e, `blogdescription${i + 1}`)}
              required
            />
            <label>Blog {i + 1} Tag:</label>
            <input
              type="text"
              name={`blogtag${i + 1}`}
              value={formData[`blogtag${i + 1}`]}
              onChange={(e) => handleInputChange(e, `blogtag${i + 1}`)}
            />
            <label>Blog {i + 1} Date:</label>
            <input
              type="text"
              name={`blogdate${i + 1}`}
              value={formData[`blogdate${i + 1}`]}
              onChange={(e) => handleInputChange(e, `blogdate${i + 1}`)}
            />
            <label>Blog {i + 1} Author:</label>
            <input
              type="text"
              name={`blogauthor${i + 1}`}
              value={formData[`blogauthor${i + 1}`]}
              onChange={(e) => handleInputChange(e, `blogauthor${i + 1}`)}
            />
          </div>
        ))}

        <h3>Steps</h3>
        {[...Array(8)].map((_, i) => (
          <div className="form-group" key={`step-${i}`}>
            <label>Step {i + 1} Title:</label>
            <input
              type="text"
              name={`stepstitle${i + 1}`}
              value={formData[`stepstitle${i + 1}`]}
              onChange={(e) => handleInputChange(e, `stepstitle${i + 1}`)}
              required
            />
            <label>Step {i + 1} Description:</label>
            <textarea
              name={`stepsdescription${i + 1}`}
              value={formData[`stepsdescription${i + 1}`]}
              onChange={(e) => handleInputChange(e, `stepsdescription${i + 1}`)}
              required
            />
          </div>
        ))}

        <h3>Copyrights and Address</h3>
        <div className="form-group">
          <label>Address:</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={(e) => handleInputChange(e, "address")}
          />
        </div>
        <div className="form-group">
          <label>Copyright Text:</label>
          <textarea
            name="copyrights"
            value={formData.copyrights}
            onChange={(e) => handleInputChange(e, "copyrights")}
          />
        </div>

        <div className="form-group">
          <button type="submit" disabled={disable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default NICAF;
