import React, { useEffect, useState } from "react";
import "./TranslationField.css";
import { useDispatch, useSelector } from "react-redux";
import { TranslationContent } from "../../../redux/reducers/admin";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { getAllWebContent } from "../../../redux/reducers/user";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";


const ShopFeild = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    dispatch(getAllWebContent());
  }, []);

  const { allWebContent } = useSelector((state) => state?.user);

  const shopPageData = allWebContent?.[0]?.shop;

  const [diable, setDiable] = useState(true);

  const [formData, setFormData] = useState({
    page: "shop",
    isChange: false,
    //  search: shopPageData?.search || "",
    selected: shopPageData?.selected || "",
    categories: shopPageData?.categories || "",
    subcategories: shopPageData?.subcategories || "",
    brand: shopPageData?.brand || "",
    mainCategory: shopPageData?.mainCategory || "",
    allCategory: shopPageData?.allCategory || "",
    allProduct: shopPageData?.allProduct || "",
    price: shopPageData?.price || "",
    message: shopPageData?.message || "",
    messgaeTwo: shopPageData?.messgaeTwo || "",
    popUpHeading: shopPageData?.popUpHeading || "",
    popUp: shopPageData?.popUp || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDiable(false);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(TranslationContent(formData));
    setDiable(true);
  };

  useEffect(() => {
    setFormData({ ...formData, ...shopPageData, isChange: false });
  }, [shopPageData]);

  return (
    <div className="container">
      <ToastContainer autoClose={20000} />
      <div className="tf-top mx-5 mt-2 mb-2">
        
          <button className="back-button" onClick={history.goBack}>
            &lt;</button>

            <h1 className="mt-3" s  style={{
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
  }}>
  Shop Page
</h1>

      </div>
      <form onSubmit={handleSubmit} className="mx-5">
        {/* <div className="form-group">
          <label> Search:</label>
          <input
            type="text"
            name="search"
            value={formData.search}
            onChange={handleInputChange}
            required
          />
        </div> */}

        <div className="form-group">
          <label>Selected :</label>
          <input
            type="text"
            name="selected"
            value={formData.selected}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label>categories:</label>
          <input
            type="text"
            name="categories"
            value={formData.categories}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>subcategories:</label>
          <input
            type="text"
            name="subcategories"
            value={formData.subcategories}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>brand:</label>
          <input
            type="text"
            name="brand"
            value={formData.brand}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>mainCategory:</label>
          <input
            type="text"
            name="mainCategory"
            value={formData.mainCategory}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>allCategory:</label>
          <input
            type="text"
            name="allCategory"
            value={formData.allCategory}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>allProduct:</label>
          <input
            type="text"
            name="allProduct"
            value={formData.allProduct}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>price:</label>
          <input
            type="text"
            name="price"
            value={formData.price}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>message:</label>
          <input
            type="text"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>messgaeTwo:</label>
          <input
            type="text"
            name="messgaeTwo"
            value={formData.messgaeTwo}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>popUpHeading:</label>
          <input
            type="text"
            name="popUpHeading"
            value={formData.popUpHeading}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>popUp:</label>
          <input
            type="text"
            name="popUp"
            value={formData.popUp}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <button type="submit" disabled={diable}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ShopFeild;
